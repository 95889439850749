import React from 'react';
import {HeadingWrapper, Heading} from './styles';

const BoldHeading = ({text, children}) => {
  return (
    <HeadingWrapper>
      <Heading>{text}</Heading>
      {children}
    </HeadingWrapper>
  )
};

export default BoldHeading;

