import React from 'react';
import {DarkPrimaryButton, PrimaryButton, PrimaryButtonText, PrimaryButtonIcon} from 'styled/Buttons';
import {ButtonLoader} from 'styled/ButtonLoader';

const LoadingButton = ({
  dark = false,
  centered,
  wide = true,
  buttonText,
  loadingText,
  isLoading,
  beforeTextIcon,
  smallButton = false,
  type = 'button',
  onClick = null,
  icon
}) => {

  if (dark) {
    return (
      <DarkPrimaryButton
        onClick={type === 'button' ? onClick : null}
        centered={centered}
        wide={wide}
        type="submit"
        className={[isLoading ? 'loading' : null, smallButton ? 'small-button' : null]}
        disabled={isLoading}>
        <PrimaryButtonText>
          {beforeTextIcon ? beforeTextIcon : null}
          {isLoading ? loadingText : buttonText}
        </PrimaryButtonText>
        <PrimaryButtonIcon>
          {isLoading ? <ButtonLoader /> : icon}
        </PrimaryButtonIcon>
      </DarkPrimaryButton>
    )
  }

  return (
    <PrimaryButton
      dark={dark}
      centered={centered}
      wide={wide}
      type="submit"
      className={[isLoading ? 'loading' : null, smallButton ? 'small-button' : null]}
      disabled={isLoading}>
      <PrimaryButtonText>
        {beforeTextIcon ? beforeTextIcon : null}
        {isLoading ? loadingText : buttonText}
      </PrimaryButtonText>
      <PrimaryButtonIcon>
        {isLoading ? <ButtonLoader /> : icon}
      </PrimaryButtonIcon>
    </PrimaryButton>
  )
};

export default LoadingButton;