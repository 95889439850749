import React from 'react';
import {useDispatch} from 'react-redux';
import {setEditAlertVisible} from 'store/routes/actions';
import {
  AlertContent,
  Text,
  TextBold,
  CloseButton
} from './styles';
import {ReactComponent as Cross} from 'assets/icons/cross.svg';
import { withNamespaces } from 'react-i18next';

const EditAlert = ({ t }) => {
  
  const d = useDispatch();

  return (
    <AlertContent>
      <Text>
        {t('route.editAlert1')}
        <TextBold>{t('route.editAlert2')}</TextBold>
        {t('route.editAlert3')}
        <TextBold>{t('route.editAlert4')}</TextBold>
        {t('route.editAlert5')}
      </Text>
      <CloseButton onClick={() => d(setEditAlertVisible(false))}>
        <Cross />
      </CloseButton>
    </AlertContent>
  );
};

export default withNamespaces()(EditAlert);