import styled from 'styled-components';
import {rem} from 'polished';
import theme from 'config/theme';

export const FilterLabel = styled.div`
  font-size: ${rem('11px')};
  margin-right: 10px;
  color: ${theme.colors.blueyGrey};
  @media (max-width: 767px) {
    display: none;
  }
`;

export const FiltersRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const FilterCol = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;