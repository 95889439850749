import * as types from './types'

export const setCars = cars => {
  return {
    type: types.SET_CARS,
    payload: cars
  }
};

export const setCarsLoading = isLoading => {
  return {
    type: types.SET_CARS_LOADING,
    payload: isLoading
  }
};

export const setCarsPositions = positions => {
  return {
    type: types.SET_CARS_POSITIONS,
    payload: positions
  }
};

export const setCarsColors = carsData => {
  return {
    type: types.SET_CARS_COLORS,
    payload: carsData
  }
};

export const setCompanyCars = companyCars => {
  return {
    type: types.SET_COMPANY_CARS,
    payload: companyCars
  }
};

export const setCompanyCarsLoading = isLoading => {
  return {
    type: types.SET_COMPANY_CARS_LOADING,
    payload: isLoading
  }
};

export const setCompanyCarsPositionsLoading = isLoading => {
  return {
    type: types.SET_COMAPNY_CARS_POSITIONS_LOADING,
    payload: isLoading
  }
};

export const setSelectedCompanyCars = selectedCars => {
  return {
    type: types.SET_SELECTED_COMPANY_CARS,
    payload: selectedCars
  }
};

export const setCompanyCarsPositions = positions => {
  return {
    type: types.SET_COMAPNY_CARS_POSITIONS,
    payload: positions
  }
};

export const setCarsFilters = filters => {
  return {
    type: types.SET_CARS_FILTERS,
    payload: filters
  }
};
