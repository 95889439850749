import * as types from './types'

export const setEvents = events => {
  return {
    type: types.SET_EVENTS,
    payload: events,
  };
};

export const addEventsToEvents = event => {
  return {
    type: types.ADD_EVENTS_TO_EVENTS,
    payload: event,
  };
};

export const setEventsLoading = isLoading => {
  return {
    type: types.SET_EVENTS_LOADING,
    payload: isLoading,
  };
};

export const setUnreadAlertsCounter = count => {
  return {
    type: types.SET_UNREAD_ALERTS_COUNTER,
    payload: count,
  };
};

export const addToastEvent = event => {
  return {
    type: types.ADD_TOAST_EVENT,
    payload: event,
  };
};

export const removeToastEvent = event => {
  return {
    type: types.REMOVE_TOAST_EVENT,
    payload: event,
  };
};

export const updateEvents = event => {
  return {
    type: types.UPDATE_EVENTS,
    payload: event
  }
}
