export default {
  login: {
    logoAlt: 'GTV Bus',
    header: 'Zaloguj się do panelu dyspozytora',
    companyHeader: "Zaloguj się do panelu firmowego",
    loginForm: 'Login',
    loginFormPassword: 'Hasło',
    forgotPassword: 'Jeśli zapomniałeś hasła skontaktuj się z administratorem',
    loginFormButton: 'Zaloguj się',
    logging: 'Logowanie...',
    verify: 'Weryfikowanie...',
    helpText: 'Potrzebna pomoc? Zadzwoń do nas',
    loading: 'Ładuję...'
  },
  languages: {
    language: 'Język',
    pl: 'Polski',
    en: 'English',
    ro: 'Română',
    nl: "Nederlands",
    ua: 'Український',
    de: 'Deutsche',
    change: 'Zmień',
  },
  database: {
    name: 'Baza danych',
    pl: ' Polska',
    nl: ' Holenderska',
    ro: ' Rumuńska',
    pl2: ' Autko 2.0',
    undefined: ' niezdefiniowana'
  },
  route: {
    editRoute: 'Edytuj trasę',
    cancelEdit: 'Anuluj edycję',
    saveChanges: 'Zapisz zmiany',
    savingChanges: 'Zapisywanie...',
    editAlert1: 'Zaznacz odcinek trasy, który chcesz edytować ',
    editAlert2: 'wybierając dwa punkty ',
    editAlert3: 'na trasie, a następnie ',
    editAlert4: 'wyznacz punkt/y objazdu ',
    editAlert5: 'dla tego odcinka trasy.',
    taskCancelled: 'Anulowany',
    removePoint: 'Usuń punkt',
    additionalPoint: 'Punkt objazdu',
  },
  confirmModal: {
    confirmButton: 'Tak, wychodzę',
    cancelButton: 'Nie, chcę dokończyć edycję',
    mainText: 'Zmiany na trasie nie zostały zapisane i zostaną utracone.',
    boldText: 'Czy na pewno chcesz wyjść?',
  },
  filters: {
    all: 'Wszystkie',
    departure: 'Wyjazd',
    back: 'Powrót',
    labels: {
      departureDate: 'Data wyjazdu',
      date: 'Data',
      tripType: 'Rodzaj trasy',
      team: 'ID oddziału'
    },
    allPassengers: 'Wszyscy',
    presentPassengers: 'Obecni',
    refreshData: 'Odśwież dane'
  },
  tabs: {
    info: 'Informacje',
    task: 'Zadanie',
  },
  sidebar: {
    vehicles: 'Pojazdy',
    drivers: 'Kierowcy',
    passengers: 'Pasażerowie',
    workers: 'Pracownicy',
    events: 'Zdarzenia',
    chat: 'Czat',
  },
  cars: {
    searchCar: 'Wyszukaj pojazd',
    noCarsFound: 'Nie znaleziono pojazdów dla podanych kryteriów!',
    carTypePlaceholder: 'Rodzaj',
    carTypes: {
      all: 'Wszystkie',
      target: 'Docelowe',
      delivers: 'Rozwożące',
      shippers: 'Dowożące',
      other: 'Inne'
    },
    drivers: 'Kierowcy',
    trip: 'Trasa',
    tripTypes: {
      target: 'Docelowa',
      delivers: 'Rozwożąca',
      shippers: 'Dowożąca',
      other: 'Inna',
    },
    vehicleTrip: 'Trasa pojazdu',
    tripNotPlanned: 'Trasa nie została jeszcze zaplanowana',
    write: 'Napisz',
    backToCarsList: 'Powrót do listy samochodów',
    noGpsSignal: 'Brak sygnału GPS',
    routeLines: 'Linie tras',
    personIn: 'Odbiór',
    personOut: 'Wysiadka'
  },
  events: {
    eventsHeading: 'Lista zdarzeń',
    noEventsMessage: 'Nie zanotowano żadnych zdarzeń!',
    call: 'Zadzwoń',
    message: 'Napisz'
  },
  drivers: {},
  passenger: {
    searchWorker: 'Wyszukaj pracownika',
    car: 'Pojazd',
    fromTo: 'Miejsce docelowe',
    notFound: 'Nie znaleziono pracowników dla podanych kryteriów!',
    passengerMissing: 'Nie pojechał',
    passengerCancelled: 'Rezygnacja',
    start: 'Start',
    vehicleList: 'Pojazdy',
    sharePassengersLocations: 'Udostępnij lokalizację pasażerów',
    sharePassengerLocation: 'Udostępnij lokalizację pasażera',
    copyLinkToClipboard: 'Kopiuj link do schowka',
    copyLinksToClipboard: 'Kopiuj linki do schowka',
    sendInMail: 'Wyślij link mailem',
    clickToFollowLocation: 'Kliknij w link, aby móc śledzić pozycję pasażera',
  },
  companyCars: {
    companyCarsHeading: 'Lista pojazdów',
    worker: 'pracownik',
    workers: 'pracowników',
    notFound: 'Nie znaleziono pojazdów dla podanych kryteriów!',
  },
  api: {
    unexpectedError: 'Wystąpił nieoczekiwany błąd!',
  },
};
