export const SET_CORPS = 'SET_CORPS';
export const SET_CORPS_LOADING = 'SET_CORPS_LOADING';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANIES_LOADING = 'SET_COMPANIES_LOADING';
export const SET_COMPANY_REMOVING_ID = 'SET_COMPANY_REMOVING_ID';
export const SET_COMPANY_FORM_SUBMITTING = 'SET_COMPANY_FORM_SUBMITTING';
export const SET_COMPANY_FORM_ERRORS = 'SET_COMPANY_FORM_ERRORS';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const SET_COMPANY_TEAMS_LOADING = 'SET_COMPANY_TEAMS_LOADING';
export const SET_COMPANY_TEAMS = 'SET_COMPANY_TEAMS';
