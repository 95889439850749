import styled from "styled-components";

export const AlertBox = styled.div`
  padding: 15px;
  margin: 15px;
  border-radius: 4px;
  background-color: ${props => props.color};
  color: #2f2f2f;
  font-size: 12px;
`;

export default {
  AlertBox
};
