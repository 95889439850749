import React from 'react';
import SearchStyles from './styles';
import magnifyIcon from 'assets/icons/magnify.svg';

const Search = ({ wide, bgColor, onSubmit, onChange, inputName, inputValue, placeholder, disabled }) => {
	return (
		<SearchStyles.SearchForm wide={wide} onSubmit={onSubmit}>
			<SearchStyles.SerachLabel>
				<SearchStyles.SearchMagnify icon={magnifyIcon} />
				<SearchStyles.SearchInput
					bgColor={bgColor}
					type={"text"}
					value={inputValue}
					disabled={disabled}
					onChange={(e) => onChange(inputName, e.target.value)}
					placeholder={placeholder} />
			</SearchStyles.SerachLabel>
		</SearchStyles.SearchForm>
	)
};

export default Search;