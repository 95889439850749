import React, {useState, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {getUser} from 'utils/auth';
import {routes} from 'config/routes';
import PageLoader from 'components/__common/PageLoader';
import history from 'config/history';

const Authorize = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.success) {
        setUser(userData.user);
        setIsLoading(false);
      } 
      else {
        history.push('/login');
      }
    }
    getUserData()
  }, []);

  const setFallbackRoute = route => {
    switch(route) {
      case routes.admin.url:
        return user.type === 'dispatcher' ? routes.map.url : routes.company_map.url;
      case routes.company_map.url: 
        return user.type === 'dispatcher' ? routes.map.url : routes.admin.url;
      case routes.map.url:
        return user.type === 'manager' ? routes.company_map.url : routes.admin.url;
      default:
        return '/map'
    };
  };

  if (isLoading) {
    return <PageLoader />
  } else {
    return (
      <Route {...rest} render={props => (
        rest.availableFor.includes(user.type)
        ? <Component {...props} />
        : <Redirect to={setFallbackRoute(rest.path)} />
      )} />
    );
  }
}

export default Authorize;
