import React, {useEffect} from 'react';
import {Router, Switch, Redirect} from 'react-router-dom'

import {useDispatch, useSelector } from 'react-redux'
import {ToastContainer} from 'react-toastify';
import {getBasicConfig} from 'store/settings/thunks';
import { withNamespaces } from 'react-i18next';
import {setLoggedUser} from 'store/user/actions';

import '../../../i18n/index';
import history from 'config/history';
import {GlobalStyles} from './styles';
import {FadeModalWrapper} from 'components/__common/ConfirmModal/styles';
import {getUser} from 'utils/auth';
import {routes} from 'config/routes';
import {PoseGroup} from 'react-pose';

import Login from 'components/login/Login';
import Map from 'components/map/Map';
import CompanyMap from 'components/map/CompanyMap';
import Admin from 'components/admin/Admin';
import ConfirmModal from 'components/__common/ConfirmModal';
import IsLogged from 'hoc/IsLogged';
import Authorize from 'hoc/Authorize';
import PageLoader from 'components/__common/PageLoader';


const App = () => {
  const loggedUser = useSelector(({user}) => user.loggedUser);
  const basicConfigLoading = useSelector(({settings}) => settings.basicConfigLoading);
  const confirmModalVisible = useSelector(({modal}) => modal.confirmModalVisible);

  const d = useDispatch();

  const getUserData = async () => {
    const userData = await getUser();
    if (userData.success) {
      d(setLoggedUser(userData.user));
    }
  }

  useEffect(() => {
    getUserData();
    d(getBasicConfig());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyles />
      <PoseGroup>  
        {confirmModalVisible && (
          <FadeModalWrapper key='confirmModal'>
            <ConfirmModal />
          </FadeModalWrapper>
        )}
      </PoseGroup>
      <ToastContainer className="ISSToast" />
      {basicConfigLoading ? (
        <PageLoader />
      ) : (
        <Router history={history}>
          <Switch>
            <Authorize
              exact
              path={routes.map.url}
              loggedUser={loggedUser}
              availableFor={routes.map.availableFor}
              component={Map} />
            <Authorize 
              path={routes.admin.url}
              loggedUser={loggedUser}
              availableFor={routes.admin.availableFor}
              component={Admin} />
            <Authorize 
              path={routes.company_map.url}
              loggedUser={loggedUser}
              availableFor={routes.company_map.availableFor}
              component={CompanyMap} />      
            <IsLogged exact path="/login" component={Login} />
            <Redirect from="/*" to="/login" />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default withNamespaces()(App);
