import styled from "styled-components";
import theme from 'config/theme';
import {Link} from 'react-router-dom';
import {rem} from 'polished';

export const ButtonWrapper = styled(Link)`
  display: flex;
  flex-direction: nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  background: ${({background}) => background ? background : theme.colors.apple};
  color: ${({textColor}) => textColor ? textColor : theme.colors.white};
  padding: 0 20px;
  text-decoration: none;
  transition: .3s background;
  
  &:hover {
    background: ${({hoverBackground}) => hoverBackground ? hoverBackground : theme.colors.darkNavy};
  }
`;

export const ButtonText = styled.p`
  margin-right: 20px;
  font-size: ${rem('12px')};
  white-space: nowrap;
`;
