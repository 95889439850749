import styled from 'styled-components';
import {rem} from 'polished';
import theme from 'config/theme';

export const SettingsWrapper = styled.div`
  padding: 20px 25px 40px 25px;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  border-radius: 10px;
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 15px 15px 35px 15px;
  }
`

export const Logo = styled.img`
  max-width: 100%;
  width: 200px;
  margin: 0 auto 25px auto;
`

export const SettingsHeader = styled.h3`
  font-weight: bold;
  font-size: ${rem('14px')};
  text-align: center;
  color: ${theme.colors.darkNavy};
`

