import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'config/theme';

const LoginInfoWrapper = styled.div`
	width: 100%;
	max-width: 280px;
	margin: 50px 0 48px;
	padding: 27px 37px 24px;
	min-height: 100px;
	border-radius: 4px;
	background-color: ${theme.colors.paleGrey};
	text-align: center;
	@media (min-width: ${theme.breakpoints.lg}) {
		max-width: 220px;
		margin: 100px 0 40px;
		padding: 27px 20px 24px;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		max-width: 280px;
		margin: 180px 0 48px;
		padding: 27px 37px 24px;
	}
	@media (max-height: ${theme.breakpoints.xs}) {
		margin: 10px 0;
		padding: 15px 20px;
		min-height: auto;
	}
	@media (max-width: 375px) {
		max-width: 250px;
	}
	@media (max-width: 320px) {
		margin: 100px 0 0;
	}
`

const LoginInfoMsg = styled.p`
	font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('12px')};
	color: ${theme.colors.darkNavy};
	line-height: 1.17;
	margin: 0;
`

const LoginInfoLink = styled.a`
	display: block;
	font-family: ${theme.font.family};
	font-weight: ${theme.font.bolder};
	font-size: ${rem('12px')};
	line-height: 1.17;
	color: ${theme.colors.apple};
	text-decoration: none;
	margin-top: 11px;
	transition: color .15s ease;
	
	&:hover, &:focus {
		color: ${theme.colors.darkNavy};
	}
`

export default {
	LoginInfoWrapper,
	LoginInfoMsg,
	LoginInfoLink
}
