import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import CarsStyles from "../../cars/Cars/styles";
import CarsListStyles from "../../cars/CarsList/styles";
import theme from 'config/theme';
import {ReactComponent as PinMapIcon} from "assets/icons/pinmaps.svg";
import {ReactComponent as ArrowLeftBack} from "assets/icons/arrowLeft.svg";
import {ReactComponent as ArrowRight} from 'assets/icons/arrow.svg';
import {ReactComponent as Cross} from 'assets/icons/cross.svg';
import {ReactComponent as Check} from 'assets/icons/check.svg';
import {withNamespaces} from 'react-i18next';
import {setFetchedNewRoute, setNewRoutePoints, setEditAlertVisible} from "store/routes/actions";
import {ButtonLoader} from 'styled/ButtonLoader';

const TabsHeader = ({
  color,
  isRouteEditable,
  carName,
  onClick,
  tripCarHeader,
  editModeEnabled,
  newRoutePointsLength,
  setEditModeEnabled,
  setSectionPoints,
  showEditAlertAndHide,
  setTemporaryPointsOnPolyline,
  routePointsOnPolyline,
  handleNewRouteSave,
  fetchedRouteSaving,
  carId,
  t,
}) => {

  const carsPositions = useSelector(({ cars }) => cars.carsPositions);
  const d = useDispatch();
  const currentCar = carsPositions && carsPositions.find(c => c.car_id === Number(carId));

  const handleEditClick = () => {
    if (editModeEnabled && newRoutePointsLength > 0) {
      handleNewRouteSave();
    } else {
      d(setEditModeEnabled(true));
      d(showEditAlertAndHide());
      d(setTemporaryPointsOnPolyline(routePointsOnPolyline));
    }
  }

  const handleCancelEditClick = () => {
    d(setSectionPoints([]));
    d(setEditModeEnabled(false));
    d(setTemporaryPointsOnPolyline([]));
    d(setFetchedNewRoute(null));
    d(setNewRoutePoints([]));
    d(setEditAlertVisible(false));
  }

  return (
    <CarsStyles.CarsTopSideWrapper wide>
      <>
        <CarsListStyles.CarsListsPin noSpace color={color}>
          <PinMapIcon />
        </CarsListStyles.CarsListsPin>
        <CarsListStyles.CarsListsWrapper>
          <CarsListStyles.CarsListsUsr>
            {tripCarHeader}
          </CarsListStyles.CarsListsUsr>
          <CarsListStyles.CarsDetailsName>
            {carName}
          </CarsListStyles.CarsDetailsName>
          {currentCar && (
            <CarsListStyles.CarPositionLink target="_blank" href={`https://maps.google.com/maps?q=${currentCar.geo_lat},${currentCar.geo_lon}`}>
              {`Pozycja: ${currentCar.geo_lat}, ${currentCar.geo_lon}`}
            </CarsListStyles.CarPositionLink>
          )}
          <CarsListStyles.EditButtonsWrapper>
            {isRouteEditable &&
              <CarsListStyles.EditButton
                onClick={() => handleEditClick()}
                disabled={
                  editModeEnabled
                  ? newRoutePointsLength > 0
                    ? false
                    : true
                  : false
                }
                bgColor={
                  editModeEnabled
                  ? newRoutePointsLength > 0
                    ? theme.colors.apple
                    : theme.colors.markerGray
                  : theme.colors.apple
                }>
                <CarsListStyles.EditButtonIcon>
                  {
                    editModeEnabled
                      ? fetchedRouteSaving
                        ? <ButtonLoader radius={13} stroke={3} />
                        : <Check />
                      : <ArrowRight />
                  }
                </CarsListStyles.EditButtonIcon>
                <CarsListStyles.EditButtonText>
                  {
                    editModeEnabled
                      ? fetchedRouteSaving
                        ? t('route.savingChanges')
                        : t('route.saveChanges')
                      : t('route.editRoute')
                  }
                </CarsListStyles.EditButtonText>
              </CarsListStyles.EditButton>
            }
            {editModeEnabled &&
              <CarsListStyles.EditButton onClick={() => handleCancelEditClick()} bgColor={theme.colors.editRed}>
                <CarsListStyles.EditButtonIcon><Cross /></CarsListStyles.EditButtonIcon>
                <CarsListStyles.EditButtonText>{t('route.cancelEdit')}</CarsListStyles.EditButtonText>
              </CarsListStyles.EditButton>
            }
          </CarsListStyles.EditButtonsWrapper>
        </CarsListStyles.CarsListsWrapper>
      </>
      <CarsStyles.ArrowLeftBackWrapper editModeEnabled={editModeEnabled} onClick={onClick}>
        <ArrowLeftBack />
      </CarsStyles.ArrowLeftBackWrapper>
    </CarsStyles.CarsTopSideWrapper>
  );
};

export default withNamespaces()(TabsHeader);
