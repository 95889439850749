import React from 'react';
import Select from 'react-select';
import {SelectWrapper} from './styles';

const SimpleSelect = ({ 
  wrapper = SelectWrapper,
  isRequired,
  error,
  value,
  onChange,
  options,
  placeholder,
  className = 'simple-select'
}) => {

  const Wrapper = wrapper;

  return (
    <Wrapper className={className} isRequired={isRequired} error={error}>
      <Select
        className={className}
        classNamePrefix={className}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        value={value} />
    </Wrapper>
  );
};

export default SimpleSelect;
