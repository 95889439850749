import React from 'react';
import {ReactComponent as CompanyIcon} from 'assets/icons/company_icon.svg';
import {ReactComponent as SettingsIcon} from 'assets/icons/settings_icon.svg';

export const routes = {
  map: {
    url: '/map',
    label: '',
    icon: null,
    availableFor: ['dispatcher'],
  },
  company_map: {
    url: '/company-map',
    label: '',
    icon: null,
    availableFor: ['manager'],
  },
  admin: {
    url: '/admin',
    label: '',
    icon: null,
    availableFor: ['admin'],
    nested: {
      companies: {
        url: '/admin/companies',
        label: 'Konta firmowe',
        icon: <CompanyIcon />,
        availabeFor: [],
        nested: {
          new: {
            url: '/admin/companies/new',
            label: '',
            icon: null,
            availabeFor: [],
          },
          edit: {
            url: '/admin/companies/:company_id',
            label: '',
            icon: null,
            availabeFor: [],
          }
        }
      },
      settings: {
        url: '/admin/settings',
        label: 'Ustawienia',
        icon: <SettingsIcon />,
        availableFor: []
      },
    }
  },
}