import styled, {css} from 'styled-components';
import {rem} from 'polished';
import theme from 'config/theme';

export const FilterLabel = styled.div`
  font-size: ${rem('11px')};
  margin-right: 10px;
  color: ${theme.colors.blueyGrey};
  @media (max-width: 767px) {
    display: none;
  }
`;

export const FiltersRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const FilterCol = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`;

export const RefreshData = styled.div`
  margin-left: 10px;
`;

export const RefreshButton = styled.button`
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 50%;
  display: flex;
  padding: 7px;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.apple};
  color: white;
  opacity: ${props => props.disabled ? 0.6 : 1};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  svg {
    * {
      stroke: white !important;
    }
  }
`;

export const TeamSelectWrapper = styled.div`
  ${props => css`
    .${props.className} {
      width: 100%;
      max-width: 280px;
      min-width: 110px;

      &__control {
        border: none;
        background-color: ${theme.colors.paleGrey};
        min-height: 30px;
        height: 30px;
        &--is-focused {
          box-shadow: 0 0 0 1px ${theme.colors.apple};
        }
        &:hover {
          cursor: pointer;
        }
      }
      &__placeholder {
        font-size: ${rem('11px')};
        color: ${theme.colors.darkNavy};
        &::after {
          content: '*';
          color: ${theme.colors.error};
          margin-left: 2px;
          display: ${props => props.isRequired ? 'inline-block' : 'none'}
        }
      }
      &__value-container {
        padding-left: 13px;
        height: 100%;
      }
      &__indicators {
        height: 30px;
      }
      &__indicator {
        color: ${theme.colors.darkNavy};
        svg {
          width: 15px;
          height: 15px;
        }
      }
      &__indicator-separator {
        display: none;
      }
      &__option {
        font-size: ${rem('12px')};
        color: ${theme.colors.darkNavy};
        background-color: transparent;
        &:hover {
          background-color: ${theme.colors.apple};
          color: ${theme.colors.white};
          cursor: pointer;
        }
      }
      &__single-value {
        font-size: ${rem('12px')};
        color: ${theme.colors.darkNavy};
      }
    }
  `}
`;
