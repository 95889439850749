import styled, {css} from "styled-components";
import theme from "config/theme";

const widthSidebar = {
	width: '80px'
}

export const SidebarTabPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 31.5%;
 	max-width: 480px;
 	min-width: 400px;
  box-shadow: 0 4px 20px 0 ${theme.colors.armyGreen20};
  background-color: ${theme.colors.white};
  border-top-right-radius: 10px;
  padding-left: ${widthSidebar.width};
  opacity: 0;
  transform: translateX(-100%);
  transform-origin: left center;
  overflow: hidden;
  pointer-events: none;
  transition: transform .4s ease, opacity .4s ease;
  z-index: 2;
	padding-top: 60px; 
  
  @media(max-width: 1440px) {
  	min-width: 440px;
  }
  
  @media(max-width: 1023px) {
  	transform: translateX(0);
  	transform: translateY(100%);
  	transform-origin: bottom center;
  	padding-left: 0;
  	height: calc(100% - 88px);
  	min-width: 360px;
  }
  
  @media(max-width: 601px) {
  	max-width: none;
  	width: 100%;
  	top: 0;
  	bottom: auto;
  	z-index: 99;
  	min-width: 320px;
		height: ${props => props.editModeEnabled ? '138px' : '100%'};
		padding-top: ${props => props.editModeEnabled ? '0' : '60px'};
  }

	@media (max-width: 466px) {
		padding-top: ${props => props.editModeEnabled ? '0' : '87px'};
	}
	
	@media (max-width: 346px) {
		padding-top: ${props => props.editModeEnabled ? '0' : '120px'};
	}
  
  ${({ isActive }) => (
  		isActive && css`
				opacity: 1;
				pointer-events: auto;
				@media(min-width: 320px) {
					transform: none;
				}
			`
	)}
  
  &:before {
		display: ${props => props.editModeEnabled ? 'none' : 'block'};
  	content: "";
  	position: absolute;
  	bottom: 0;
  	left: 0;
  	width: calc(100% - 6px);
  	height: 45px;
  	background: linear-gradient(to bottom,rgba(255,255,255,0) 0%,#ffffff 78%,#ffffff 52%);
  	z-index: 2;
  }
`;

export const SidebarListNotFound = styled.div`
	padding: 15px;
	margin: ${({ employee }) => employee ? '15px 20px' : '15px'};
	border-radius: 4px;
	background-color: ${({ bgColor }) =>
		bgColor ? theme.colors.darkNavy : theme.colors.paleGrey};
	color: ${({ bgColor }) => (bgColor ? "#ffffff" : theme.colors.darkNavy)};
	font-size: 12px;
`;

export default {
	SidebarListNotFound,
	SidebarTabPanel,
}