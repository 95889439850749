import * as types from "./types";

const initialState = {
  confirmModalVisible: false,
  modalCallback: null,
  modalContent: {
    text: '',
    boldText: '',
    confirmButtonText: '',
    cancelButtonText: '',
    icon: 'default',
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CONFIRM_MODAL_VISIBLE:
      return {
        ...state,
        confirmModalVisible: action.payload,
      }
    case types.SET_MODAL_CALLBACK:
      return {
        ...state,
        modalCallback: action.payload,
      }
    case types.SET_MODAL_CONTENT:
      return {
        ...state,
        modalContent: action.payload,
      }
    default:
      return state;
  };
};
