import styled from 'styled-components';
import theme from 'config/theme';
import {rem, darken} from 'polished';
import posed from 'react-pose';

const onEnterAnimation = { 
  opacity: 1,
}

const onLeaveAnimation = { 
  opacity: 0,
}

export const FadeWrapper = posed.div({
  enter: onEnterAnimation,
  exit: onLeaveAnimation
});

export const FadeModalWrapper = styled(FadeWrapper)`
  margin-top: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111111;
`;

export const ModalWrapper = styled.div`
  padding: 30px 40px;
  border-radius: 5px;
  background-color: white;
  min-width: 250px;
  max-width: 410px;
  @media (max-width: ${theme.breakpoints.sm}) {
    max-width: 200px;
    padding: 20px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const ContentIcon = styled.div`
  margin-right: 20px; 
  @media (max-width: ${theme.breakpoints.sm}) {
    margin-bottom: 15px;
    margin-right: 0;
  }
`;

export const ContentText = styled.div`
  font-size: ${rem('14px')};
  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
    font-size: ${rem('13px')};
  }
`;

export const TextBold = styled.div`
  font-weight: bold;
  font-size: ${rem('14px')};
  margin-top: 5px;
  @media (max-width: ${theme.breakpoints.sm}) {
    text-align: center;
    font-size: ${rem('13px')};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: -5px;
  margin-right: -5px;
  justify-content: center;
  align-items: center;
  @media (max-width: ${theme.breakpoints.sm}) {
    flex-wrap: wrap;
  }
`;

export const ModalButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;
  margin: 5px;
  background-color: ${props => props.bgColor ? props.bgColor : theme.colors.paleGrey};
  color: ${props => props.textColor ? props.textColor : theme.colors.darkNavy};
  transition: background-color .2s ease;

  &:hover {
    background-color: ${props => props.bgColor ? darken(0.07, props.bgColor) : darken(0.07, theme.colors.paleGrey)};
  }

  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
  }
`;
