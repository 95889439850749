import React from "react";
import SidebarTabStyles from "./styles";
import {withNamespaces} from 'react-i18next';
import {useSelector} from "react-redux";

import {ReactComponent as CarIcon} from "assets/icons/cars.svg";
import {ReactComponent as PassengersIcon} from "assets/icons/passengers.svg";
import {ReactComponent as EventsIcon} from "assets/icons/events.svg";

const SidebarTabsItem = ({isActiveTab, onClick, isDeactivateTab, t}) => {
  const unreadAlertsCounter = useSelector(({events}) => events.unreadAlertsCounter);
  const loggedUser = useSelector(({user}) => user.loggedUser);
 
  let sidebar = {
    manager: [
      {id: 0, icon: <PassengersIcon/>, count: 0, label: t('sidebar.workers')},
      {id: 1, icon: <CarIcon/>, count: 0, label: t('sidebar.vehicles')},
    ],
    dispatcher: [
      {id: 0, icon: <CarIcon/>, count: 0, label: t('sidebar.vehicles')},
      {id: 3, icon: <EventsIcon/>, count: unreadAlertsCounter, label: t('sidebar.events')},
    ]
  };

  const returnSidebarItems = () => {
    switch(loggedUser.type) {
      case 'manager':
        return sidebar.manager;
      default:
        return sidebar.dispatcher;
    };
  }

  return returnSidebarItems().map((item) => (
    <SidebarTabStyles.SidebarTabItem key={item.id}>
      <SidebarTabStyles.SidebarTab
        isActive={isActiveTab === item.id}
        isDeactivate={isDeactivateTab}
        onClick={() => onClick(item.id)}
      >
        {item.icon}
        {item.count !== 0 ? (
          <SidebarTabStyles.SidebarTabCountMsg>
            {item.count}
          </SidebarTabStyles.SidebarTabCountMsg>
        ) : null}
      </SidebarTabStyles.SidebarTab>
      <SidebarTabStyles.SidebarTabInfo>
        {item.label}
      </SidebarTabStyles.SidebarTabInfo>
    </SidebarTabStyles.SidebarTabItem>
  ));
};

export default withNamespaces()(SidebarTabsItem);
