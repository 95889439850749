import styled from 'styled-components'
import theme from 'config/theme'

export const ArrowContainer = styled.div`
  margin-left: 10px;
  
  svg {
    height: 8px;
    width: 8px;
  }
`;

export const DropDownContainer = styled.div`
  margin: 0 auto;
  position relative;
  max-height: 30px;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  justify-content: center;
  cursor: pointer;
  margin-right: auto;
  
  &:hover ${ArrowContainer} {
    svg {
      path {
        transition: .3s stroke;
        stroke: ${theme.colors.apple};
      }
    }
  }
`;

export const DropDownHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.darkNavy}
  font-size: 0.75rem;
`;

export const DropDownListContainer = styled.div``;

export const DropDownList = styled.div`
  padding: 0;
  margin: 0;
  position:absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  background: #ffffff;
  border: 1px solid ${theme.colors.veryLightBlue};
  min-width: 170px;
  border-radius: 4px;
  overflow: hidden;
`;

export const ListItem = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  padding: 8px;
  
  &:hover {
  background: ${theme.colors.apple};
  }
  
  svg {
    position: relative;
    top: 3px;
  }
`;

export const BoldText = styled.div`
  font-weight: 600;
  margin: 0 5px;
`;
