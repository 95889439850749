import styled from "styled-components";
import theme from "config/theme";
import { rem } from "polished";
import { CarsListsUsr } from "components/cars/CarsList/styles";

const TabsContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.paleGrey};
`;

const TabsContentInner = styled.div`
  width: 100%;
  height: calc(100% - 190px);
`;

const TabsContentContainer = styled.div`
  padding: 17px 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.apple} transparent;
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.apple};
    border-radius: 10px;
  }
`;

const TabsContentTitle = styled(CarsListsUsr)`
  font-size: ${rem("10px")};
  font-weight: ${theme.font.bolder};
  text-transform: uppercase;
  color: ${theme.colors.blueyGrey};
`;

const TabsContentDriverWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

const TabsContentDriverInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const TabsContentDriverName = styled.div`
  font-size: ${rem("12px")};
  font-weight: ${theme.font.bolder};
  line-height: ${rem("14px")};
  color: ${props => props.isCancelled ? theme.colors.blueyGrey : theme.colors.darkNavy};
`;

const TabsContentDriverInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;
`;

const TabsContentTripInfoWrap = styled.div`
  margin-top: 20px;
`;

const TabsContentTripInfoList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 18px;
`;

const TabsContentTripInfoItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 71px;

  &:not(:first-child) {
    padding-top: 26px;
  }

  &:last-of-type {
    min-height: 100px;
  }
`;

const TabsContentTripInfoIconWrap = styled.div``;

const TabsContentTripInfo = styled.div`
  padding-left: 25px;
`;

const TabsContentTripInfoTitle = styled(CarsListsUsr)`
  font-size: ${rem("10px")};
  font-weight: ${theme.font.regular};
  color: ${theme.colors.blueyGrey};
  margin-bottom: ${({ isBottom }) => (isBottom ? 0 : "5px")};
  margin-top: ${({ isBottom }) => (isBottom ? "5px" : 0)};
`;

const BulletsItem = styled.div`
  position: absolute;
  top: 0;
  left: 9px;
`;

const TaskStatusLabel = styled.div`
  margin-bottom: 5px;
  background-color: ${theme.colors.blueyGrey};
  color: ${theme.colors.white};
  font-size: ${rem('10px')};
  border-radius: 3px;
  padding: 3px 5px;
  display: inline-block;
`;

const TaskIconWrapper = styled.div`
  position: relative;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const CanceledIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: -4px;
  width: 10px;
  height: 10px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export {
  TabsContentInner,
  TabsContentWrapper,
  TabsContentContainer,
  TabsContentTitle,
  TabsContentDriverWrap,
  TabsContentDriverInfoWrap,
  TabsContentDriverName,
  TabsContentDriverInfo,
  TabsContentTripInfoWrap,
  TabsContentTripInfoList,
  TabsContentTripInfoItem,
  TabsContentTripInfoIconWrap,
  TabsContentTripInfo,
  TabsContentTripInfoTitle,
  BulletsItem,
  TaskStatusLabel,
  TaskIconWrapper,
  CanceledIconWrapper,
};
