import styled, { css, keyframes } from "styled-components";
import { rem, darken } from "polished";
import theme from "config/theme";

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const CarsLists = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  height: calc(100% - 14.6%);
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.apple} transparent;

  @media (max-width: 1023px) {
    height: calc(100vh - 271px);
  }

  @media (max-width: 466px) {
    height: calc(100vh - 290px);
  }

  @media (max-width: 346px) {
    height: calc(100vh - 323px);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.apple};
    border-radius: 10px;
  }
`;

export const CarsListsPin = styled.div`
  position: relative;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ color }) => color || theme.colors.paleGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-top: ${({ noSpace }) => (noSpace ? 0 : "16px")};
  transition: background-color 0.3s ease-in-out;
`;

export const CarsListsItem = styled.li`
  position: relative;
  font-size: ${rem("12px")};
  font-weight: ${theme.font.bolder};
  line-height: 1.2em;
  color: ${theme.colors.darkNavy};
  padding-left: 20px;
  display: flex;
  &:hover {
    ${CarsListsPin} {
      background-color: ${(props) =>
        props.color ? props.color : darken(0.1, theme.colors.paleGrey)};
    }
  }

  ${({ emptyItem }) =>
    emptyItem &&
    css`
      min-height: 67px;
    `}

  ${({ noHover }) =>
    noHover &&
    css`
      margin-top: 16px;
      align-items: center;

      ${CarsListsPin} {
        margin-top: 0;
        background-color: transparent;
      }

      &:hover {
        ${CarsListsPin} {
          background-color: transparent;
        }
      }
    `}

  ${({ isHover }) =>
    isHover &&
    css`
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.colors.paleGrey};
        transform: scaleX(0);
        transform-origin: left center;
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }
    `}
 
  &:hover {
    &:before {
      opacity: 1;
      transform: scaleX(1);
    }

    .hover-element {
      background-color: ${theme.colors.apple};
    }
  }
`;

export const CarsListsWrapper = styled.span`
  position: relative;
  display: block;
  width: 100%;
  padding: 16px 20px 16px 0;

  ${({ isCenter }) =>
    isCenter &&
    css`
      display: flex;
      align-items: center;
    `};
  
  @media (max-width: 380px) {
    padding: 12px 15px;
  }
`;

export const CarsListsUsr = styled.span`
  position: relative;
  font-size: ${rem("12px")};
  font-weight: ${theme.font.regular};
  line-height: 1.2em;
  color: ${theme.colors.blueyGrey};
  display: block;
  margin-top: ${({ noSpace }) => (noSpace ? 0 : "7px")};
  margin-bottom: 3px;
`;

export const CarsListsCar = styled(CarsListsUsr)`
  font-size: ${rem("10px")};
  font-weight: ${theme.font.bolder};
  text-transform: uppercase;
  margin-top: 0;
`;

export const CarSpinner = styled.div`
  animation: ${spin} 1s linear infinite;
  border: 3px solid #ddd;
  border-top: 3px solid ${theme.colors.apple};
  border-radius: 50%;
  height: 24px;
  min-width: 24px;
  margin-right: 16px;
  margin-top: 16px;
`;

export const CarsNotFound = styled.div`
  padding: 15px;
  margin: 15px;
  border-radius: 4px;
  background-color: ${({ bgColor }) =>
    bgColor ? theme.colors.darkNavy : theme.colors.paleGrey};
  color: ${({ bgColor }) => (bgColor ? "#ffffff" : theme.colors.darkNavy)};
  font-size: 12px;
`;

export const CarsDetailsName = styled(CarsListsUsr)`
  font-weight: ${theme.font.bolder};
  color: ${theme.colors.darkNavy};
  font-size: 13px;
`;

export const EditButtonsWrapper = styled.div`
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
`;

export const EditButton = styled.button`
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
  padding: 7px 10px 7px 27px;
  min-width: 100px;
  border-radius: 5px;
  border: none;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  background-color: ${props => props.bgColor ? props.bgColor : theme.colors.apple};
  text-align: left;
  position: relative;
  transition: background-color .2s ease;
  outline: none;
  &:hover {
    background-color: ${props => props.disabled ? theme.colors.markerGray : props.bgColor ? darken(0.1, props.bgColor) : darken(0.1, theme.colors.apple)};
  }
`;

export const EditButtonIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7px;
  /* width: 11px;
  height: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  svg {
    width: 11px;
    height: 9px;
    path {
      stroke: ${theme.colors.white};
    }
  }
`;

export const EditButtonText = styled.span`
  font-size: ${rem('11px')};
  color: ${theme.colors.white};
`;

export const CarsListStartTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
    path {
      stroke: ${theme.colors.blueyGrey};
    }
    circle:last-of-type {
      stroke: ${theme.colors.blueyGrey}
    }
  }
`;

export const CarsListStartTime = styled.div`
  margin-left: 5px;
  font-size: 10px;
  font-weight: normal;
  color: ${theme.colors.blueyGrey};
`;

export const CarPositionLink = styled.a`
  font-size: ${rem("12px")};
  font-weight: ${theme.font.regular};
  color: ${theme.colors.markerGray};
  text-decoration: none;
  transition: color 0.2s ease;
  &:hover {
    color: ${theme.colors.apple};
    svg path:last-of-type {
      fill: ${theme.colors.apple};
    }
  }
`;

export default {
  CarsLists,
  CarsListsItem,
  CarsListsWrapper,
  CarsListsUsr,
  CarsListsPin,
  CarsListsCar,
  CarSpinner,
  CarsNotFound,
  CarsDetailsName,
  EditButtonsWrapper,
  EditButton,
  EditButtonText,
  EditButtonIcon,
  CarsListStartTimeWrapper,
  CarsListStartTime,
  CarPositionLink,
};
