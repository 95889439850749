import * as types from './types'

export const setLoggedUser = user => {
  return {
    type: types.SET_LOGGED_USER,
    payload: user
  }
};

export const setDatabaseLoading = isLoading => {
  return {
    type: types.SET_DATABASE_LOADING,
    payload: isLoading,
  };
};

export const setDatabase = database => {
  return {
    type: types.SET_DATABASE,
    payload: database,
  };
};