import React, {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import LoginForm from '../LoginForm';
import LoginInfo from "../LoginInfo";
import LoginStyles from './styles';
import loginBg from 'assets/img/bg_gtv.jpg';
import API from 'api';
import {URL_LOGIN} from 'api/config';
import {setLoggedUser} from 'store/user/actions';
import {saveUser} from 'utils/auth';
import history from 'config/history';
import {withNamespaces} from 'react-i18next';

const Login = ({ t }) => {
  const [isLogging, setIsLogging] = useState(false);
  const [error, setError] = useState(null);
  const basicConfig = useSelector(({settings}) => settings.basicConfig);
  const loginForm = useRef(null);
  const d = useDispatch();

  const sendData = async (e, data) => {
    e.preventDefault();
    setIsLogging(true);

    const {login, password, database} = data;

    let config = {
      headers: {'X-DB-Id': database ? database : '1'},
    };

    const res = await API.post(URL_LOGIN, {
        login,
        password,
        type: basicConfig?.company_domain ? 'manager' : 'dispatcher',
      },
      config
    );
    setIsLogging(false);
    if (res && res.success) {
      saveUser({
        user: res.data.user,
        token: res.data.token,
        databaseID: database,
      });
      d(setLoggedUser(res.data.user));
      if (res.data.user.type === 'admin') {
        history.push('/settings');
      } else if (res.data.user.type === 'manager') {
        history.push('/company-map');
      } else history.push('/map');
    } else {
      if (res && res.data && res.data.form_error) setError(res.data.form_error);
      else setError(t('api.unexpectedError'));
    }
  };

  return (
    <LoginStyles.LoginWrapper>
      <LoginStyles.LeftSide>
        <LoginForm
          forwardRef={loginForm}
          isLogging={isLogging}
          onSubmit={sendData}
          resetError={() => setError(null)}
          error={error}
          basicConfig={basicConfig}/>
        <LoginInfo basicConfig={basicConfig}/>
      </LoginStyles.LeftSide>
      <LoginStyles.RightSide>
        <LoginStyles.BackgroundLogin image={loginBg}/>
      </LoginStyles.RightSide>
    </LoginStyles.LoginWrapper>
  )
};

export default withNamespaces()(Login);
