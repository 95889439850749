import * as types from "./types";

const initialState = {
  settings: null,
  initialSettingsValues: null,
  settingsErrors: null,
  settingsLoading: false,
  settingsFormSending: false,
  basicConfig: null,
  basicConfigLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case types.SET_INITIAL_SETTINGS_VALUES:
      return {
        ...state,
        initialSettingsValues: action.payload
      };
    case types.SET_SETTINGS_ERRORS:
      return {
        ...state,
        settingsErrors: action.payload
      };
    case types.SET_SETTINGS_LOADING:
      return {
        ...state,
        settingsLoading: action.payload
      };
    case types.SET_SETTINGS_FORM_SENDING:
      return {
        ...state,
        settingsFormSending: action.payload
      };
    case types.SET_BASIC_CONFIG:
      return {
        ...state,
        basicConfig: action.payload
      };
    case types.SET_BASIC_CONFIG_LOADING:
      return {
        ...state,
        basicConfigLoading: action.payload
      };
    default: 
      return state
  }
};
