import * as types from "./types";

export const setConfirmModalVisible = isVisible => {
  return {
    type: types.SET_CONFIRM_MODAL_VISIBLE,
    payload: isVisible,
  }
}

export const setModalCallback = callback => {
  return {
    type: types.SET_MODAL_CALLBACK,
    payload: callback,
  };
};

export const setModalContent = content => {
  return {
    type: types.SET_MODAL_CONTENT,
    payload: content,
  };
}; 
