import * as actions from './actions';
import API from 'api';
import {URL_GET_CARS, URL_GET_NAVI_CARS, URL_GET_COMPANY_CARS, URL_GET_NAVI_COMAPNY_CARS, URL_GET_ALL_NAVI_CARS} from 'api/config';
import {formatDate} from 'utils/global';
import {generateRandomColor} from 'utils/cars';

export const getCars = (filters = null) => {
  return async (dispatch, getState) => {
    dispatch(actions.setCarsLoading(true));

    const baseFilters = getState().filters.filters;
    let queryFilters = filters
      ? {
        search: filters.search,
        date: formatDate(baseFilters.date),
        return: baseFilters.return,
        type: filters.type,
      }
      : {
        search: '',
        date: formatDate(baseFilters.date),
        return: baseFilters.return,
        type: null,
      }
    const res = await API.get(URL_GET_CARS, {
      params: {
        ...queryFilters,
      }
    });
    dispatch(actions.setCarsLoading(false));

    if (res.success) {
      dispatch(actions.setCars(res.data));
    }
  }
};

export const getNaviCars = (routesIds) => {
  return async dispatch => {
    const res = await API.get(URL_GET_NAVI_CARS, {
      params: {
        route_id: routesIds,
      }
    });
    if (res.success) {
      dispatch(actions.setCarsPositions(res.data));
    }
  }
};

export const getAllNaviCars = (initFetch = false, filters) => async (dispatch, getState) => {
  const baseFilters = getState().filters.filters;
  let queryFilters = filters
    ? {
      search: filters.search,
      date: formatDate(baseFilters.date),
      return: baseFilters.return,
      type: filters.type,
    }
    : {
      search: '',
      date: formatDate(baseFilters.date),
      return: baseFilters.return,
      type: null,
    };

  const res = await API.get(URL_GET_ALL_NAVI_CARS, {
    params: {...queryFilters},
  });

  if (res.success && res.data) {
    dispatch(actions.setCarsPositions(res.data));
  }
};

export const getCompanyCars = (loading = true) => async (dispatch, getState) => {
  try {
    dispatch(actions.setCompanyCarsLoading(loading));
    const companyFilters = getState().filters.companyFilters;
    const carsColors = getState().cars.carsColors;

    let queryFilters = {
      date: formatDate(companyFilters.date),
      return: companyFilters.return,
      team_id: companyFilters.team_id === 'all' ? null : companyFilters.team_id,
    };

    const res = await API.get(URL_GET_COMPANY_CARS, {
      params: {
        ...queryFilters
      }
    });

    dispatch(actions.setCompanyCarsLoading(false));

    if (res && res.success) {
      let newCarsColors = carsColors;
      let selectedCars = [];

      res.data.forEach(car => {
        newCarsColors = {
          ...newCarsColors,
          [car.id]: carsColors[car.id] ? carsColors[car.id] : generateRandomColor(),
        };
        selectedCars = [
          ...selectedCars,
          {
            car: car,
            color: newCarsColors[car.id],
          },
        ];
      });

      dispatch(actions.setCarsColors(newCarsColors));
      dispatch(actions.setCompanyCars(res.data));
      dispatch(actions.setSelectedCompanyCars(selectedCars));
    }
  } catch (err) {
    dispatch(actions.setCompanyCarsLoading(false));
  }
};

export const getCompanyNaviCars = (carIds, routesIds) => {
  return async dispatch => {
    try {
      dispatch(actions.setCompanyCarsPositionsLoading(true));
      const res = await API.get(URL_GET_NAVI_COMAPNY_CARS, {
        params: {
          car_ids: carIds,
          route_ids: routesIds,
        }
      });
      dispatch(actions.setCompanyCarsPositionsLoading(false));
      if (res.success) {
        dispatch(actions.setCompanyCarsPositions(res.data));
      }
    } catch (err) {
      dispatch(actions.setCompanyCarsPositionsLoading(false));
    }
  };
};
