export const SET_ROUTES = 'SET_ROUTES';
export const SET_LOADING_ROUTE = 'SET_LOADING_ROUTE';
export const SET_ROUTES_COLORS = 'SET_ROUTES_COLORS';
export const SET_ROUTE_LOADING = 'SET_ROUTE_LOADING';
export const SET_ROUTE_DETAILS = 'SET_ROUTE_DETAILS';
export const SET_TRIP_DETAILS = 'SET_TRIP_DETAILS';
export const SET_TRIP_COLOR_DETAILS = 'SET_TRIP_COLOR_DETAILS';
export const SET_POINTS_ON_POLYLINE = 'SET_POINTS_ON_POLYLINE';
export const SET_TEMPORARY_POINTS_ON_POLYLINE = 'SET_TEMPORARY_POINTS_ON_POLYLINE';
export const SET_SELECTED_ROUTE = 'SET_SELECTED_ROUTE';
export const SET_EDIT_MODE_ENABLE = 'SET_EDIT_MODE_ENABLE';
export const SET_SECTION_POINTS = 'SET_SECTION_POINTS';
export const SET_NEW_ROUTE_POINTS = 'SET_NEW_ROUTE_POINTS';
export const SET_EDIT_ALERT_VISIBLE = 'SET_EDIT_ALERT_VISIBLE';
export const SET_FETCHED_NEW_ROUTE = 'SET_FETCHED_NEW_ROUTE';
export const SET_FETCHED_ROUTE_SAVING = 'SET_FETCHED_ROUTE_SAVING';
export const SET_BOUND_TO_MARKERS = 'SET_BOUND_TO_MARKERS';
export const SET_ROUTES_LINES_SHOWN = 'SET_ROUTES_LINES_SHOWN';
export const SET_COMPANIES_DESTINATIONS = 'SET_COMPANIES_DESTINATIONS';
export const SET_COMPANIES_DESTINATIONS_LOADING = 'SET_COMPANIES_DESTINATIONS_LOADING';
