import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FiltersRow, FilterLabel, FilterCol, TeamSelectWrapper} from './styles';
import SelectDate from 'components/__common/SelectDate';
import {setCompanyFilters} from 'store/filters/actions';
import {withNamespaces} from 'react-i18next';
import SimpleSelect from 'components/__common/SimpleSelect';
import ReturnFilter from '../ReturnFilter';
import {setSelectedCompanyCars} from 'store/cars/actions';

const CompanyFilters = ({ t }) => {

  const { companyCarsLoading } = useSelector(({ cars }) => cars);
  const { workersLoading } = useSelector(({ passengers }) => passengers);
  const { companiesDestinationsLoading } = useSelector(({ routes }) => routes);
  const { companyTeams } = useSelector(({ companies }) => companies);
  const companyFilters = useSelector(({filters}) => filters.companyFilters);

  const d = useDispatch();

	const handleFilterChange = (name, value) => {
		const newFilters = {
			...companyFilters,
			[name]: value
    };
    
    d(setSelectedCompanyCars([]));
		d(setCompanyFilters(newFilters));
  };

  const setSelectDefaultValue = () => {
    return companyFilters.team_id 
      ? {
        label: companyFilters.team_id === 'all' ? t('filters.all') : companyFilters.team_id,
        value: companyFilters.team_id,
      }
      : null;
  };

  const dataLoading = companyCarsLoading || workersLoading || companiesDestinationsLoading;

  return (
    <FiltersRow>
      <FilterCol>
        <FilterLabel>{t('filters.labels.departureDate')}</FilterLabel>
        <SelectDate
          loading={dataLoading}
          name={'date'}
          valueDate={companyFilters.date}
          onChange={handleFilterChange}
        />
      </FilterCol>
      {companyTeams.length > 0 &&
        <FilterCol>
          <FilterLabel>{t('filters.labels.team')}</FilterLabel>
          <SimpleSelect 
            wrapper={TeamSelectWrapper}
            className={'company-select'}
            placeholder={'Wybierz'}
            options={[{value: 'all', label: t('filters.all')}, ...companyTeams]}
            value={setSelectDefaultValue()}
            onChange={e => handleFilterChange('team_id', e.value)}
          />
        </FilterCol>
      }
      <FilterCol>
        <FilterLabel>{t('filters.labels.tripType')}</FilterLabel>
        <ReturnFilter loading={dataLoading} allOptionEnabled={false} value={companyFilters.return} handleFilterChange={handleFilterChange} />
      </FilterCol>
    </FiltersRow>
  );
};

export default withNamespaces()(CompanyFilters);
