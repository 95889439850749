import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Share } from 'assets/icons/share.svg';
import { ReactComponent as Link } from 'assets/icons/link.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ShareDropdown, ShareIconWrapper, ShareContent, ShareOption } from './styles';
import { PoseGroup } from 'react-pose';
import { withNamespaces } from 'react-i18next';

const SharePosition = ({ text, data, clipboardText, mailText, reverse = false, t }) => {
  const dropdownRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    left: 0,
    top: 0,
  });

  useEffect(() => {
    const listener = e => {
      if (dropdownOpen && dropdownContentRef.current && !dropdownContentRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [dropdownOpen, dropdownContentRef]);

  const toggleDropdown = () => {
    if (!dropdownRef) {
      return;
    }

    if (dropdownOpen) {
      setDropdownOpen(false);
      return;
    }

    const vieportOffset = dropdownRef.current.getBoundingClientRect();
    const { top, left } = vieportOffset;
    setDropdownPosition({ top: top + 30, left });
    setDropdownOpen(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data);
    setDropdownOpen(false);
  };

  const prepareMailContent = () => {
    const content = `${t('passenger.clickToFollowLocation')}: ${data}`;
    return `mailto:?body=${content}`;
  };

  return (
    <>
      <ShareDropdown ref={dropdownRef} onClick={() => toggleDropdown()}>
        <ShareIconWrapper>
          <Share />
        </ShareIconWrapper>
        {text}
      </ShareDropdown>
      <PoseGroup>
        {dropdownOpen && (
          <ShareContent
            key='share'
            ref={dropdownContentRef}
            reverse={reverse}
            left={dropdownPosition.left}
            top={dropdownPosition.top}
          >
            {clipboardText && clipboardText.length && (
              <ShareOption onClick={copyToClipboard}>
                <Link />{clipboardText}
              </ShareOption>
            )}
            {mailText && mailText.length && (
              <ShareOption as='a' href={prepareMailContent()}>
                <Mail />{mailText}
              </ShareOption>
            )}
          </ShareContent>
        )}
      </PoseGroup>
    </>
  );
};

export default withNamespaces()(SharePosition);