import * as React from "react";

const FinishIcon = ({ color }) => {
  return (
    <svg width={20} height={12.541} viewBox="0 0 7.502 12.541">
      <path
        fill={color ? color : "#000"}
        data-name="Path 49"
        d="M0 0h7.5L4.011 3.436 7.5 7.093H1.374v5.448H-.002V0z"
      />
    </svg>
  );
};

export default FinishIcon;
