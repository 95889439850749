import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitcherLabel = styled.div`
  font-size: ${rem('11px')};
  color: ${theme.colors.darkNavy};
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  margin-right: 0px;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 22px;
  border-radius: 15px;
  background: ${theme.colors.paleGrey};
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 3px;
    background: ${theme.colors.white};
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.2);
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 18px;
  &:checked + ${CheckBoxLabel} {
    background: rgba(102,203,53,0.1);
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: 22px;
      background: ${theme.colors.apple};
      box-shadow: none;
      transition: 0.2s;
    }
  }
`;
