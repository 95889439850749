import styled from "styled-components";
import theme from 'config/theme';
import {rem} from 'polished';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

export const Heading = styled.h1`
  font-size: ${rem('20px')};
  font-weight: bold;
  margin-right: 30px;
  line-height: ${rem('24px')};
  @media (max-width: ${theme.breakpoints.md}) {
    font-size: ${rem('17px')};
    line-height: ${rem('21px')};
  }
`;


