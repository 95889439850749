import * as actions from './actions';
import API from 'api';
import {URL_GET_SAVED_FILTERS} from 'api/config';

export const getSavedFilters = () => async dispatch => {
  try {
    dispatch(actions.setSavedFiltersLoading(true));
    const res = await API.get(URL_GET_SAVED_FILTERS);
    dispatch(actions.setSavedFiltersLoading(false));
    dispatch(actions.setFiltersFetched(true));

    if (res.success) {
      dispatch(actions.setFilters({
        date: new Date(res.data.date),
        return: res.data.return,
      }));
    }
  } catch (err) {
    dispatch(actions.setFiltersFetched(true));
  }
};
