import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCompanyCars} from 'store/cars/thunks'
import {CompanyCarWrapper, HeaderWrapper, HeaderContent, CompanyCarWrapperInner} from './styles';
import {withNamespaces} from 'react-i18next';
import CompanyCarItem from "../CompanyCarItem";
import {Spinner, SpinnerWrapper} from "../../../styled/Spinner";
import {SidebarListNotFound} from 'components/sidebar/SidebarPanel/styles';
import BackButton from "components/__common/BackButton";

const CompanyCars = ({activePanel, closeSidebar, activeEmployeeList, t}) => {
  const { companyCars, companyCarsLoading } = useSelector(({cars}) => cars);
  const { companyDataRefreshTime, companyFilters } = useSelector(({filters}) => filters);
  const d = useDispatch();

  useEffect(() => {
    if (activePanel === 1 && !companyCarsLoading) {
      d(getCompanyCars(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, activePanel, companyDataRefreshTime]);

  useEffect(() => {
    let refrechCarsList;

    if (!companyCarsLoading) {
      d(getCompanyCars(true));
      refrechCarsList = setInterval(() => d(getCompanyCars(false)), 60000);
    }

    return () => {
      clearInterval(refrechCarsList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, companyFilters, companyDataRefreshTime]);

  return (
    <CompanyCarWrapper>
      <CompanyCarWrapperInner>
        <HeaderWrapper>
          <HeaderContent>{t('companyCars.companyCarsHeading')}</HeaderContent>
          <BackButton onClick={closeSidebar}/>
        </HeaderWrapper>
        {companyCarsLoading ? (
          <SpinnerWrapper>
            <Spinner/>
          </SpinnerWrapper>
        ) : (
          <>
            {companyCars.length ? (
              companyCars.map((car, index) => {
                return (
                  <CompanyCarItem
                    key={index}
                    index={index}
                    car={car}
                    activeEmployeeList={activeEmployeeList} />
                );
            })
            ) : (
              <SidebarListNotFound>{t('companyCars.notFound')}</SidebarListNotFound>
            ) }
          </>
        )}
      </CompanyCarWrapperInner>
    </CompanyCarWrapper>
  )
};

export default withNamespaces()(CompanyCars);
