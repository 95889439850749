import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 240px;
  background-color: ${theme.colors.darkNavy};
  border-top-right-radius: 15px;
  position: relative;
  z-index: 100;
  @media (max-width: ${theme.breakpoints.md}) {
    position: fixed;
    bottom: 0;
    height: 80px;
    min-height: auto;
    width: 100%;
    border-top-left-radius: 15px;
    display: flex;
    align-items: center;
  }
`;

export const Topside = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  @media (max-width: ${theme.breakpoints.md}) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    display: none;
  }
`;

export const Logo = styled.img`
  margin-right: 15px;
`;

export const Title = styled.h2`
  font-size: ${rem('12px')};
  color: ${theme.colors.white};
  padding-right: 10px;
  @media (max-width: ${theme.breakpoints.md}) {
    display: none;
  }
`;

export const NavWrapper = styled.div`
  padding: 20px 0;
  @media (max-width: ${theme.breakpoints.md}) {
    flex: 1;
    justify-content: center;
    padding: 0;
  }
`;

export const Nav = styled.ul`
  display: block;
  list-style-type: none;
  padding: 0;
  @media (max-width: ${theme.breakpoints.md}) {
    display: flex;
    justify-content: center;
    margin: 0;
  }
`;
