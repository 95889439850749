import React from 'react';
import {Wrapper} from './styles';
import {Spinner} from 'styled/Spinner';

const PageLoader = ({ isFixed }) => {
  return (
    <Wrapper fixed={isFixed ? isFixed : true}>
      <Spinner />
    </Wrapper>
  );
}

export default PageLoader;