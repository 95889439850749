import React from 'react';
import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
  SwitcherWrapper,
  SwitcherLabel,
} from './styles';

const Switcher = ({ label, checked, onSwitcherChange, name }) => {
  return (
    <SwitcherWrapper>
      <CheckBoxWrapper>
        <CheckBox onChange={onSwitcherChange} checked={checked} id={name} type="checkbox" />
        <CheckBoxLabel htmlFor={name} />
      </CheckBoxWrapper>
      <SwitcherLabel>{label}</SwitcherLabel>
    </SwitcherWrapper>
  )
};

export default Switcher;
