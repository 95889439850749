import * as actions from "./actions";
import API from "api";
import {URL_DATABASES} from 'api/config';

export const getDatabasesList = () => {
  return async (dispatch) => {
    dispatch(actions.setDatabaseLoading(true));
    const res = await API.get(URL_DATABASES);
    dispatch(actions.setDatabaseLoading(false));

    if (res.success && res.data) {
      dispatch(actions.setDatabase(res.data));
    }
  };
};