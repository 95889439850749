import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const LabelWrapper = styled.div`
  padding: 4px 5px;
  background-color: ${theme.colors.darkNavy};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const LabelCarWrapper = styled.div`
  display: flex;
  margin-right: 4px;
`;

export const LabelName = styled.span`
  font-size: ${rem('10px')};
  font-weight: ${theme.font.bolder};
  color: ${theme.colors.white};
`;