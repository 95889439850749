import React from 'react';
import {
  ItemWrapper,
  ActionsLabel,
  LocationLabel,
  GrayLabel,
  SortButtonWrapper
} from './styles';

// import {ReactComponent as CaretDown} from 'assets/icons/caretDown.svg';
// import {ReactComponent as CaretUp} from 'assets/icons/caretUp.svg';

const SortHeader = () => {
  return (
    <ItemWrapper>
      <GrayLabel>
        Imię i nazwisko
        <SortButtonWrapper>
          {/* <CaretDown/>
          <CaretUp/> */}
        </SortButtonWrapper>
      </GrayLabel>
      <GrayLabel>
        Firma
        <SortButtonWrapper>
          {/* <CaretDown/>
          <CaretUp/> */}
        </SortButtonWrapper>
      </GrayLabel>
      <LocationLabel>Podrzędne oddziały (ID)</LocationLabel>
      <GrayLabel>Numer telefonu</GrayLabel>
      <GrayLabel>Login</GrayLabel>
      <ActionsLabel>Akcje</ActionsLabel>
    </ItemWrapper>
  )
};

export default SortHeader;

