import styled from "styled-components";
import {rem} from "polished";
import theme from "config/theme";

const SearchForm = styled.form`
	display: inline-block;
	width: 100%;
	max-width: ${({ wide }) => wide ? '100%' : '293px'};
	
	@media(max-width: 601px) {
		max-width: none;
	}
`

const SerachLabel = styled.label`
	position: relative;
	display: block;
`

const SearchInput = styled.input`
	width: 100%;
	height: 40px;
	border-radius: 4px;
	background-color: ${props => props.bgColor ? props.bgColor : theme.colors.paleGrey};
	font-family: ${theme.font.family};
	font-size: ${rem('12px')};
	font-weight: ${theme.font.regular};
	line-height: 1.17em;
	color: ${theme.colors.darkNavy};
	border: 1px solid transparent;
	padding: 12px 15px 12px 40px;
	transition: border-color .3s ease;
	opacity: ${props => props.disabled ? 0.3 : 1};
	
	&:focus {
		outline: none;
		border-color: ${theme.colors.apple};
	}
	
	::placeholder {
		font-family: ${theme.font.family};
		font-size: ${rem('12px')};
		font-weight: ${theme.font.regular};
		line-height: 1.17em;
		color: ${theme.colors.darkNavy};
	}
`

const SearchMagnify = styled.button`
	background-image: url(${({ icon }) => icon});
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	appearance: none;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 13px;
	height: 13px;
	border: none;
	background-color: transparent;
	margin-left: 16px;
	cursor: pointer;
	
	&:focus {
		outline: none;
	}
`

export default {
	SearchForm,
	SerachLabel,
	SearchInput,
	SearchMagnify
}