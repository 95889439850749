import React, {useEffect, useState} from 'react';
import {routes} from 'config/routes';
import {Wrapper, CompanyTable, NoCompanies, NoCompaniesWrapper} from './styles';
import {ReactComponent as AddIcon} from 'assets/icons/add.svg'
import theme from 'config/theme';

import {useDispatch, useSelector} from "react-redux";
import {getCompanies, removeCompany} from 'store/companies/thunks';
import {showModalWithCallback} from 'store/modal/thunks';

import BoldHeading from "../../__common/BoldHeading";
import Fade from 'components/__common/Fade';
import BorderedButton from "../../__common/BorderedButton";
import CompanyItem from "../CompanyItem";
import SortHeader from "../../__common/SortHeader";
import PageLoader from 'components/__common/PageLoader';
import Pagination from 'components/__common/Pagination';
import Search from 'components/__common/Search';

const Companies = () => {

  const [searchValue, setSearchValue] = useState('');

  const companies = useSelector(({companies}) => companies.companies);
  const companiesLoading = useSelector(({companies}) => companies.companiesLoading);
  const companyRemovingId = useSelector(({companies}) => companies.companyRemovingId);
  
  const d = useDispatch();
  const params = { search: searchValue };

  useEffect(() => {
    d(getCompanies(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);

  const handleSearchChange = (name, value) => {
    setSearchValue(value);
  };

  const handleSearchSubmit = () => {
    d(getCompanies({ search: searchValue }));
  };

  const handleAccountRemove = id => {
    const content = {
      text: 'Operacja usunięcia jest nieodwracalna.',
      boldText: 'Czy na pewno chcesz usunąć rekord?',
      confirmButtonText: 'Tak, usuń',
      cancelButtonText: 'Anuluj',
      icon: null,
    };

    d(showModalWithCallback(content, () => {
      d(removeCompany(id));
    }));
  };

  const handlePageChange = page => {
    d(getCompanies({...params, page}))
  };

  if (companiesLoading) {
    return <PageLoader isFixed={false}/>
  }

  return (
    <Fade>
      <BoldHeading text={"Konta firmowe"}>
        <BorderedButton
          link={routes.admin.nested.companies.nested.new.url}
          text={"Stwórz nowe"}
          icon={<AddIcon/>}/>
      </BoldHeading>
      <Search
        bgColor={theme.colors.white}
        inputName={'search'}
        inputValue={searchValue}
        onSubmit={handleSearchSubmit}
        onChange={handleSearchChange}
        placeholder={'Wyszukaj...'} />
      {companies && companies.items
        ? (
          <CompanyTable>
            <Wrapper>
              <SortHeader/>
              {companies.items.map((manager, index) => (
                <CompanyItem
                  key={index}
                  isRemoving={companyRemovingId === manager.id}
                  manager={manager}
                  onRemove={handleAccountRemove}/>
              ))}
            </Wrapper>
          </CompanyTable>
        ) : (
          <NoCompaniesWrapper>
            <NoCompanies>Nie znaleziono wyników spełniających podane kryteria.</NoCompanies>
          </NoCompaniesWrapper>
        )
      }
      {companies && companies.pagination &&
        <Pagination 
          onPageClick={handlePageChange}
          count={companies.pagination.count}
          pageCount={companies.pagination.page_count}
          currentPage={companies.pagination.page}
          total={companies.pagination.total_count} />
      }
    </Fade>
  );
};

export default Companies;