import React, {useState, useEffect, Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import CarsStyles from "./styles";
import {TopSideContainer} from '../../__common/TopSide/styles';
import CarsList from "../CarsList";
import CarsFilters from "../CarsFilters";
import {SpinnerWrapper, Spinner} from "../../../styled/Spinner";
import {getCars} from "store/cars/thunks";
import {getRoute, getRouteDetails, showEditAlertAndHide} from "store/routes/thunks";
import {showModalWithCallback} from 'store/modal/thunks';
import {
  setRoutes,
  setRoutesDetails,
  setEditModeEnabled,
  setSectionPoints,
  setTemporaryPointsOnPolyline,
} from "store/routes/actions";
import {ReactComponent as PhoneIcon} from "assets/icons/phone.svg";
import {ReactComponent as ChatSmallIcon} from "assets/icons/chatSmall.svg";
import Tabs from "components/tabs/Tabs";
import TabsContent from "components/tabs/TabsContent";
import TabsHeader from "components/tabs/TabsHeader";
import shortid from "shortid";
import {withNamespaces} from 'react-i18next';

const Cars = ({
  activePanel,
  pickRoute,
  loadingRouteId,
  routes,
  onClick,
  handleNewRouteSave,
  initGettingPositionsInterval,
  t
}) => {

  const [activePanelDetail, setActivePanelDetail] = useState(null);
  const [isActive, setIsActive] = useState(0);
  const [filtersValues, setFiltersValues] = useState({
    type: null,
    search: "",
  });

  const cars = useSelector(({ cars }) => cars.cars);
  const carsColors = useSelector(({ cars }) => cars.carsColors);
  const carsFilters = useSelector(({ cars }) => cars.carsFilters);
  const filters = useSelector(({ filters }) => filters.filters);
  const carsLoading = useSelector(({ cars }) => cars.carsLoading);
  const routeLoading = useSelector(({ routes }) => routes.routeLoading);
  const newRoutePoints = useSelector(({ routes }) => routes.newRoutePoints);
  const routePointsOnPolyline = useSelector(({routes}) => routes.routePointsOnPolyline);
  const editModeEnabled = useSelector(({routes}) => routes.editModeEnabled);
  const fetchedRouteSaving = useSelector(({routes}) => routes.fetchedRouteSaving);
  const routeLinesShown = useSelector(({routes}) => routes.routeLinesShown);

  const d = useDispatch();

  useEffect(() => {
    if (activePanel === 0) d(getCars(prepareFiltersQuery(filtersValues)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, activePanel]);

  useEffect(() => {
    setFiltersValues({
      ...filtersValues,
      type: carsFilters.type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carsFilters]);

  useEffect(() => {
    if (activePanel === 0) {
      const filtersQuery = prepareFiltersQuery(filtersValues);
      d(getCars(filtersQuery));
      d(setRoutes([]));
      setActivePanelDetail(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, filters]);

  const prepareFiltersQuery = (filtersObject = filtersValues) => {
    const { type, search } = filtersObject;
    return {
      search: search,
      type: type,
    };
  };

  const filterCars = newFilters => {
    const filtersQuery = prepareFiltersQuery(newFilters);
    d(getCars(filtersQuery));
    d(setRoutes([]));
    initGettingPositionsInterval(filtersQuery);
  };

  const checkWhichPanelIdMustBeOpen = (id, carName, carId) => {
    d(getRouteDetails(id, routes, carName, carId));
    setActivePanelDetail(id);
    const routesIds = routes.map((route) => route.id);

    if (routesIds.length) {
      if (!routesIds.includes(id)) {
        d(getRoute(id, routes, carName, carId, true));
      }
    } else {
      d(getRoute(id, routes, carName, carId, true));
    }
  };

  const hideWhichPanelIdMustBeClose = () => {
    setActivePanelDetail(null);
    d(setRoutesDetails([]));
  };

  const handleActiveTab = (id) => {
    setIsActive(id);
  };

  const handleCancelEdit = () => {
    d(showModalWithCallback(() => {
      hideWhichPanelIdMustBeClose();
    }));
  };

  return activePanelDetail ? (
    <>
      {cars.dowoz &&
        cars.dowoz.length &&
        cars.dowoz.map((dowoz) => {
          return (
            activePanelDetail === dowoz.route_id && (
              <Fragment key={dowoz.route_id}>
                <TopSideContainer editModeEnabled={editModeEnabled}>
                  <CarsStyles.CarsFiltersWrapper>
                    {routeLoading ? t('login.loading') : routes && routes.length && dowoz.date_begin
                      ? routes.map((route) => {
                          return (
                            route.id === dowoz.route_id &&
                              <TabsHeader
                                key={shortid.generate()}
                                isRouteEditable={route.route.length > 0 && routeLinesShown ? true : false}
                                tripCarHeader={t('cars.vehicleTrip')}
                                color={carsColors[route.car_id]}
                                carId={dowoz.car.id}
                                carName={dowoz.car.name}
                                editModeEnabled={editModeEnabled}
                                newRoutePointsLength={newRoutePoints.length}
                                setSectionPoints={setSectionPoints}
                                setEditModeEnabled={setEditModeEnabled}
                                showEditAlertAndHide={showEditAlertAndHide}
                                setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                                routePointsOnPolyline={routePointsOnPolyline}
                                handleNewRouteSave={handleNewRouteSave}
                                fetchedRouteSaving={fetchedRouteSaving}
                                onClick={
                                  editModeEnabled
                                  ? () => handleCancelEdit()
                                  : () => hideWhichPanelIdMustBeClose()
                                }
                                routeLoading={routeLoading}
                              />
                          );
                      }) : !dowoz.date_begin && (
                        <TabsHeader
                          key={shortid.generate()}
                          isRouteEditable={false}
                          color={"#f4f8fa"}
                          carId={dowoz.car.id}
                          carName={t('cars.backToCarsList')}
                          editModeEnabled={editModeEnabled}
                          newRoutePointsLength={newRoutePoints.length}
                          setSectionPoints={setSectionPoints}
                          setEditModeEnabled={setEditModeEnabled}
                          showEditAlertAndHide={showEditAlertAndHide}
                          setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                          routePointsOnPolyline={routePointsOnPolyline}
                          handleNewRouteSave={handleNewRouteSave}
                          fetchedRouteSaving={fetchedRouteSaving}
                          onClick={
                            editModeEnabled
                            ? () => handleCancelEdit()
                            : () => hideWhichPanelIdMustBeClose()
                          }
                        />
                      )
                    }
                  </CarsStyles.CarsFiltersWrapper>
                </TopSideContainer>
                <Tabs onClick={handleActiveTab} isActive={isActive} />
                <TabsContent
                  primaryIconInfo={<PhoneIcon />}
                  secondaryIconInfo={<ChatSmallIcon />}
                  secondaryTextInfo={t('cars.write')}
                  routes={routes}
                  route={dowoz}
                  isActiveTabsContent={isActive}
                  routeLoading={routeLoading}
                />
              </Fragment>
            )
          );
        })}
      {cars.cel &&
        cars.cel.length &&
        cars.cel.map((cel) => {
          return (
            activePanelDetail === cel.route_id && (
              <Fragment key={cel.route_id}>
                <TopSideContainer>
                  <CarsStyles.CarsFiltersWrapper>
                    {routeLoading ? t('login.loading') : routes && routes.length && cel.date_begin
                      ? routes.map((route) => {
                          return (
                            route.id === cel.route_id && (
                              <TabsHeader
                                key={shortid.generate()}
                                isRouteEditable={route.route.length > 0 && routeLinesShown ? true : false}
                                tripCarHeader={t('cars.vehicleTrip')}
                                color={carsColors[route.car_id]}
                                carName={cel.car.name}
                                carId={cel.car.id}
                                editModeEnabled={editModeEnabled}
                                newRoutePointsLength={newRoutePoints.length}
                                setSectionPoints={setSectionPoints}
                                setEditModeEnabled={setEditModeEnabled}
                                showEditAlertAndHide={showEditAlertAndHide}
                                setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                                routePointsOnPolyline={routePointsOnPolyline}
                                handleNewRouteSave={handleNewRouteSave}
                                fetchedRouteSaving={fetchedRouteSaving}
                                onClick={
                                  editModeEnabled
                                  ? () => handleCancelEdit()
                                  : () => hideWhichPanelIdMustBeClose()
                                }
                              />
                            )
                          );
                        })
                      : (
                          <TabsHeader
                            key={shortid.generate()}
                            isRouteEditable={false}
                            color={"#f4f8fa"}
                            carId={cel.car.id}
                            carName={t('cars.backToCarsList')}
                            editModeEnabled={editModeEnabled}
                            newRoutePointsLength={newRoutePoints.length}
                            setSectionPoints={setSectionPoints}
                            setEditModeEnabled={setEditModeEnabled}
                            showEditAlertAndHide={showEditAlertAndHide}
                            setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                            routePointsOnPolyline={routePointsOnPolyline}
                            handleNewRouteSave={handleNewRouteSave}
                            fetchedRouteSaving={fetchedRouteSaving}
                            onClick={
                              editModeEnabled
                              ? () => handleCancelEdit()
                              : () => hideWhichPanelIdMustBeClose()
                            }
                          />
                        )}
                  </CarsStyles.CarsFiltersWrapper>
                </TopSideContainer>
                <Tabs onClick={handleActiveTab} isActive={isActive} />
                <TabsContent
                  primaryIconInfo={<PhoneIcon />}
                  secondaryIconInfo={<ChatSmallIcon />}
                  secondaryTextInfo={t('cars.write')}
                  routes={routes}
                  route={cel}
                  isActiveTabsContent={isActive}
                  routeLoading={routeLoading}
                />
              </Fragment>
            )
          );
        })}
      {cars.rozwoz &&
        cars.rozwoz.length &&
        cars.rozwoz.map((rozwoz) => {
          return (
            activePanelDetail === rozwoz.route_id && (
              <Fragment key={rozwoz.route_id}>
                <TopSideContainer>
                  <CarsStyles.CarsFiltersWrapper>
                    {routeLoading ? t('login.loading') : routes && routes.length && rozwoz.date_begin
                      ? routes.map((route) => {
                          return (
                            route.id === rozwoz.route_id && (
                              <TabsHeader
                                key={shortid.generate()}
                                isRouteEditable={route.route.length > 0 && routeLinesShown ? true : false}
                                tripCarHeader={t('cars.vehicleTrip')}
                                color={carsColors[route.car_id]}
                                carName={rozwoz.car.name}
                                carId={rozwoz.car.id}
                                editModeEnabled={editModeEnabled}
                                newRoutePointsLength={newRoutePoints.length}
                                setSectionPoints={setSectionPoints}
                                setEditModeEnabled={setEditModeEnabled}
                                showEditAlertAndHide={showEditAlertAndHide}
                                setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                                routePointsOnPolyline={routePointsOnPolyline}
                                handleNewRouteSave={handleNewRouteSave}
                                fetchedRouteSaving={fetchedRouteSaving}
                                onClick={
                                  editModeEnabled
                                  ? () => handleCancelEdit()
                                  : () => hideWhichPanelIdMustBeClose()
                                }
                              />
                            )
                          );
                        })
                      : (
                          <TabsHeader
                            key={shortid.generate()}
                            color={"#f4f8fa"}
                            carName={t('cars.backToCarsList')}
                            carId={rozwoz.car.id}
                            editModeEnabled={editModeEnabled}
                            newRoutePointsLength={newRoutePoints.length}
                            setSectionPoints={setSectionPoints}
                            setEditModeEnabled={setEditModeEnabled}
                            showEditAlertAndHide={showEditAlertAndHide}
                            setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                            routePointsOnPolyline={routePointsOnPolyline}
                            handleNewRouteSave={handleNewRouteSave}
                            fetchedRouteSaving={fetchedRouteSaving}
                            onClick={
                              editModeEnabled
                              ? () => handleCancelEdit()
                              : () => hideWhichPanelIdMustBeClose()
                            }
                          />
                        )}
                  </CarsStyles.CarsFiltersWrapper>
                </TopSideContainer>
                <Tabs onClick={handleActiveTab} isActive={isActive} />
                <TabsContent
                  primaryIconInfo={<PhoneIcon />}
                  secondaryIconInfo={<ChatSmallIcon />}
                  secondaryTextInfo={t('cars.write')}
                  routes={routes}
                  route={rozwoz}
                  isActiveTabsContent={isActive}
                  routeLoading={routeLoading}
                />
              </Fragment>
            )
          );
        })}
      {cars.inne &&
        cars.inne.length &&
        cars.inne.map((inne) => {
          return (
            activePanelDetail === inne.route_id && (
              <Fragment key={inne.route_id}>
                <TopSideContainer>
                  <CarsStyles.CarsFiltersWrapper>
                    {routeLoading ? t('login.loading') : routes && routes.length && inne.date_begin
                      ? routes.map((route) => {
                          return (
                            route.id === inne.route_id && (
                              <TabsHeader
                                key={shortid.generate()}
                                isRouteEditable={route.route.length > 0 && routeLinesShown ? true : false}
                                tripCarHeader={t('cars.vehicleTrip')}
                                color={carsColors[route.car_id]}
                                carName={inne.car.name}
                                carId={inne.car.id}
                                editModeEnabled={editModeEnabled}
                                newRoutePointsLength={newRoutePoints.length}
                                setSectionPoints={setSectionPoints}
                                setEditModeEnabled={setEditModeEnabled}
                                showEditAlertAndHide={showEditAlertAndHide}
                                setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                                routePointsOnPolyline={routePointsOnPolyline}
                                handleNewRouteSave={handleNewRouteSave}
                                fetchedRouteSaving={fetchedRouteSaving}
                                onClick={
                                  editModeEnabled
                                  ? () => handleCancelEdit()
                                  : () => hideWhichPanelIdMustBeClose()
                                }
                              />
                            )
                          );
                        })
                      : (
                          <TabsHeader
                            key={shortid.generate()}
                            color={"#f4f8fa"}
                            carName={t('cars.backToCarsList')}
                            carId={inne.car.id}
                            editModeEnabled={editModeEnabled}
                            newRoutePointsLength={newRoutePoints.length}
                            setSectionPoints={setSectionPoints}
                            setEditModeEnabled={setEditModeEnabled}
                            showEditAlertAndHide={showEditAlertAndHide}
                            setTemporaryPointsOnPolyline={setTemporaryPointsOnPolyline}
                            routePointsOnPolyline={routePointsOnPolyline}
                            handleNewRouteSave={handleNewRouteSave}
                            fetchedRouteSaving={fetchedRouteSaving}
                            onClick={
                              editModeEnabled
                              ? () => handleCancelEdit()
                              : () => hideWhichPanelIdMustBeClose()
                            }
                          />
                        )}
                  </CarsStyles.CarsFiltersWrapper>
                </TopSideContainer>
                <Tabs onClick={handleActiveTab} isActive={isActive} />
                <TabsContent
                  primaryIconInfo={<PhoneIcon />}
                  secondaryIconInfo={<ChatSmallIcon />}
                  secondaryTextInfo={t('cars.write')}
                  routes={routes}
                  route={inne}
                  isActiveTabsContent={isActive}
                  routeLoading={routeLoading}
                />
              </Fragment>
            )
          );
      })}
    </>
  ) : (
    <>
      <TopSideContainer>
        <CarsFilters
          filterCars={filterCars}
          setFiltersValues={setFiltersValues}
          filtersValues={filtersValues}
          onClick={onClick} />
      </TopSideContainer>
      {carsLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <CarsList
          routeLoading={routeLoading}
          pickRoute={pickRoute}
          carsList={cars}
          carsColors={carsColors}
          loadingRouteId={loadingRouteId}
          setRoutesloadingRouteId={loadingRouteId}
          routes={routes}
          onClick={checkWhichPanelIdMustBeOpen}
        />
      )}
    </>
  );
};

export default withNamespaces()(Cars);
