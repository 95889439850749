import React from 'react';
import LoginInfoStyles from './styles';
import {withNamespaces} from 'react-i18next';


const LoginInfo = ({ basicConfig, t }) => {

	const adminMail = basicConfig ? basicConfig.admin_email : 'admin@gtvbus.pl'

	return (
		<LoginInfoStyles.LoginInfoWrapper>
			<LoginInfoStyles.LoginInfoMsg>{t('login.forgotPassword')}</LoginInfoStyles.LoginInfoMsg>
			<LoginInfoStyles.LoginInfoLink href={`maito:${adminMail}`}>{adminMail}</LoginInfoStyles.LoginInfoLink>
		</LoginInfoStyles.LoginInfoWrapper>
	)
}

export default withNamespaces()(LoginInfo);