import styled, {css} from "styled-components";
import theme from "config/theme";

export const TopSideContainer = styled.div`
  padding: 24px 20px 16px;
  border-bottom: 1px solid ${theme.colors.paleGrey};
  background-color: ${theme.colors.white};
  min-height: 14.6%;

  ${({noFilters}) => noFilters && css`
    padding: 16px 20px;
    min-height: auto;
  `};

  @media (max-width: 1023px) {
    padding: 24px 20px;
    min-height: auto;

    ${({noFilters}) => noFilters && css`
      padding: 16px 20px;
    `};
  }

  @media (max-width: 601px) {
    padding: ${props => props.editModeEnabled ? '0 20px 20px 20px': '20px'};
  }
`;

export const TopSideWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ wide }) => (wide ? "100%" : "auto")};
`;

export const TopSideArrowButtonWrapper = styled.div`
  margin-left: 15px;  
`;
