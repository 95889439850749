import React from 'react';
import {withNamespaces} from 'react-i18next';
import Search from '../../__common/Search';

const PassengersSearch = ({onChange, value, onSubmit, closeSidebar, t, disabled}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    // <TopSideContainer noFilters>
    //   <TopSideWrapper>
        <Search
          wide
          disabled={disabled}
          inputName={"search"}
          inputValue={value}
          onChange={onChange}
          onSubmit={handleSubmit}
          placeholder={t('passenger.searchWorker')}
        />
    //     <TopSideArrowButtonWrapper>
    //       <BackButton onClick={closeSidebar}/>
    //     </TopSideArrowButtonWrapper>
    //   </TopSideWrapper>
    // </TopSideContainer>
  )
};

export default withNamespaces()(PassengersSearch);
