import React from 'react';
import {LabelWrapper} from './styles';


const BorderedLabel = ({backgroundColor, textColor, text}) => {
  return (
    <LabelWrapper backgroundColor={backgroundColor} textColor={textColor}>
      {text}
    </LabelWrapper>
  )
};

export default BorderedLabel