import * as types from './types';

const initialState = {
  filters: {
    return: null,
    date: new Date(),
  },
  filtersFetched: false,
  companyFilters: {
    team_id: null,
    date: new Date(),
    return: 0,
  },
  filtersLoading: false,
  companyDataRefreshTime: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case types.SET_FILTERS_FETCHED:
      return {
        ...state,
        filtersFetched: action.payload,
      };
    case types.SET_COMPANY_FILTERS:
      return {
        ...state,
        companyFilters: action.payload,
      };
    case types.SET_SAVED_FILTERS_LOADING:
      return {
        ...state,
        filtersLoading: action.payload,
      };
    case types.SET_COMPANY_DATA_REFRESH_TIME:
      return {
        ...state,
        companyDataRefreshTime: action.payload,
      };
    default:
      return state
  };
};
