import styled, {keyframes} from 'styled-components'
import theme from 'config/theme'

const widthSidebar = {
  width: '80px'
};

const scaleAnimation = keyframes`
  0% { transform: translateX(-100%); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  @media (min-width: ${theme.breakpoints.lg}) {
    position: relative;
  }
`;

export const SidebarWrapper = styled.div`
  width: ${widthSidebar.width};
  height: 100vh;
  background-color: ${theme.colors.apple};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transform-origin: left center;
  z-index: 98;
  @media (min-width: ${theme.breakpoints.lg}) {
    min-height: 600px;
    border-top-right-radius: 10px;
    animation-name: ${scaleAnimation};
    animation-duration: 1s;
    animation-iteration-count: 1;
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: row;
    padding: 20px 15px;
    z-index: 100;
    position: fixed;
    bottom: 0;  
  }
  @media (max-width: 601px) {
    display: ${props => props.editModeEnabled ? 'none' : 'flex'};
  }

  img {
  padding-top: 24px;
  
    @media (min-width: 1024px) {
      min-width: 53px;
    }
  
    @media (max-width: 1023px) {
      display: none;
    }
  }
`;

export const SidebarTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 72px;
  @media (max-width: 1023px) {
    flex-direction: row;
    align-items: flex-end;
    padding-top: 0;
  }
`;

export const SidebarTabBottom = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 24px;
  @media (max-width: 1023px) {
    display: none;
  }
`;

export default {
  Wrapper,
  SidebarWrapper,
  SidebarTabsWrapper,
  SidebarTabBottom,
}