import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const ReturnFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const ArrowWrapper = styled.div`
  width: 13px;
  height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${props => props.return ? 'rotate(-90deg)' : 'rotate(90deg)'};
  svg {
    path {
      transition: stroke .2s ease;
      stroke: ${props => props.active ? theme.colors.white : theme.colors.black};
    }
  }
`;

export const ReturnFilterButton = styled.button`
  font-size: ${rem('11px')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  min-width: 80px;
  outline: none;
  border-radius: 5px;
  padding: 9px 10px 9px 5px;
  background-color: ${props => props.active ? theme.colors.apple : theme.colors.veryLightBlue};
  color: ${props => props.active ? theme.colors.white : theme.colors.black};
  margin: 0 5px;
  transition: background-color .2s ease, color .2s ease;
  &:hover {
    background-color: ${theme.colors.apple};
    color: ${theme.colors.white};
    svg {
      path {
        stroke: ${theme.colors.white};
      }
    }
  }
  @media (max-width: 767px) {
    margin: 3px 6px 3px 0;
  }
  opacity: ${props => props.disabled ? 0.6 : 1};
`;

export const FilterText = styled.span`
  margin-left: 6px;
`;
