import React, { useEffect, useRef, useState } from "react";
import {useSelector} from 'react-redux';

const MapMarker = ({
  map,
  H,
  ui,
  behavior,
  id = null,
  onDragEnd = null,
  bubbleContent = null,
  onBubbleClick = null,
  geoLat,
  geoLon,
  icon,
  color = null,
  dragable = false,
  geoDegree = 0,
  isCarMarker = false,
  isCarLabelMarker = false,
  labelText = null,
  editModeEnabled,
  companyCarMarker,
  onMarkerTap = null,
  mainGroup,
  handleGroupChange,
  zIndex = 10,
  isNewRouteMarker = false,
}) => {
  const [marker, setMarker] = useState(null);
  const [group, setGroup] = useState(null);

  const boundToMarkers = useSelector(({routes}) => routes.boundToMarkers);

  const _bubble = useRef(null);
  const _onDragEnd = useRef(null);
  const _onBubbleClick = useRef(null);
  const _onMarkerTap = useRef(null);

  useEffect(() => {
    if (geoLat && geoLon) {
      addMarker(geoLat, geoLon, color, icon, zIndex, boundToMarkers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoLat, geoLon, icon, geoDegree, map]);

  useEffect(() => {
    return () => {
      if (map && group && mainGroup) {
        ui.getBubbles().forEach(bub => ui.removeBubble(bub));
        mainGroup.removeObject(group);
        handleGroupChange(mainGroup, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, group, mainGroup]);

  useEffect(() => {
    if (onDragEnd) {
      _onDragEnd.current = onDragEnd;
    }
  }, [onDragEnd]);

  useEffect(() => {
    if (onBubbleClick) {
      _onBubbleClick.current = onBubbleClick;
    }
  }, [onBubbleClick]);

  useEffect(() => {
    if (onMarkerTap) {
      _onMarkerTap.current = onMarkerTap;
    }
  }, [onMarkerTap]);


  useEffect(() => {
    function onMarkerEnter() {
      document.body.style.cursor = "pointer";
    }

    function onMarkerLeave() {
      document.body.style.cursor = "default";
    }

    if (marker && editModeEnabled) {
      marker.addEventListener('pointerenter', onMarkerEnter);
      marker.addEventListener('pointerleave', onMarkerLeave);
    }

    if (marker) {
      if (onMarkerEnter) {
        marker.addEventListener('pointerenter', onMarkerEnter);
      }

      if (onMarkerLeave) {
        marker.addEventListener('pointerleave', onMarkerLeave);
      }

      if (_onMarkerTap.current) {
        marker.addEventListener('tap', _onMarkerTap.current);
      }
    }

    return () => {
      if (marker) {
        if (onMarkerEnter) marker.removeEventListener('pointerenter', onMarkerEnter);
        if (onMarkerLeave) marker.removeEventListener('pointerleave', onMarkerLeave);
        if (_onMarkerTap.current) marker.removeEventListener('tap', _onMarkerTap.current);
      }
    }
  }, [marker, editModeEnabled]);

  const addMarkerToGroup = (groupParam, markerParam, shouldBound) => {
    // add custom data to the marker
    // markerParam.setData(html);
    setMarker(markerParam);
    groupParam.addObject(markerParam);
    mainGroup.addObject(groupParam);

    if (shouldBound) {
      handleGroupChange(mainGroup, isNewRouteMarker ? false : shouldBound);
    }
  };

  const addMarker = (geoLat, geoLon, clr, ico = null, layerIndex, shouldBound) => {
    if (map) {
      let coloredIcon = ico;
      let marker;
      let bubbleInstance;
      const group = new H.map.Group({zIndex: layerIndex});

      if (bubbleContent) {
        group.addEventListener('tap', function (evt) {
          const content = bubbleContent(id);

          ui.getBubbles().forEach(bub => ui.removeBubble(bub));

          bubbleInstance =  new H.ui.InfoBubble(evt.target.getGeometry(), {
            content,
          });

          ui.addBubble(bubbleInstance);
          _bubble.current = bubbleInstance;

          const bubbleButton = bubbleInstance.getElement().querySelector('.bubble-button');

          if (bubbleButton) {
            bubbleButton.addEventListener('click', function() {
              if (_onBubbleClick && _onBubbleClick.current) {
                _onBubbleClick.current(id);
              }
            });
          }
        });
      }

      setGroup(group);

      if (clr && coloredIcon) {
        coloredIcon = coloredIcon.replaceAll("#fd739e", `${clr}`);
      }
      if (isCarLabelMarker && labelText) {
        coloredIcon = coloredIcon.replace('CAR_LABEL_TEXT', labelText);
      }
      if (ico) {
        const domIconElement = document.createElement("div");
        domIconElement.innerHTML = `${coloredIcon}`;
        const icon = new H.map.DomIcon(domIconElement, {
          onAttach: function (clonedElement, domIcon, domMarker) {
            const clonedContent = clonedElement.getElementsByTagName("svg")[0];
            clonedContent.style.position = 'absolute';
            clonedContent.style.zIndex = layerIndex;
            if (isCarMarker) {
              clonedContent.style.transform = "rotate(" + geoDegree + "deg)";
              clonedContent.style.left = '-20px';
              clonedContent.style.top = '-20px';
            } else if (isCarLabelMarker) {
              clonedContent.style.left = '22px';
              clonedContent.style.top = '-11px';
            } else {
              clonedContent.style.left = '-5px';
              clonedContent.style.top = '-5px';
            }
          },
        });
        marker = new H.map.DomMarker(
          {
            lat: geoLat,
            lng: geoLon,
          },
          { icon: icon }
        );
      } else {
        marker = new H.map.Marker(
          {
            lat: geoLat,
            lng: geoLon,
          },
        );
      }

      if (boundToMarkers) {
        map.setCenter({
          lat: geoLat,
          lng: geoLon,
        });
        map.setZoom(10);
      }

      if (dragable) {
        marker.draggable = dragable;

        map.addEventListener('dragstart', function(ev) {
          var target = ev.target,
              pointer = ev.currentPointer;
          if (target === marker) {
            var targetPosition = map.geoToScreen(marker.getGeometry());
            marker['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
            behavior.disable();
          }
        }, false);

        // re-enable the default draggability of the underlying map
        // when dragging has completed
        map.addEventListener('dragend', function(ev) {
          var target = ev.target,
              pointer = ev.currentPointer;

          if (target === marker) {
            if (_onDragEnd && _onDragEnd.current) {

              const location = map.screenToGeo(pointer.viewportX - marker['offset'].x, pointer.viewportY - marker['offset'].y);
              _onDragEnd.current(location, id);
            }
            behavior.enable();
          }
        }, false);

        // Listen to the drag event and move the position of the marker
        // as necessary
         map.addEventListener('drag', function(ev) {
          var target = ev.target,
              pointer = ev.currentPointer;

          if (target === marker) {
            const location = map.screenToGeo(pointer.viewportX - marker['offset'].x, pointer.viewportY - marker['offset'].y);
            marker.setGeometry(location);
            if (_bubble.current) {
              _bubble.current.setPosition(location);
            } else {
              if (bubbleInstance) bubbleInstance.setPosition(location);
            }
          }
        }, false);
      }

      addMarkerToGroup(group, marker, shouldBound);
    }
  };
  return <></>;
};

export default MapMarker;
