import React, {useState} from 'react';
import {
  ItemWrapper,
  Name,
  GrayText,
  IdWrapper,
  Id,
  EditButtonsWrapper,
  ItemButton,
  ItemLink
} from './styles';
import theme from 'config/theme';
import {routes} from 'config/routes';

import {ReactComponent as EditIcon} from 'assets/icons/pencil.svg';
import {ReactComponent as DeleteIcon} from 'assets/icons/trash.svg';

import Fade from 'components/__common/Fade';
import {Spinner} from 'styled/Spinner';

const CompanyItem = ({ manager, onRemove, isRemoving }) => {

  const [locationList, setLocationList] = useState(null);

  return (
    <ItemWrapper isRemoving={isRemoving}>
      <Name>{manager.name}</Name>
      <GrayText>{manager.company && manager.company.name}</GrayText>
      <IdWrapper>
        {manager.localizations && manager.localizations.length
          ? manager.localizations.slice(0, 2).map((location, index) => (
            <Id key={index}>{location}</Id>
          )) : '-'
        }
        {manager.localizations && manager.localizations.length > 2 && !locationList ? (
          <Id showAll onClick={() => setLocationList(true)}>{`+${manager.localizations.length - 2}`}</Id>
        ) : (
          manager.localizations.slice(2, manager.localizations.length).map((location, index) => (
            <Id key={index}>
              <Fade>{location}</Fade>
            </Id>
          ))
        )}
      </IdWrapper>
      <GrayText>
        {manager.phones.length 
          ? manager.phones.map((phoneNumber, index) => (
            <a key={index} href={`tel:${phoneNumber.tel}`}>{phoneNumber.name}</a>
          )) : '-'
        }
      </GrayText>
      <GrayText>
        <a href={`mailto:${manager.email}`}>{manager.email}</a>
      </GrayText>
      <EditButtonsWrapper>
        <ItemLink to={`${routes.admin.nested.companies.url}/${manager.id}`}>
          <EditIcon/>
        </ItemLink>
        <ItemButton disabled={isRemoving} deleteButton onClick={() => onRemove(manager.id)}>
          {isRemoving ? <Spinner stroke={2} color={theme.colors.error} width={12} height={12} /> : <DeleteIcon />} 
        </ItemButton>
      </EditButtonsWrapper>
    </ItemWrapper>
  )
};

export default CompanyItem;

