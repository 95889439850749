import {createGlobalStyle} from 'styled-components';
import theme from 'config/theme';
import {rem, darken} from 'polished';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: ${theme.font.family};
    margin: 0;
    overflow-x: hidden;
    /* cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><circle cx="8" cy="8" r="8" stroke="none"/><circle cx="8" cy="8" r="7.5" fill="none"/></svg>') 10 10, pointer; */
  }

  .new-route-point-number {
    text-anchor: middle;
  }

  svg text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .H_ib_body {
    box-shadow: 0px 0px 5px 2px rgba(15, 22, 33, 0.1);
    right: auto;
    top: 24px;
    bottom: auto;
  }

  .H_ib_close {
    top: 10px;
  }

  .H_ib_tail {
    display: none;
  }

  .H_ib_content {
    margin: 3px 34px 3px 12px;
  }

  .H_ib_close {
    svg {
      fill: ${theme.colors.darkNavy};
    }
    &:hover {
      svg {
        fill: ${theme.colors.darkNavy};
      }
    }
  }

  .H_l_bottom {
    @media (max-width: 1023px) {
      bottom: 85px;
    }
  }

  .bubble-header {
    font-size: ${rem('12px')};
    color: ${theme.colors.markerGray};
    margin-bottom: 3px;
    font-family: ${theme.font.family};
  }

  .bubble-button {
    margin-bottom: 10px;
    outline: none;
    cursor: pointer;
    border: none;
    background-color: ${theme.colors.apple};
    padding: 7px 14px 7px 30px;
    border-radius: 5px;
    position: relative;
    transition: background-color .2s ease;
    font-size: ${rem('12px')};
    font-weight: bold;
    color: ${theme.colors.white};
    min-width: 75px;
    font-family: ${theme.font.family};
    position: relative;
    text-align: left;
    svg {
      position: absolute;
      top: 6px;
      left: 11px;
      width: 10px;
      height: 15px;
    }

    &:hover {
      background-color: ${darken(0.1, theme.colors.editRed)};
    }

    &-remove {
      background-color: ${theme.colors.editRed};
      color: ${theme.colors.white};
    }
  }

  .ISSToast {
    .Toastify__toast {
      font-family: ${theme.font.family};
      padding: 20px;
      font-size: ${rem('14px')};
      border-radius: 4px;
      top: 60px;
      @media (max-width: 601px) {
        top: 0;
      }
    }
    .Toastify__toast--error {
      background: ${theme.colors.error};
      color: ${theme.colors.white};
    }
    .Toastify__toast--success {
      background: ${theme.colors.apple};
      color: ${theme.colors.white};
    }
  }

`
