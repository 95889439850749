import posed from 'react-pose';

const onEnterAnimation = {
  opacity: 1,
}

const onLeaveAnimation = {
  opacity: 0,
}

export const FadeWrapper = posed.div({
  visible: onEnterAnimation,
  hidden: onLeaveAnimation
});
