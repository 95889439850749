import styled from 'styled-components';

export const MapWrapper = styled.div`
  position: fixed;
  top:0;
  left: 0;
  /* width: ${props => props.isPanelExpanded ? 'calc(100% - 440px)' : '100%'}; */
  /* left: ${props => props.isPanelExpanded ? '440px' : '80px'}; */
  bottom: 0;
  right: 0;
  transition: left .4s ease;
`

export const CalculateButton = styled.button`
  position: absolute;
  right: 30px;
  bottom: 30px;
`