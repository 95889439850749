import React from 'react';
import {ReactComponent as ArrowLeftBack} from "../../../assets/icons/arrowLeft.svg";
import {BackButtonWrapper} from "./styles";

const BackButton = ({onClick}) => {
  return (
    <BackButtonWrapper onClick={onClick}>
      <ArrowLeftBack/>
    </BackButtonWrapper>
  )
};

export default BackButton;




