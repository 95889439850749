import styled from 'styled-components';
import theme from 'config/theme';

export const TopbarWrapper = styled.div`
  height: 60px;
  background-color: ${theme.colors.white};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify ? props.justify : 'space-between'};
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 25px 10px 100px;
  z-index: 97;
  box-shadow: 1px 0 10px 1px rgba(0,0,0,.15);
  @media (max-width: 1023px) {
    padding-left: 15px;
  }
  @media (max-width: 767px) {
    z-index: 100;
    height: auto;
  }
  @media (max-width: 601px) {
    top: ${props => props.editModeEnabled ? '-150px' : 0};
  }
`;