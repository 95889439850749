import styled from "styled-components";
import { CarsListsUsr } from "components/cars/CarsList/styles";
import theme from "../../../config/theme";
import posed from "react-pose";

export const Wrapper = styled.div`
  background-color: ${theme.colors.white};
  overflow-y: auto;
  height: calc(100%);
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.apple} transparent;

  @media (max-width: 1023px) {
    min-height: auto;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.apple};
    border-radius: 10px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 26px 15px 25px;
  justify-content: space-between;
  border-bottom: 1px solid #f4f8fa;
  margin-bottom: 5px;

  svg {
    cursor: pointer;
  }
`;

export const EventsWrapper = styled.div`
  padding: 12px 15px 65px;
`;

export const EventsHeader = styled(CarsListsUsr)`
  color: ${theme.colors.darkNavy};
  font-weight: ${theme.font.bolder};
`;


export const FadingToast = posed.div({
  enter: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 400,
  }
});

export default {
  Wrapper,
  HeaderWrapper,
  EventsWrapper,
  FadingToast
};
