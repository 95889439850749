import React from 'react';
import {ButtonWrapper, ButtonText} from './styles';

const BorderedButton = ({text, icon, link}) => {
  return (
    <ButtonWrapper to={link}>
      <ButtonText>{text}</ButtonText>
      {icon}
    </ButtonWrapper>
  )
};

export default BorderedButton;

