import styled, {css} from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const Wrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-left: -10px;
  margin-right: -10px;
  @media (max-width: ${theme.breakpoints.md}) {
    margin-top: 0;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px;
  flex-wrap: wrap;
`;

export const Item = styled.button`
  outline: none;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border: none;
  margin: 3px;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: ${props => props.isActive ? theme.colors.white : 'transparent'};
  font-size: ${rem('12px')};
  padding: 0;
  font-weight: ${props => props.isActive ? 'bold' : 'normal'};
  color: ${props => props.isActive ? theme.colors.darkNavy : theme.colors.blueyGrey};
  transition: background-color .2s ease, color .2s ease;
  svg {
    path {
      transition: fill .2s ease;
    }
  }

  &:hover {
    ${({disabled}) => !disabled && css`
      background-color: ${theme.colors.darkNavy};
      color: ${theme.colors.white};
      font-weight: bold;
      svg {
        path {
          fill: ${theme.colors.white};
        }
      }
    `};
  }
`;

export const ItemIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${props => props.isFliped ? 'rotate(180deg)': 'none'};
`;
