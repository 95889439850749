import styled from "styled-components";
import theme from 'config/theme';
import {rem} from 'polished';

export const CompanyTable = styled.div`
  @media (max-width: ${theme.breakpoints.lg}) {
      margin-bottom: 20px;
      overflow-x: scroll;
  }
`;

export const Wrapper = styled.div`
  margin: 30px 0 0;
  
    @media (max-width: ${theme.breakpoints.lg}) {
  	 min-width: 1200px;
  }
`;

export const NoCompaniesWrapper = styled.div``;

export const NoCompanies = styled.div`
  display: inline-block;
  font-size: ${rem('13px')};
  color: ${theme.colors.darkNavy};
  font-weight: bold;
  padding: 15px;
  margin-top: 20px;
  background-color: ${theme.colors.white};
  border-radius: 5px;
`;
