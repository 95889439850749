import React from "react";
import { TabsWrapper, TabsWrapperItem, TabsItem } from "./styles";
import { withNamespaces } from 'react-i18next';

const Tabs = ({ onClick, isActive, t }) => {

  const tabsItems = [
    { id: 0, label: t('tabs.info') },
    // { id: 1, label: t('tabs.task') },
  ];

  return (
    <TabsWrapper>
      {tabsItems.map((tab, i) => {
        return (
          <TabsWrapperItem
            onClick={() => onClick(i)}
            isActive={isActive === i}
            key={tab.id}
          >
            <TabsItem>{tab.label}</TabsItem>
          </TabsWrapperItem>
        );
      })}
    </TabsWrapper>
  );
};

export default withNamespaces()(Tabs);
