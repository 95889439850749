import * as types from './types'

const initialState = {
  workers: [],
  workersLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WORKERS:
      return {
        ...state,
        workers: action.payload
      }
    case types.SET_WORKERS_LOADING:
      return {
        ...state,
        workersLoading: action.payload
      }
    default: 
      return state
  }
}