import styled from 'styled-components';
import theme from 'config/theme';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  background-color: ${theme.colors.paleGrey};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 100px 50px 50px;
  position: relative;
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 80px 20px 100px 20px;
  }
`;
