import styled from 'styled-components';
import theme from "../../../config/theme";

export const BackButtonWrapper = styled.div`
  cursor:pointer;
  position: relative;
  
  @media (max-width: 1023px) {
    transform: rotate(-90deg);
  }
  
  .prefix__cls-1  {
    stroke: ${theme.colors.darkNavy};
    transition: all 0.3s ease-in;
  }

   &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 48px;
    min-height: 48px;
  }
  
   &:hover {
   .prefix__cls-1  {
      stroke: ${theme.colors.apple};
    }
  }
`;
