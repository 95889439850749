import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';
import {PrimaryButtonStyles} from 'styled/Buttons';

const Wrapper = styled.div`
  padding: 10px;
  max-width: 800px;
  width: 100%;
`

const Section = styled.div`
  margin-bottom: 40px;
`

const SectionHeader = styled.h3`
  font-size: ${rem('13px')};
  font-weight: bold;  
  margin-bottom: 20px;
  color: ${theme.colors.darkNavy};
`

const FieldsWrapper = styled.div`
  margin: 0 -10px 0 -10px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${theme.breakpoints.sm}) {
    margin: 0;
  }
`

const FieldGroup = styled.div`
  margin: 10px;
  width: calc(50% - 20px);
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    margin: 10px 0;
  }
  span {
    width: 100%;
    max-width: 100%;
    input {
      width: 100%;
      max-width: 100%;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const PrimaryLink = styled.a`
  ${PrimaryButtonStyles};
  display: inline-block;
  max-width: 150px;
  min-width: 150px;
  @media (max-width: ${theme.breakpoints.sm}) {
    margin-top: 10px;
  }
`;

const DownloadButtonWrapper = styled.div`
	display: flex;
	align-items: center;
  margin: 10px;
  width: calc(50% - 20px);
  @media (max-width: ${theme.breakpoints.sm}) {
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    align-items: flex-start;
  }
  span {
    width: 100%;
    max-width: 100%;
    input {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const ButtonLabel = styled.span`
	font-size: ${rem('12px')};
	color: ${theme.colors.darkNavy};
	margin-right: 10px;
`;

const SwitcherLabel = styled.div`
  font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('10px')};
	color: ${theme.colors.cloudyBlue};
	text-align: left;
	line-height: 1;
  margin-bottom: 8px;
`;

export default {
  Wrapper,
  Section,
  SectionHeader,
  FieldsWrapper,
  FieldGroup,
  ButtonsWrapper,
  PrimaryLink,
  DownloadButtonWrapper,
  ButtonLabel,
  SwitcherLabel,
};
