import * as actions from './actions';
import API from "api";
import {
  URL_GET_ROUTE,
  URL_GET_ROUTE_DETAILS,
  URL_GET_ROUTE_FROM_HERE_API,
  URL_SAVE_NEW_ROUTE,
  URL_GET_COMPANIES_DESTINATIONS,
} from 'api/config';
import axios from 'axios';
import {returnNearestPolylinePoints, parseStringShapeToObject} from 'utils/routes';
import {setModalCallback} from 'store/modal/actions';
import {setCarsColors} from "../cars/actions";
import {formatDate} from 'utils/global';
import {setBoundToMarkers} from "./actions";
import {generateRandomColor} from "../../utils/cars";
import {decode} from 'utils/here';

export const getRoute = (routeId, routes, carName, carId, shouldSetAsCurrent = false) => {
  return async (dispatch, getState) => {
    let carsColors = getState().cars.carsColors;

    dispatch(actions.setBoundToMarkers(true));
    dispatch(actions.setLoadingRoute(routeId));
    dispatch(actions.setLRouteLoading(true));

    const res = await API.get(URL_GET_ROUTE, {
      params: {
        route_id: routeId,
      },
    });

    dispatch(actions.setLoadingRoute(null));
    dispatch(actions.setLRouteLoading(false));
    if (res.success) {
      if (res.data) {
        let route = [];

        res.data.blob.sections.forEach(section => {
          const decoded = decode(section.polyline);
          route = [...route, ...decoded.polyline];
        });
        
        const newRoute = {
          id: routeId,
          car: carName,
          car_id: carId,
          route,
          tasks: res.data.tasks,
          points: res.data.points,
        };

        carsColors = {
          ...carsColors,
          [carId]: carsColors[carId] ? carsColors[carId] : generateRandomColor(),
        }

        if (shouldSetAsCurrent && route.length) {
          const nearestPoints = returnNearestPolylinePoints(res.data.points, route);
          dispatch(actions.setPointsOnPolyline(nearestPoints));
          dispatch(actions.setTemporaryPointsOnPolyline(nearestPoints));
          dispatch(actions.setCurrentRoute(newRoute));
        }
        dispatch(setCarsColors(carsColors));
        dispatch(
          actions.setRoutes([
            ...routes,
            newRoute,
          ])
        );
      }
    }
  };
};

export const getRouteDetails = (routeId, routes) => {
  return async (dispatch) => {
    dispatch(actions.setLRouteLoading(true));
    const res = await API.get(URL_GET_ROUTE_DETAILS, {
      params: {
        route_id: routeId,
      },
    });
    dispatch(actions.setLRouteLoading(false));
    if (res.success) {
      const selectedRoute = routes.find((route) => route.id === routeId);
      if (selectedRoute && selectedRoute.route.length) {
        const nearestPoints = returnNearestPolylinePoints(selectedRoute.points, selectedRoute.route);
        dispatch(actions.setPointsOnPolyline(nearestPoints));
        dispatch(actions.setTemporaryPointsOnPolyline(nearestPoints));
        dispatch(actions.setCurrentRoute(selectedRoute));
      }
      dispatch(actions.setRoutesDetails(res.data));
      dispatch(actions.setTripDetails(routes));
    }
  };
};

export const showEditAlertAndHide = () => {
  return dispatch => {
    dispatch(actions.setEditAlertVisible(true));
    setTimeout(() => dispatch(actions.setEditAlertVisible(false)), 7000);
  };
};

export const getNewRouteFromHere = (points, color) => {
  return async dispatch => {
    let via = [];
    const first = points[0],
          last = points[points.length - 1];
          
    if (points.length >= 3) {
      const middle = points.slice(1, points.length - 1);
      via = middle.map(el => `${el.latitude},${el.longitude}`);
    }

    try {
      let params = new URLSearchParams();
      params.append('origin', `${first.latitude},${first.longitude}`);
      params.append('destination', `${last.latitude},${last.longitude}`);
      params.append('routingMode', 'fast');
      params.append('transportMode', 'car');
      params.append('return', 'polyline,summary');
      params.append('apikey', 'AMyLKc4pe2VvrUN0pBM1lqDbAMtlz095C71uP8Xz2Rg');
      via.forEach(v => params.append('via', v));

      const res = await axios.get(URL_GET_ROUTE_FROM_HERE_API, { params });

      if (res && res.data) {
        let route = [];

        res.data.routes[0].sections.forEach(section => {
          const decoded = decode(section.polyline);
          route = [...route, ...decoded.polyline];
        });

        const newRoute = {
          route: route,
          color: color,
        };

        dispatch(actions.setFetchedNewRoute(newRoute));
      }
    } catch (err) {
      console.log(err)
    }
  };
}

export const saveNewFetchedRoute = (route, points) => async (dispatch, getState) => {
  const routes = getState().routes.routes;

  dispatch(actions.setFetchedRouteSaving(true));

  const res = await API.post(URL_SAVE_NEW_ROUTE, {
    route_id: route.id,
    points: points.map(point => {
      return {
        latitude: point.latitude,
        longitude: point.longitude,
      };
    })
  });

  if (res.success) {
    const res2 = await API.get(URL_GET_ROUTE, {
      params: {
        route_id: route.id,
      },
    });

    if (res2.success) {
      dispatch(setModalCallback(null));
      dispatch(actions.setSectionPoints([]));
      dispatch(actions.setNewRoutePoints([]));
      dispatch(actions.setEditModeEnabled(false));
      dispatch(actions.setTemporaryPointsOnPolyline([]));
      dispatch(actions.setFetchedNewRoute(null));

      const shape = parseStringShapeToObject(res2.data.shape);
      const newRoute = {
        id: route.id,
        car: route.car,
        car_id: route.car_id,
        route: shape,
        tasks: res2.data.tasks,
        points: res2.data.points,
        color: route.color,
      }

      const newRoutes = routes.map(r => r.id === route.id ? newRoute : r);
      dispatch(actions.setFetchedRouteSaving(false));
      dispatch(actions.setRoutes(newRoutes));
    }
  } else {
    dispatch(actions.setFetchedRouteSaving(false));
  }
};

export const getCompaniesDestinations = (passengersType = 0) => async (dispatch, getState) => {
  try {
    dispatch(setBoundToMarkers(true));
    dispatch(actions.setCompaniesDestinationsLoading(true));
    const companyFilters = getState().filters.companyFilters;
  
    let queryFilters = {
      date: formatDate(companyFilters.date),
      return: companyFilters.return,
      cancelled: passengersType,
    };
  
    const res = await API.get(URL_GET_COMPANIES_DESTINATIONS, {
      params: {...queryFilters},
    });

    dispatch(actions.setCompaniesDestinationsLoading(false));
  
    if (res.success) {
      dispatch(actions.setCompaniesDestinations(res.data));
      dispatch(setBoundToMarkers(true));
    }
  } catch (err) {
    dispatch(actions.setCompaniesDestinationsLoading(false));
  }
};
