import React from 'react';
import {TopbarWrapper} from './styles';

const Topbar = ({ children, editModeEnabled = false }) => {

  return (
    <TopbarWrapper justify={children.length > 1 ? 'space-between' : 'flex-end'} editModeEnabled={editModeEnabled}>
      {
        React.Children.map(children, child =>
          React.cloneElement(child)
        )
      }
    </TopbarWrapper>
  );
};

export default Topbar;
