import styled from 'styled-components';
import theme from 'config/theme';
import { rem } from 'polished';

export const TimerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const TimerTextContent = styled.span`
  font-size: ${rem('12px')};
  font-weight: ${theme.font.regular};
  color: ${theme.colors.blueyGrey};
  margin-left: 8px;
`;

export const GlobalIconWrapper = styled.div`
  width: 14px;
  text-align: center;
`;