// import {findNearest} from 'geolib';
// import shortid from 'shortid';

export const returnWaypoints = points => {
  let queryWaypoints = [];
  if (points) {
    points.forEach((point, i) => {
      queryWaypoints = {
        ...queryWaypoints,
        [`waypoint${i}`]: point
      }
    });
    return queryWaypoints;
  } else {
    return null;
  }
}

export const parseStringShapeToObject = shape => {
  return shape.map(point => {
    const splited = point.split(',');
    return {
      latitude: parseFloat(splited[0]),
      longitude: parseFloat(splited[1]),
    }
  });
};

export const parseStringPointToObject = point => {
  const splited = point.split(',');

  return {
    latitude: parseFloat(splited[0]),
    longitude: parseFloat(splited[1]),
  }
};

export const findNearest = (point, shape) => {
  let pointWithMinDistance = shape[0];
  let minDistance = Math.pow(point.latitude - shape[0].latitude, 2) +  Math.pow(point.longitude - shape[0].longitude, 2);
  
  shape.forEach(shapePoint => {
    const distance = Math.pow(point.latitude - shapePoint.latitude, 2) +  Math.pow(point.longitude - shapePoint.longitude, 2);
    if (distance <= minDistance) {
      minDistance = distance;
      pointWithMinDistance = shapePoint;
    }
  });

  return pointWithMinDistance;
}

export const returnNearestPolylinePoints = (points, shape) => {
  return points.map(point => {
    const pointObject = parseStringPointToObject(point);
    return findNearest(pointObject, shape);
  });
};
