import React, {forwardRef} from 'react'
import { PickerWrapper, DateFilterSelect, DataLoadingWrapper } from './styles'
import DatePicker, { registerLocale } from 'react-datepicker'
import { ReactComponent as DateIcon } from 'assets/icons/calendar.svg'
import pl from 'date-fns/locale/pl';
import ro from 'date-fns/locale/ro';
import nl from 'date-fns/locale/nl';
import uk from 'date-fns/locale/uk';
import de from 'date-fns/locale/de';
import 'react-datepicker/dist/react-datepicker.css'
import I18n from 'i18n';
import { Spinner } from 'styled/Spinner';

registerLocale('pl', pl);
registerLocale('pl-PL', pl);
registerLocale('ro', ro);
registerLocale('nl', nl);
registerLocale('ua', uk);
registerLocale('de', de);

const SelectDate = ({
		loading,
		placeholder = null,
		maxDate = null,
		minDate = null,
		showMonthYearPicker = false,
		dateFormat = 'yyyy-MM-dd',
		isClearable = false,
		onChange,
		valueDate,
		name
	}) => {

	const isLoading = loading !== undefined && loading === true;
	return (
		<>
			{isLoading ? (
				<DataLoadingWrapper>
					<Spinner height={13} width={13} stroke={2} />
				</DataLoadingWrapper>
			) : (
				<PickerWrapper>
					<DatePicker
						maxDate={maxDate}
						minDate={minDate}
						placeholderText={placeholder}
						dateFormat={dateFormat}
						locale={I18n.language}
						selected={valueDate}
						onChange={(e) => onChange(name, e)}
						showMonthYearPicker={showMonthYearPicker}
						customInput={<CustomDateFilter isClearable={isClearable} />}
					/>
				</PickerWrapper>
			)}
		</>
	)
}

export default SelectDate;

const CustomDateFilter = forwardRef((props, ref) => {
	return (
		<>
			<DateFilterSelect
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onClick}
				onClick={props.onClick}
				isClearable={props.isClearable}/>
			<DateIcon />
		</>
	)
});
