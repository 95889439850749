import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';
import {Link} from 'react-router-dom';

export const Label = styled.div`
  font-size: ${rem('12px')};
  color: ${theme.colors.white};
  font-weight: bold;
  opacity: ${props => props.isActiveTab ? 1 : 0.8};
  transition: opacity .2s ease;
  @media (max-width: ${theme.breakpoints.md}) {
    font-size: ${rem('11px')};
  }
`;

export const IconWrapper = styled.div`
  margin-right: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${props => props.isActiveTab ? theme.colors.white : theme.colors.navy};
  padding: 7px;
  transition: background-color .2s ease;
  svg {
    width: 100%;
    height: auto;
    path {
      transition: fill .2s ease;
      fill: ${props => props.isActiveTab ? theme.colors.navy : theme.colors.white};
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    margin-right: 0;
    margin-bottom: 7px;
  }
`;

export const ItemLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  &:hover {
    ${Label} {
      opacity: 1;
    }
    ${IconWrapper} {
      background-color: ${theme.colors.white};
      svg {
        path {
          fill: ${theme.colors.navy};
        }
      }
    }
  }
  @media (max-width: ${theme.breakpoints.md}) {
    flex-direction: column;
    justify-content: center;
  }
`;
