export const validateForm = form => {
  let errors = null;

  Object.keys(form).forEach(field => {
    if (form[field].required && form[field].value.length === 0) {
      errors = {
        ...errors, 
        [field]: 'To pole nie może być puste!'
      }
    }  
  });

  return {
    success: errors ? false : true,
    errors: errors,
  };
};

export const prepareApiErrors = form => {
  let errors;

  form.forEach(f => {
    errors = {
      ...errors,
      [f.name]: f.error,
    }
  });

  return errors;
};
