import styled from 'styled-components'
import theme from 'config/theme'

export const SelectWrapper = styled.div`
	position: relative;
	
	@media(max-width: 1023px) {
    margin-left: 0;
    margin-top: 0;
    width: calc(50% - 4px);
  }

	.select-filter__control {
		padding: 0;
		border-radius: 4px;
		border: none;
		min-height: 26px;
		width: 93px;
		box-shadow: none;
		cursor: pointer;
		
		@media(max-width: 1366px) {
			width: 85px;
		}
		
		@media(max-width: 1023px) {
			width: 100%;
		}
		
		.select-filter__value-container {
			flex-wrap: nowrap;
			border: 1px solid ${theme.colors.veryLightBlue};
			border-radius: 4px;
			padding: 1px 8px;
			min-height: 26px;
			transition: border-color .3s ease;
		}
		
		&:hover, &:focus {
		
			.select-filter__value-container {
				border-color: ${theme.colors.apple};
			}
		}
		
		.select-filter__value-container--has-value {
			background-color: rgba(102, 203, 53, 0.1);
			border-color: rgba(102, 203, 53, 0.1);
			
			.select-filter__single-value {
				color: ${theme.colors.apple};
			}
		}
	}
	
	svg {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		margin-right: 8px;
	}
`