export default {
	login: {
		"logoAlt": "GTV Bus",
		"header": "Log in to the dispatcher's panel",
		"companyHeader": "Log in to the company's panel",
		"loginForm": "Login",
		"loginFormPassword": "Password",
		"forgotPassword": "If you forgot your password, contact the administrator",
		"loginFormButton": "Log in",
		"logging": "Login...",
		"verify": "Verification...",
		"helpText": "Do you need help? Call us",
		"loading": "Loading..."
	},
	"languages": {
		"language": "Language",
		"pl": "Polski",
		"en": "English",
    "ro": "Română",
		"nl": "Nederlands",
		"ua": 'Український',
		"de": "Deutsche",
		"change": "Change"
	},
	"database": {
		"name": "Database",
		"pl": " Polish",
		"nl": " Dutch",
		"ro": " Romanian",
		"pl2": ' Autko 2.0',
		"undefined": ' nicht definiert'
	},
	"route": {
		"editRoute": "Edit the route",
		"cancelEdit": "Cancel editing",
		"saveChanges": "Save changes",
		"savingChanges": "Saving...",
		"editAlert1": "Select the route section you want to edit",
		"editAlert2": "by selecting two points",
		"editAlert3": "on the route, and then ",
		"editAlert4": "determine the detour point (s) ",
		"editAlert5": "for this section of the route.",
		"taskCancelled": 'Cancelled',
		"removePoint": 'Remove point',
		"additionalPoint": 'Detour point',
	},
	"confirmModal": {
		"confirmButton": "Yes, I'm leaving",
		"cancelButton": "No, I want to finish editing",
		"mainText": "Changes to the route have not been saved and will be lost.",
		"boldText": "Are you sure you want to leave?"
	},
	"filters": {
		"all": "All",
		"departure": "Departure",
		"back": "Return",
		"labels": {
			"departureDate": "Departure date",
			"date": "Date",
			"tripType": "Type of route",
			"team": "Branch ID"
		},
		"allPassengers": 'All',
		"presentPassengers": 'Present',
		"refreshData": "Refresh data"
	},
	"tabs": {
		"info": "Information",
		"task": "Task"
	},
	"sidebar": {
		"vehicles": "Vehicles",
		"drivers": "Drivers",
		"passengers": "Passengers",
		"workers": "Employees",
		"events": "Events",
		"chat": "Chat"
	},
	"cars": {
		"searchCar": "Search vehicle",
		"noCarsFound": "No vehicles were found for the selected criteria!",
		"carTypePlaceholder": "Type",
		"carTypes": {
			"all": "All",
			"target": "Target",
			"delivers": "Transport",
			"shippers": "Delivery",
			"other": "Other",
		},
		"drivers": "Drivers",
		"trip": "Route",
		"tripTypes": {
			"target": "Destination",
			"delivers": "Transporting",
			"shippers": "Bringing",
			"other": "Other"
		},
		"vehicleTrip": "Vehicle route",
		"tripNotPlanned": "The route has not yet been planned",
		"write": "Write",
		"backToCarsList": "Back to the car list",
		"noGpsSignal": 'No GPS signal',
		"routeLines": 'Route lines',
    "personIn": 'Pickup',
    "personOut": 'Egress'
	},
	"events": {
		"eventsHeading": "List of events",
		"noEventsMessage": "No events have been reported",
		"call": "Call",
		"message": "Write"
	},
	"passenger": {
		"searchWorker": "Search for an employee",
		"car": "Vehicle",
		"fromTo": "Destination",
		"notFound": "No employees were found for the given criteria!",
		"passengerMissing": 'Absent',
		"passengerCancelled": 'Resignation',
		"start": 'Start',
		"vehicleList": 'Vehicles',
		"sharePassengersLocations": 'Share passengers locations',
    "sharePassengerLocation": 'Share passenger location',
    "copyLinkToClipboard": 'Copy link to clipboard',
    "copyLinksToClipboard": 'Copy links to clipboard',
    "sendInMail": 'Send the link by email',
    "clickToFollowLocation": 'Click on the link to be able to track the position of the passenger',
	},
	"companyCars": {
    "companyCarsHeading": "List of vehicles",
		"worker": "employee",
    "workers": "employees",
    "notFound": 'No vehicles were found for the given criteria!',
	},
	"api": {
    "unexpectedError": 'An unexpected error has occurred!',
  },
};
