export const SET_CARS = 'SET_CARS';
export const SET_CARS_LOADING = 'SET_CARS_LOADING';
export const SET_CARS_POSITIONS = 'SET_CARS_POSITIONS';
export const SET_COMPANY_CARS = 'SET_COMPANY_CARS';
export const SET_COMPANY_CARS_LOADING = 'SET_COMPANY_CARS_LOADING';
export const SET_SELECTED_COMPANY_CARS = 'SET_SELECTED_COMPANY_CARS';
export const SET_COMAPNY_CARS_POSITIONS = 'SET_COMAPNY_CARS_POSITIONS';
export const SET_COMAPNY_CARS_POSITIONS_LOADING = 'SET_COMAPNY_CARS_POSITIONS_LOADING'
export const SET_CARS_FILTERS = 'SET_CARS_FILTERS';
export const SET_CARS_COLORS = 'SET_CARS_COLORS';
