import styled, {keyframes} from 'styled-components';
import {rem, darken} from 'polished';
import theme from 'config/theme';
import posed from 'react-pose';

const contentConfig = posed.div({
  open: {
    opacity: 1,
    height: 'auto',
    transition: {duration: 200},
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {duration: 200},
  }
});

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const CarItemPin = styled.div`
    background: ${({routeColor, bgColor}) => routeColor ? routeColor : bgColor ? darken(0.1, theme.colors.paleGrey) : theme.colors.paleGrey};
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    
    &:hover {
      background-color: ${({routeColor, bgColor}) => routeColor ? routeColor : bgColor ? darken(0.2, theme.colors.paleGrey) : darken(0.1, theme.colors.paleGrey)};
    }
`;

export const CarItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 15px 20px;
  overflow: hidden;
  background-color: ${({bgColor}) => bgColor ? theme.colors.paleGrey : 'transparent'};
  transition: background-color 0.3s ease-in;
   
  &:hover {
    background-color: ${theme.colors.paleGrey};
  }
`;

export const CarItem = styled.div`
  cursor: pointer;
`;

export const CarItemNameWrapper = styled.div`
  display: flex;
  justify-content: ${({carLabel}) => carLabel ? 'flex-start' : 'space-between'};
  align-items: center;
`;

export const CarItemName = styled.span`
  font-size: ${rem('12px')};
  font-weight: ${theme.font.bolder};
  color: ${theme.colors.darkNavy};
  margin-right: ${({carLabel}) => carLabel ? '7px' : 0};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NameHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CarItemDescription = styled.span`
  font-size: ${rem('12px')};
  color: ${theme.colors.blueyGrey};
  margin-right: 10px;
  margin-left: 8px;
`;

export const CarArrowWrapper = styled.div`
  position: relative;
  top: ${({rotateItem}) => rotateItem ? '2px' : '-4px'};
  transform: ${({rotateItem}) => rotateItem ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const CarPassengerPhone = styled.a`
  font-size: ${rem('12px')};
  font-weight: ${theme.font.bolder};
  color: ${theme.colors.blueyGrey};
  margin-left: 8px;
  text-decoration: none;
  transition: .3s color;
  
  &:hover {
    color: ${theme.colors.apple};
  }
`;

export const CarPassengersContent = styled(contentConfig)``;

export const CarPassengerWrapper = styled.div`
  border-radius: 4px;
  padding: 15px;
  background: ${({bgColor}) => bgColor ? bgColor : theme.colors.white};
  margin-bottom: 5px;
  
  &:first-of-type {
    margin-top: 15px;
  }
`;

export const CarItemList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const CarSpinner = styled.div`
  animation: ${spin} 1s linear infinite;
  border: 3px solid #ddd;
  border-top: 3px solid ${theme.colors.apple};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 10px;
`;

export const LocationIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2px;
  svg {
    margin-right: 5px;
    margin-top: 1px;
    max-height: 12px;
  }
`;
