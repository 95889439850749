import React, {Fragment} from 'react';
import { useFormik } from 'formik'
import SettingsFormStyles from './styles';
import {Form, InputBar, InputLabel, Input, FieldError, Label} from 'styled/Form';
import {PrimaryButton, PrimaryButtonIcon, PrimaryButtonText} from 'styled/Buttons';
import {ButtonLoader} from 'styled/ButtonLoader';
import {ReactComponent as CheckIcon} from 'assets/icons/check.svg';
import Switcher from 'components/__common/Switcher';

const SettingsForm = ({ 
  form,
  onSubmit, 
  initialFormValues, 
  isFormSending,
  errors,
  resetErrors
 }) => {

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit
  });

  const handleFormChange = () => {
    if (errors) {
      resetErrors();
    }
  };

  const renderProperField = field => {
    switch (field.type) {
      case 'button':
        return renderButtonField(field);
      case 'boolean':
        return renderSwitcherField(field);
      default:
        return renderTextField(field);
    };
  };

  const renderButtonField = field => {
    return (
      <SettingsFormStyles.DownloadButtonWrapper>
        <SettingsFormStyles.ButtonLabel>{field.label}</SettingsFormStyles.ButtonLabel>  
        <SettingsFormStyles.PrimaryLink href={field.value} target="_blank">
          <PrimaryButtonText>Pobierz plik</PrimaryButtonText>
          <PrimaryButtonIcon>
            <CheckIcon />
          </PrimaryButtonIcon>
        </SettingsFormStyles.PrimaryLink>
      </SettingsFormStyles.DownloadButtonWrapper>
    );
  };

  const renderSwitcherField = field => {
    return (
      <SettingsFormStyles.FieldGroup>
        <SettingsFormStyles.SwitcherLabel>{field.label}</SettingsFormStyles.SwitcherLabel>
        <Switcher
          checked={formik.values[field.name]}
          name={field.name}
          onSwitcherChange={() => formik.setFieldValue(field.name, !formik.values[field.name])}
        />
      </SettingsFormStyles.FieldGroup>
    );
  };

  const renderTextField = field => {
    return (
      <SettingsFormStyles.FieldGroup>
        <InputBar>
          <Input error={errors && errors[field.name] ? true : false} type='text' name={field.name} value={formik.values[field.name]} onChange={formik.handleChange} />
          <InputLabel />
          <Label htmlFor={field.name}>{field.label}</Label>
          {errors && errors[field.name] && <FieldError>{errors[field.name]}</FieldError>}
        </InputBar>
      </SettingsFormStyles.FieldGroup>
    );
  };

  return (
    <SettingsFormStyles.Wrapper>
      {formik.values &&
        (
          <Form onSubmit={formik.handleSubmit} onChange={handleFormChange}>
            {form && form.map(section => {
              return (
                <SettingsFormStyles.Section key={section.label}>
                  <SettingsFormStyles.SectionHeader>{section.label}</SettingsFormStyles.SectionHeader>
                  <SettingsFormStyles.FieldsWrapper>
                    {section.fields && section.fields.map(field => {
                      return (
                        <Fragment key={field.name}>
                          {renderProperField(field)}
                        </Fragment>
                      )
                    })}
                  </SettingsFormStyles.FieldsWrapper>
                </SettingsFormStyles.Section>
              )
            })}
            {form && form.length &&
              <SettingsFormStyles.ButtonsWrapper>
                <PrimaryButton type="submit" className={isFormSending ? 'loading' : null} disabled={isFormSending}>
                  <PrimaryButtonText>{isFormSending ? 'Zapisuję...' : 'Zapisz'}</PrimaryButtonText>
                  <PrimaryButtonIcon>
                    {isFormSending ? <ButtonLoader /> : <CheckIcon />}
                  </PrimaryButtonIcon>
                </PrimaryButton>
              </SettingsFormStyles.ButtonsWrapper>
            }
          </Form>
        )
      }
    </SettingsFormStyles.Wrapper>
  );
}

export default SettingsForm;