import * as types from './types'

const initialState = {
  loggedUser: null,
  database: null,
  settingsLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOGGED_USER:
      return {
        ...state,
        loggedUser: action.payload
      };
    case types.SET_DATABASE_LOADING:
    return {
      ...state,
      loggedUser: action.payload
    };
    case types.SET_DATABASE:
      return {
        ...state,
        database: action.payload
      };
    default: 
      return state
  }
};
