import * as types from './types'

export const setCorps = corps => {
  return {
    type: types.SET_CORPS,
    payload: corps,
  };
};

export const setCorpsLoading = areLoading => {
  return {
    type: types.SET_CORPS_LOADING,
    payload: areLoading,
  };
};
export const setCompanies = cars => {
  return {
    type: types.SET_COMPANIES,
    payload: cars
  };
};

export const setCompaniesLoading = isLoading => {
  return {
    type: types.SET_COMPANIES_LOADING,
    payload: isLoading
  };
};

export const setCompanyRemovingId = value => {
  return {
    type: types.SET_COMPANY_REMOVING_ID,
    payload: value
  };
};

export const setCompanyFormSubmitting = isSubmitting => {
  return {
    type: types.SET_COMPANY_FORM_SUBMITTING,
    payload: isSubmitting
  };
};

export const setCompanyFormErrors = errors => {
  return {
    type: types.SET_COMPANY_FORM_ERRORS,
    payload: errors
  };
};

export const setCurrentCompany = company => {
  return {
    type: types.SET_CURRENT_COMPANY,
    payload: company
  };
};

export const setCompanyTeamsLoading = areLoading => {
  return {
    type: types.SET_COMPANY_TEAMS_LOADING,
    payload: areLoading
  };
};

export const setCompanyTeams = teams => {
  return {
    type: types.SET_COMPANY_TEAMS,
    payload: teams
  };
};
