import styled, {keyframes} from 'styled-components';
import theme from 'config/theme';

const spin = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
	transform: rotate(360deg);
	}
`

export const ButtonLoader = styled.div`
	margin: 0 auto;
	width: ${props => props.radius ? `${props.radius}px` : '17px'};
	height: ${props => props.radius ? `${props.radius}px` : '17px'};
	border-radius: 50%;
	border: ${props => props.stroke ? `${props.stroke}px solid ${theme.colors.white}` : `4px solid ${theme.colors.white}`};
	border-top-color: transparent;
	animation: ${spin} .9s ease infinite;
`