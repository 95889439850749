import React from 'react';
import {ReturnFilterWrapper, ReturnFilterButton, ArrowWrapper, FilterText} from './styles';
import {ReactComponent as ArrorIcon} from 'assets/icons/arrowLeft.svg';
import { withNamespaces } from 'react-i18next';
import { Spinner } from 'styled/Spinner';

const ReturnFilter = ({ value, handleFilterChange, allOptionEnabled = true, t, loading }) => {

  const isLoading = loading !== undefined && loading === true;
  return (
    <ReturnFilterWrapper>
      {allOptionEnabled &&
        <ReturnFilterButton active={value === null} onClick={() => handleFilterChange('return', null)}>
          <FilterText>{t('filters.all')}</FilterText>
        </ReturnFilterButton>
      }
      <ReturnFilterButton disabled={isLoading} active={value === 0} onClick={() => handleFilterChange('return', 0)}>
        {isLoading ? (
          <Spinner stroke={2} height={13} width={13} />
        ) : (
          <>
            <ArrowWrapper active={value === 0}>
              <ArrorIcon />
            </ArrowWrapper>
            <FilterText>{t('filters.departure')}</FilterText>
          </>  
        )}
      </ReturnFilterButton>
      <ReturnFilterButton disabled={isLoading} active={value === 1} onClick={() => handleFilterChange('return', 1)}>
        {isLoading ? (
          <Spinner stroke={2} height={13} width={13} />
        ) : (
          <>
            <ArrowWrapper active={value === 1} return={1}>
              <ArrorIcon />
            </ArrowWrapper>
            <FilterText>{t('filters.back')}</FilterText>
          </>
        )}
      </ReturnFilterButton>
    </ReturnFilterWrapper>
  );
};

export default withNamespaces()(ReturnFilter);
