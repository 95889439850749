import React from 'react';
import {routes} from 'config/routes';
import {Route, Switch, Redirect} from 'react-router-dom';

import {Wrapper, ContentWrapper} from './styles';

import AdminSidebar from 'components/admin/AdminSidebar';
import Settings from 'components/settings/Settings';
import Companies from 'components/companies/Companies';
import AddCompany from 'components/companies/AddCompany';
import Topbar from 'components/topbar/Topbar';
import LoggedUser from 'components/topbar/LoggedUser';
import EditCompany from 'components/companies/EditCompany';

const Admin = () => {
  return (
    <Wrapper>
      <Topbar>
        <LoggedUser isAdmin={true} />
      </Topbar>
      <AdminSidebar />
      <ContentWrapper>
        <Switch>
          <Route
            exact
            path={routes.admin.nested.companies.url}
            component={Companies} />
          <Route
            exact
            path={routes.admin.nested.companies.nested.new.url}
            component={AddCompany} />
          <Route
            exact
            path={routes.admin.nested.companies.nested.edit.url}
            component={EditCompany} />
          <Route
            exact 
            path={routes.admin.nested.settings.url}
            component={Settings} />
          <Redirect from="/*" to="/admin/settings" />
        </Switch>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Admin;
