import React, { useState, Fragment } from 'react';
import {
  PassengerItemWrapper,
  PassengerItem,
  PassengerItemNameWrapper,
  PassengerItemName,
  PassengerItemListWrapper,
  PassengerItemList,
  PassengerItemContent,
  PassengerContent,
  PassengerArrowWrapper,
  PasssengerItemPhone,
  PassengerIconWrapper,
} from './styles';
import SharePosition from '../SharePosition';
import CarLabel from '../../__common/CarLabel'
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowIcon.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/timer.svg';
import DriverIcon from 'assets/react-icons/driverIcon';
import { withNamespaces } from 'react-i18next';
import shortid from "shortid";
import theme from 'config/theme';
import BorderedLabel from "../../__common/BorderedLabel";
import Timer from "../../__common/Timer";

const PassengersItem = ({ passenger, t }) => {

  const { name, phones, address, cars, address_to, cancelled, absent, from_time, estimated, urls } = passenger;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <PassengerItemWrapper
      bgColor={
        absent
          ? theme.colors.lightEditRed
          : cancelled
            ? theme.colors.lightOrange
            : isOpen
              ? theme.colors.paleGrey
              : 'transparent'
        }
      borderColor={
        absent
          ? theme.colors.lightEditRed
          : cancelled
            ? theme.colors.lightOrange
            : isOpen
              ? '#f1f2f7'
              : 'transparent'
        }>
      <PassengerItem onClick={() => setIsOpen(!isOpen)}>
        <PassengerItemNameWrapper>
          <PassengerItemNameWrapper carLabel>
          <PassengerItemName>{name}</PassengerItemName>
            {cancelled ? (
              <BorderedLabel backgroundColor={theme.colors.labelOrange} text={t('passenger.passengerCancelled')}/>
            ) : null}
            {absent ? (
              <BorderedLabel backgroundColor={theme.colors.editRed} text={t('passenger.passengerMissing')}/>
            ) : null}
          </PassengerItemNameWrapper>
          <PassengerArrowWrapper rotateItem={isOpen}>
            <ArrowIcon />
          </PassengerArrowWrapper>
        </PassengerItemNameWrapper>
        <PassengerItemListWrapper>
          {phones && phones.map(({tel, name}, i) => (
            <PassengerItemList key={i}>
              <PassengerIconWrapper>
                <PhoneIcon />
              </PassengerIconWrapper>
              <PasssengerItemPhone href={`tel:${tel}`}>{name}</PasssengerItemPhone>
            </PassengerItemList>
          ))}
          <PassengerItemList>
            <PassengerIconWrapper>
              <LocationIcon />
            </PassengerIconWrapper>
            <PassengerItemContent>{address}</PassengerItemContent>
          </PassengerItemList>
          {from_time && (
            <PassengerItemList>
              <Timer
                icon={<TimerIcon />}
                text={from_time}
              />
            </PassengerItemList>
          )}
        </PassengerItemListWrapper>
      </PassengerItem>
      <PassengerContent initialPose="closed" pose={isOpen ? 'open' : 'closed'}>
        <PassengerItemNameWrapper>
          <PassengerItemName>{t('passenger.vehicleList')}:</PassengerItemName>
        </PassengerItemNameWrapper>
        {cars && cars.length ? cars.map(({number, drivers}) => (
          <Fragment key={shortid.generate()}>
            <PassengerItemNameWrapper carLabel>
              <CarLabel label={number} />
            </PassengerItemNameWrapper>
            <PassengerItemListWrapper>
              {drivers && drivers.map(driver => {
                return (
                  <Fragment key={shortid.generate()}>
                    <PassengerItemList>
                      <PassengerIconWrapper>
                        <DriverIcon />
                      </PassengerIconWrapper>
                      <PassengerItemContent>{driver.name}</PassengerItemContent>
                    </PassengerItemList>
                    {driver.phones && driver.phones.map(phone => {
                      return (
                        <PassengerItemList key={shortid.generate()}>
                          <PassengerIconWrapper>
                            <PhoneIcon/>
                          </PassengerIconWrapper>
                          <PasssengerItemPhone href={`tel:${phone.tel}`}>{phone.name}</PasssengerItemPhone>
                        </PassengerItemList>
                      )
                    })}
                  </Fragment>
                )
              })}
            </PassengerItemListWrapper>
          </Fragment>
        )) : '-'}
        <PassengerItemNameWrapper>
          <PassengerItemName>{t('passenger.fromTo')}:</PassengerItemName>
        </PassengerItemNameWrapper>
        <PassengerItemListWrapper>
          <PassengerItemList>
            <PassengerIconWrapper>
              <LocationIcon />
            </PassengerIconWrapper>
            <PassengerItemContent>{address_to}</PassengerItemContent>
          </PassengerItemList>
          {!cancelled && estimated && (
            <PassengerItemList>
              <Timer
                icon={<TimerIcon/>}
                text={estimated}
              />
            </PassengerItemList>
          )}
        </PassengerItemListWrapper>
        {urls && urls.share && (
          <SharePosition
            text={t('passenger.sharePassengerLocation')}
            data={urls.share}
            clipboardText={t('passenger.copyLinkToClipboard')}
            mailText={t('passenger.sendInMail')}
          />
        )}
      </PassengerContent>
    </PassengerItemWrapper>
  )
}

export default withNamespaces()(PassengersItem);
