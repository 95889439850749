import styled, {css} from 'styled-components';
import {rem, lighten} from 'polished';
import theme from 'config/theme';
import {PrimaryButton, PrimaryButtonStyles} from 'styled/Buttons';

export const ContentWrapper = styled.div`
	width: 100%;
	max-width: 280px;
	@media (max-width: ${theme.breakpoints.xl}) {
		max-width: 220px;
	}
	@media (max-width: ${theme.breakpoints.lg}) {
		max-width: 280px;
	}
	@media (max-width: 375px) {
		max-width: 220px;
	}
`;

const LoginForm = styled.form`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
`;

const LoginHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	
	img {
	
		@media (max-width: 568px) {
			max-width: 130px;
			margin: 0 auto;
		}
	}
`;

const LoginHeaderInfo = styled.p`
	font-family: ${theme.font.family};
	font-weight: ${theme.font.bolder};
	font-size: ${rem('12px')};
	color: ${theme.colors.darkNavy};
	margin-top: 25px;
	text-align: center;
	margin-bottom: 25px;
	@media (max-width: 568px) {
		margin-top: 10px;
	}
`;

const LoginMessage = styled.div`
	padding: 12px;
	width: 280px;
	background-color: ${props => props.error ? lighten(0.3, theme.colors.error) : lighten(0.15, theme.colors.apple)};
	color: ${props => props.error ? lighten(0.1, theme.colors.error) : theme.colors.white};
	border-radius: 4px;
	font-size: ${rem('12px')};
	margin-bottom: 15px;
	min-height: 40px;
	text-align: center;
	pointer-events: none;
	transition: all .2s ease-in;
	
	@media (min-width: ${theme.breakpoints.lg}) {
		width: 100%;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		width: 100%;
	}
	@media (max-width: 375px) {
		width: 250px;
	}
`;

const LoginButtonStyles = css`
	${PrimaryButtonStyles};
	margin-top: 16px;
	max-height: 40px;
	max-width: 280px;
	@media (min-width: ${theme.breakpoints.lg}) {
		max-width: 220px;
	}
	@media (min-width: ${theme.breakpoints.xl}) {
		max-width: 280px;
	}
	@media (max-width: 568px) {
		margin-top: 8px;
	}
	@media (max-width: 375px) {
		max-width: 250px;
	}
`;

const SelectWrapper = styled.div`
  display: flex;
  margin: 0 0 25px;
  
  @media (max-width: 767px) {
		margin: 5px 0 20px;
	}
`;

const LoginButton = styled(PrimaryButton)`
	${LoginButtonStyles};
`;

export default {
	ContentWrapper,
  LoginForm,
  LoginHeader,
  LoginMessage,
  LoginHeaderInfo,
  LoginButtonStyles,
  LoginButton,
  SelectWrapper
}
