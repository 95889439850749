import React, { useEffect, useState } from "react";
import {useSelector} from 'react-redux';

const CarMarker = ({
   map,
   H,
   ui,
   behavior,
   geoLat,
   geoLon,
   icon,
   color = null,
   geoDegree = 0,
   mainGroup,
   handleGroupChange,
   zIndex = 10,
 }) => {
  const [marker, setMarker] = useState(null);

  const boundToMarkers = useSelector(({routes}) => routes.boundToMarkers);

  useEffect(() => {
    if (geoLat && geoLon && color) {
      addMarker(geoLat, geoLon, color, icon, zIndex, boundToMarkers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoLat, geoLon, icon, geoDegree, color, map]);

  useEffect(() => {
    return () => {
      if (map && marker && mainGroup) {
        mainGroup.removeObject(marker);
        handleGroupChange(mainGroup, false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, marker, mainGroup]);

  const addMarker = (geoLat, geoLon, clr, ico = null, layerIndex, shouldBound) => {
    if (map) {
      let coloredIcon = ico;
      let marker;

      if (clr && coloredIcon) {
        coloredIcon = coloredIcon.replaceAll("#fd739e", `${clr}`);
        coloredIcon = coloredIcon.replaceAll("#B4BBBF", `${clr}`);
      }

      if (ico) {
        const domIconElement = document.createElement("div");
        domIconElement.innerHTML = `${coloredIcon}`;
        const icon = new H.map.DomIcon(domIconElement, {
          onAttach: function (clonedElement, domIcon, domMarker) {
            const clonedContent = clonedElement.getElementsByTagName("svg")[0];
            clonedContent.style.position = 'absolute';
            clonedContent.style.zIndex = layerIndex;
            clonedContent.style.transform = "rotate(" + geoDegree + "deg)";
            clonedContent.style.left = '-20px';
            clonedContent.style.top = '-20px';
          },
        });

        marker = new H.map.DomMarker(
          {
            lat: geoLat,
            lng: geoLon,
          },
          { icon: icon }
        );
      } else {
        marker = new H.map.Marker(
          {
            lat: geoLat,
            lng: geoLon,
          },
        );
      }

      if (boundToMarkers) {
        map.setCenter({
          lat: geoLat,
          lng: geoLon,
        });
        map.setZoom(10);
      }

      mainGroup.addObject(marker);
      setMarker(marker);

      if (shouldBound) {
        handleGroupChange(mainGroup, shouldBound);
      }
    }
  };
  return <></>;
};

export default CarMarker;
