import React from 'react';
import {AlertBox} from "./styles";

const Alert = ({text, color}) => {
  return (
    <AlertBox color={color}>{text}</AlertBox>
  )
};

export default Alert;

