import styled from "styled-components";
import theme from 'config/theme';
import rem from "polished/lib/helpers/rem";


export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px 15px;
  font-size: 12px;
  
  @media (max-width: ${theme.breakpoints.xl}) {
  	padding: 0 15px 15px;
  }
`;

export const GrayLabel = styled.div`
 width: 16.666%;
 color: ${theme.colors.blueyGrey};
 display: flex;
 align-items: center;
 justify-content: space-between;
 white-space: nowrap;
 font-size: ${rem('10px')}; 
`;

export const LocationLabel = styled.div`
 width: 25%;
 color: ${theme.colors.blueyGrey};
 font-size: ${rem('10px')}; 
`;

export const ActionsLabel = styled.div`
 width: 5%;
 color: ${theme.colors.blueyGrey};
 text-align: center;
 font-size: ${rem('10px')}; 
`;

export const SortButtonWrapper = styled.div`
  margin-right: 25px;
  padding-bottom: 2px;
    svg {
      margin-right: 3px;
      cursor: pointer;
      transition: .3s all;

        path {
          &:hover {
              fill: ${theme.colors.apple};

                  }
             }
    }
`;