import React from 'react';
import {LabelWrapper, LabelCarWrapper, LabelName} from './styles';
import { ReactComponent as CarIcon } from 'assets/icons/carIcon.svg';

const CarLabel = ({label}) => {
  return (
    <LabelWrapper>
      <LabelCarWrapper>
        <CarIcon />
      </LabelCarWrapper>
      <LabelName>{label}</LabelName>
    </LabelWrapper>
  )
}

export default CarLabel