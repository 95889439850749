import styled from "styled-components";
import theme from "config/theme";
import { rem } from "polished";

const ContentInfoWrap = styled.div`
  display: flex;
  flex-direction: wrap;
  justify-content: center;
  align-items: center;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const ContentInfoBlock = styled.a`
  background-color: ${theme.colors.white};
  border-radius: 4px;
  padding: 5px 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover,
  &:focus {
    opacity: 0.6;
  }
`;

const ContentInfoText = styled.span`
  font-size: ${rem("12px")};
  font-weight: ${theme.font.regular};
  line-height: ${rem("14px")};
  color: ${theme.colors.blueyGrey};
  margin-left: 8px;
`;

const ContentInfoIconWrap = styled.span``;

export {
  ContentInfoWrap,
  ContentInfoBlock,
  ContentInfoText,
  ContentInfoIconWrap,
};
