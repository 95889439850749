const colors = {
  white: "#ffffff",
  black: "#000000",
  veryLightBlue: "#e5ecef",
  apple: "#66cb35",
  error: "#FF6352",
  paleGrey: "#f4f8fa",
  darkNavy: "#00063c",
  navy: "#1D256C",
  cloudyBlue: "#b9bbcb",
  lightBlueGrey40: "rgba(195, 207, 224, 0.4)",
  leafyGreen: "#61bc35",
  mango: "#ffa92a",
  yellow: "#F9C000",
  red: "#D82840",
  editRed: '#EA5D58',
  lightEditRed: 'rgba(234, 93, 88, 0.05)',
  armyGreen20: "rgba(35, 69, 18, 0.2)",
  blueyGrey: "#9b9eb9",
  carnation: "#fd739e",
  lightBlue: "#70c2ea",
  seafoamGreen: "#84eab3",
  markerGray: '#B4BBBF',
  lightRed: 'rgba(234, 93, 88,.1)',
  lightGray: 'rgba(234, 93, 88,.1)',
  labelOrange: '#EA9058',
  lightOrange: 'rgba(244, 144, 88, 0.1)',
};

const breakpoints = {
  xs: "480px",
  sm: "768px",
  md: "992px",
  lg: "1024px",
  xl: "1440px",
};

const font = {
  family: "Inter, sans-serif",
  regular: "normal",
  bolder: "bold",
};

const height = {
  full: "100vh",
};

const theme = {
  colors,
  breakpoints,
  font,
  height,
};

export default theme;
