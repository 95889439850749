import styled from 'styled-components'
import theme from 'config/theme'

export const ArrowContainer = styled.div`
  margin-left: 10px;
  
  svg {
    height: 8px;
    width: 8px;
  }
`;

export const DropDownContainer = styled.div`
  margin: 0 auto;
  position: relative;
  max-height: 30px;
  padding: ${(props) => props.withChangeText ? '10px 0': '10px 10px 10px 0'};
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  justify-content: center;
  cursor: pointer;
  margin-right: ${(props) => props.withChangeText ? '15px': 'auto'};
  
   &:hover ${ArrowContainer} {
    svg {
      path {
        transition: .3s stroke;
        stroke: ${theme.colors.apple};
      }
    }
  }
`;

export const DropDownHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.colors.darkNavy};
  font-size: 0.75rem;
  
  svg {
    position: relative;
    top: 3px;
  }
`;

export const DropDownListContainer = styled.div``;

export const DropDownList = styled.div`
  padding: 0;
  margin: 0;
  position:absolute;
  top: 120%;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  background: #ffffff;
  border: 1px solid ${theme.colors.veryLightBlue};
  min-width: 145px;
  border-radius: 4px;
  overflow: hidden;
`;

export const ListItem = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  
  &:hover {
  background: ${theme.colors.apple};
  }
  
  svg {
    position: relative;
    top: 3px;
  }
`;
export const BoldText = styled.div`
  font-weight: 600;
  margin: 0 5px;
`;

export const DropDownText = styled.div`
  display:flex;
  flex-direction: ${(props) => props.withChangeText ? 'row': 'column'};
  text-align: center;
`;