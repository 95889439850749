import styled from 'styled-components';
import theme from 'config/theme';
import { CarsListsUsr } from "components/cars/CarsList/styles";

export const CompanyCarWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.apple} transparent;
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.apple};
    border-radius: 10px;
  }
`;

export const CompanyCarWrapperInner = styled.div`
  padding-bottom: 70px;

  @media (max-width: ${theme.breakpoints.md}) {
    padding-bottom: 90px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 26px 15px 25px;
  justify-content: space-between;
  border-bottom: 1px solid #f4f8fa;

  svg {
    cursor: pointer;
  }
`;

export const HeaderContent = styled(CarsListsUsr)`
  color: ${theme.colors.darkNavy};
  font-weight: ${theme.font.bolder};
  flex-grow: 1;
`;