import React, {useEffect} from "react";
import EventsStyles, {EventsHeader} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {SpinnerWrapper, Spinner} from "../../../styled/Spinner";
import EventsItem from "../EventsItem";
import Alert from "../../__common/Alert";
import theme from "../../../config/theme";
import {getEvents} from "store/events/thunks";
import BackButton from "components/__common/BackButton";
import Fade from "../../__common/Fade";
import {withNamespaces} from 'react-i18next';

const Events = ({
  markEventAsRead,
  markEventAsNew,
  activePanel,
  hideSidebarPanel,
  t
}) => {
  const events = useSelector(({events}) => events.events);
  const eventsLoading = useSelector(({events}) => events.eventsLoading);
  const filters = useSelector(({filters}) => filters.filters);
  const d = useDispatch();

  useEffect(() => {
    if (activePanel === 3) d(getEvents());
  }, [d, activePanel]);

  useEffect(() => {
    if (activePanel === 3) {
      d(getEvents());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, filters]);

  return (
    <EventsStyles.Wrapper>
      <EventsStyles.HeaderWrapper>
        <EventsHeader>{t('events.eventsHeading')}</EventsHeader>
        <BackButton onClick={hideSidebarPanel}/>
      </EventsStyles.HeaderWrapper>
      <EventsStyles.EventsWrapper>
        {events.length ? (
          <>
            {eventsLoading ? (
              <SpinnerWrapper>
                <Spinner/>
              </SpinnerWrapper>
            ) : (
              events.map((event) => {
                return (
                  <Fade key={event.id}>
                    <EventsItem
                      key={event.id}
                      onEventMarkRead={markEventAsRead}
                      onEventMarkNew={markEventAsNew}
                      event={event}
                    />
                  </Fade>
                );
              })
            )}
          </>
        ) : (
          <Alert
            text={t('events.noEventsMessage')}
            color={theme.colors.paleGrey}
          />
        )}
      </EventsStyles.EventsWrapper>
    </EventsStyles.Wrapper>
  );
};

export default withNamespaces()(Events);
