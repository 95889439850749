import styled from 'styled-components';
import theme from 'config/theme';
import { rem } from 'polished';

export const PassengersWrapper = styled.div`
  overflow-y: auto;
  height: calc(100% - 180px);
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.apple} transparent;

  @media (max-width: 1023px) {
    height: calc(100vh - 271px);
  }

  @media (max-width: 466px) {
    height: calc(100vh - 290px);
  }

  @media (max-width: 346px) {
    height: calc(100vh - 323px);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.apple};
    border-radius: 10px;
  }
`;

export const PassengersFiltersWrapper = styled.div`
  margin-top: 10px;
  display: inline-block;
  
  > div {
    @media (max-width: 1023px) {
      min-width: 100px;
      width: 100%; 
    }
  }
`;

export const PassengersWrapperInner = styled.div`
  padding-bottom: 60px;
`;

export const ShareAllPassengersPositionsWrapper = styled.div`
  background-color: ${theme.colors.white};
  padding: 20px 15px;
  height: 65px;
  z-index: 11111;
  position: relative;
`;

export const RefreshButton = styled.button`
  width: 25px;
  height: 25px;
  outline: none;
  border: none;
  border-radius: 50%;
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.apple};
  color: white;
  opacity: ${props => props.disabled ? 0.6 : 1};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  svg {
    * {
      stroke: white !important;
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const RefreshWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const RefreshLabel = styled.div`
  font-size: ${rem('10px')};
  margin-right: 10px;
  color: ${theme.colors.blueyGrey};
  white-space: nowrap;
`;
