import * as types from "./types";

export const setSettings = settings => {
  return {
    type: types.SET_SETTINGS,
    payload: settings,
  };
};

export const setInitialSettingsValues = settings => {
  return {
    type: types.SET_INITIAL_SETTINGS_VALUES,
    payload: settings,
  };
};

export const setSettingsLoading = isLoading => {
  return {
    type: types.SET_SETTINGS_LOADING,
    payload: isLoading,
  };
};

export const setSettingsFormSending = isSending => {
  return {
    type: types.SET_SETTINGS_FORM_SENDING,
    payload: isSending,
  };
};

export const setSettingsErrors = errors => {
  return {
    type: types.SET_SETTINGS_ERRORS,
    payload: errors,
  };
};

export const setBasicConfig = config => {
  return {
    type: types.SET_BASIC_CONFIG,
    payload: config,
  };
};

export const setBasicConfigLoading = isLoading => {
  return {
    type: types.SET_BASIC_CONFIG_LOADING,
    payload: isLoading,
  };
};
