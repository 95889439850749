import styled from 'styled-components';
import posed from 'react-pose';

export const MapWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  
  @media (max-width: 1023px) {
    justify-content: flex-end;
    position: relative;
    overflow-y: hidden;
  }
  .map-content {
    canvas + div {
      z-index: 0 !important;
    }
  }
`;

export const RouteButton = styled.button`
  display: block;
  margin: 10px;
`;

export const RouteButtonsWrapper = styled.div`
  position: fixed;
  z-index: 50;
  bottom: 40px;
  right: 40px;
`;

export const NotificationWrapper = styled.div`
  position: absolute;
  bottom: 40px; 
  right: 80px; 
  min-width: 380px;
  
  @media (max-width: 1023px) {
    bottom: 100px;
  }
  
  @media (max-width: 640px) {
    min-width: auto; 
    right: 75px;
    left: 15px;
  }
`;

export const FadingToast = posed.div({
  enter: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: 400,
  }
});
