import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const LabelWrapper = styled.div`
  border-radius: 4px;
  background: ${({backgroundColor}) => backgroundColor ? backgroundColor : theme.colors.editRed};
  padding: 3px 10px;
  font-size: ${rem('10px')};
  color: ${({textColor}) => textColor ? textColor : theme.colors.white};
  margin: 0 3px;
  font-weight: bold;
  white-space: nowrap;
`;
