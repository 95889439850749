import history from 'config/history';
import API from 'api';
import {URL_LOGOUT} from 'api/config';

export const isUser = () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user === null || user === undefined) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
}


export const getUser = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      return {
        success: true,
        ...user
      }
    } else {
      return {
        success: false
      }
    }
  } catch (err) {
    return {
      success: false
    }
  }
}

export const saveUser = user => {
  try {
    localStorage.setItem('user', JSON.stringify(user));
  } catch (err) {
    throw new Error(err);
  }
}

export const logout = async (callback = null) => {
  try {
    const user = await getUser();
    if (user.success) {
      await API.post(URL_LOGOUT);
      if (callback) callback();
      removeTokenAndRedirect();
    } else {
      if (callback) callback();
    }
  } catch (err) {
    if (callback) callback();
    removeTokenAndRedirect();
    console.log(err);
  }
}

const removeTokenAndRedirect = () => {
  localStorage.removeItem('user');
  history.push('/login');
}
