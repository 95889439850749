import axios from "axios";
import {BASE_API_URL} from "./config";
import {displayProperToast} from "utils/toast";
import {logout} from "utils/auth";
import I18n from 'i18n';

const API = axios.create({
  baseURL: BASE_API_URL,
});

API.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user")) || "";
    if (user) {
      config.headers["X-API-Key"] = `${user.token}`;
      config.headers["X-Db-Id"] = user.databaseID ? `${user.databaseID}` : `1`;
    }
    config.headers["X-Language"] = I18n.language;
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (response.data.flashes) displayProperToast(response.data.flashes);
    return response.data;
  },
  (error) => {
    const res = error.response;
    if (res && res.status) {
      switch (res.status) {
        case 400:
          if (res.data && res.data.flashes) {
            displayProperToast(res.data.flashes);
            return {
              status: 400,
              flashes: res.data.flashes,
            };
          } else {
            displayProperToast({
              error: ["Wystąpił nieoczkiwany błąd!"],
            });
            return {
              status: 400,
              flashes: "Wystąpił nieoczkiwany błąd!",
            };
          }
        case 401:
          logout();
          if (res.data && res.data.flashes) {
            displayProperToast(res.data.flashes);
            return {
              status: 401,
              flashes: res.data.flashes,
            };
          }
          break;
        case 404:
          if (res.data && res.data.flashes) {
            displayProperToast(res.data.flashes);
            return {
              status: 404,
              flashes: res.data.flashes,
            };
          }
          break;
        case 422:
          if (res.data && res.data.data && res.data.data.form_error) {
            if (res.data.flashes) {
              displayProperToast(res.data.flashes);
            }
            return res.data;
          } else {
            if (res.data && res.data.flashes) {
              return {
                status: 422,
                flashes: res.data.flashes,
              };
            } else {
              return {
                status: 422,
                flashes: ["Wystąpił nieoczekiwany błąd!"],
              };
            }
          }
        default:
          displayProperToast({
            error: ["Wystąpił nieoczekiwany błąd!"],
          });
          return {
            flashes: ["Wystąpił nieoczekiwany błąd!"],
          };
      }
    }
  }
);

export default API;
