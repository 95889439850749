import * as types from './types';

const initialState = {
  events: [],
  unreadAlertsCounter: 0,
  toastEvents: [],
  eventsLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case types.SET_EVENTS_LOADING:
      return {
        ...state,
        eventsLoading: action.payload,
      };
    case types.SET_UNREAD_ALERTS_COUNTER:
      return {
        ...state,
        unreadAlertsCounter: action.payload,
      };
    case types.ADD_TOAST_EVENT:
      return {
        ...state,
        toastEvents: [action.payload, ...state.toastEvents],
      };
    case types.REMOVE_TOAST_EVENT:
      return {
        ...state,
        toastEvents: state.toastEvents.filter(toast => toast.id !== action.payload),
      };
    case types.ADD_EVENTS_TO_EVENTS:
      return {
        ...state,
        events: [action.payload, ...state.events],
      }
    case types.UPDATE_EVENTS:
      return {
        ...state,
        events: state.events.map(alert => alert.id === action.payload.id ? action.payload : alert)
      }
    default: 
      return state;
  };
};
