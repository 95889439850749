import styled, {css} from 'styled-components';
import {rem} from 'polished';
import theme from 'config/theme';

export const PrimaryButtonIcon = styled.div`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%) translateX(-16px);
	transition: transform .3s ease, opacity .3s ease;
	max-width: 21px;
	svg {
		width: 100%;
	}
`;

export const PrimaryButtonStyles = css`
	position: relative;
	min-width: 180px;
	min-height: 40px;
	width: 100%;
	outline: none;
	padding: 12px 16px;
	border-radius: 4px;
	border: none;
	background-color: ${theme.colors.apple};
	text-decoration: none;
	text-align: left;
	overflow: hidden;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transform: scaleX(0);
		transform-origin: left;
		background-color: ${theme.colors.darkNavy};
		transition: transform .3s ease, opacity .3s ease;
	}
	
	&:hover, &:focus {
	
		&:before {
			opacity: 1;
			transform: scaleX(1);
		}
		
		${PrimaryButtonIcon} {
			transform: translateY(-50%) translateX(-12px);
			opacity: .6;
		}
	}
	
	&.small-button {
		background-color: ${theme.colors.white};
		padding: 8px;
    min-width: auto;
    min-height: 35px;
    
    &:before {
		  background-color: ${theme.colors.paleGrey};
	  }
    
    @media (min-width: 1440) {
				  padding: 8px 12px;
		}
  
    	span {
    		font-size: ${rem('11px')};
    		color: ${theme.colors.darkNavy};
    	}
	}
`;

export const PrimaryButton = styled.button`
	${PrimaryButtonStyles};

	max-width: ${props => props.wide ? '100%' : '250px'};
	margin: ${props => props.centered ? '0 auto' : '0'};

	&.loading {
		
		&:before {
			opacity: 1;
			transform: scaleX(1);
		}
	}
`;

export const DarkPrimaryButton = styled(PrimaryButton)`
	background-color: ${theme.colors.darkNavy};
	&:before {
		background-color: ${theme.colors.apple};
	}
`;

export const PrimaryButtonText = styled.span`
	position: relative;
	font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('12px')};
	line-height: 1.17;
	color: ${theme.colors.white};
	text-align: left;
	display: flex;
	align-items: center;  
	
	svg {
	  margin-right: 5px;
    width: 15px;
    height: 20px;
	}
`;
