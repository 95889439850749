import styled from 'styled-components';
import theme from 'config/theme';

const LoginWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
`

const LeftSide = styled.div`
	position: relative;
	min-width: 28%;
	min-height: 320px;
	height: ${theme.height.full};
	background-color: ${theme.colors.white};
	box-shadow: 0 5px 15px 0 ${theme.colors.lightBlueGrey40};
	border-top-right-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	z-index: 99;
	@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1440px) {
  	justify-content: center;
  }
  @media (min-width: 1920px) {
  	justify-content: center;
  }
	@media (max-width: 1023px) {
		width: 100%;
		justify-content: center;
	}
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 6px;
		height: 100%;
		background-color: ${theme.colors.apple};
		border-top-right-radius: 10px;
	}
`

const RightSide = styled.div`
	position: relative;
	min-width: 72%;
	height: ${theme.height.full};
	background-color: ${theme.colors.white};
	
	img {
		position: absolute;
    bottom: 140px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    
    @media only screen 
		and (min-device-width: 1024px) 
		and (max-device-width: 1366px) {
			bottom: auto;
			top: 50%;
			transform: translate(-50%, -50%);
		}
    
    @media (min-width: ${theme.breakpoints.lg}) {
    	max-width: 80%;
    }
    
    @media (min-width: 1441px) {
    	bottom: 220px;
    }
	}
	
	@media (max-width: 1023px) {
		display: none;
	}
`

const BackgroundLogin = styled.div`
	background-image: url(${({image}) => image});
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	width: 100%;
	
	@media only screen 
		and (min-device-width: 1024px) 
		and (max-device-width: 1366px) {
			position: relative;
			top: 50%;
			transform: translateY(-100%);
		}
`

export default {
	LoginWrapper,
	LeftSide,
	RightSide,
	BackgroundLogin,
}
