export default {
	"login": {
		"logoAlt": "GTV Bus",
		"header": "Log in het bedieningspaneel in",
		"companyHeader": "Log in op het bedrijfspaneel",
		"loginForm": "Login",
		"loginFormPassword": "Wachtwoord",
		"forgotPassword": "Ben je je wachtwoord vergeten? Neem contact met de beheerder op ",
		"loginFormButton": "Log in",
		"logging": "Login...",
		"verify": "Verificatie...",
		"helpText": "Hulp nodig? Bel ons",
		"loading": "Wordt geladen..."
	},
	"languages": {
		"language": "Taal",
		"pl": "Polski",
		"en": "English",
    "ro": "Română",
		"nl": "Nederlands",
		"ua": 'Український',
		"de": "Deutsche",
		"change": "Wijzig"
	},
	"database": {
		"name": "Gegevensbank",
		"pl": " Pools",
		"nl": " Nederlands",
		"ro": " Roemeens",
		"pl2": ' Autko 2.0',
		"undefined": ' ongedefinieerd'
	},
	"route": {
		"editRoute": "Bewerk route",
		"cancelEdit": "Annuleer bewerking",
		"saveChanges": "Sla wijzigingen op",
		"savingChanges": "Wordt opgeslagen...",
		"editAlert1": "Markeer het traject dat je wilt bewerken ",
		"editAlert2": "door twee punten op de route ",
		"editAlert3": "te kiezen, en vervolgens ",
		"editAlert4": "duid de omleidingspunt(en) aan ",
		"editAlert5": "voor dit traject van de route.",
		"taskCancelled": 'Nietig verklaard',
		"removePoint": 'Punt verwijderen',
		"additionalPoint": 'Omleidingspunt',
	},
	"confirmModal": {
		"confirmButton": "Ja, ik sluit af ",
		"cancelButton": "Nee, ik wil de bewerking voltooien ",
		"mainText": "Wijzigingen in de route zijn niet opgeslagen en zullen vervallen.",
		"boldText": "Wil je zeker verlaten?"
	},
	"filters": {
		"all": "Alle",
		"departure": "Vertrek",
		"back": "Terugkomst",
		"labels": {
			"departureDate": 'Vertrekdatum',
			"date": "Datum",
			"tripType": "Soort route",
			"team": "ID van de afdeling"
		},
		"allPassengers": 'Iedereen',
		"presentPassengers": 'Cadeau',
	},
	"tabs": {
		"info": "Informatie",
		"task": "Opdracht"
	},
	"sidebar": {
		"vehicles": "Voertuigen",
		"drivers": "Bestuurders",
		"passengers": "Passagiers",
		"workers": "Medewerkers",
		"events": "Gebeurtenissen",
		"chat": "Chat"
	},
	"cars": {
		"searchCar": "Zoek een voertuig",
		"noCarsFound": "Geen voertuigen voor deze criteria gevonden!",
		"carTypePlaceholder": "Soort",
		"carTypes": {
			"all": "Alle",
			"target": "Doel",
			"delivers": "Rondbrengende",
			"shippers": "Leverende",
			"other": "Ander",
		},
		"drivers": "Bestuurders",
		"trip": "Route",
		"tripTypes": {
			"target": "Doel",
			"delivers": "Rondbrengend",
			"shippers": "Leverend",
			"other": "Verschillend"
		},
		"vehicleTrip": "Voertuigroute",
		"tripNotPlanned": "De route werd nog niet gepland ",
		"write": "Schrijf",
		"backToCarsList": "Terug naar de lijst met auto’s ",
		"noGpsSignal": 'Geen gps-signaal',
		"routeLines": 'Routelijnen',
		"personIn": "Ophalen",
		"personOut": "Uitstappen",
		"refreshData": "Gegevens vernieuwen"
	},
	"events": {
		"eventsHeading": "Overzicht van de gebeurtenissen",
		"noEventsMessage": "Geen gebeurtenissen genoteerd!",
		"call": "Bel",
		"message": "Schrijf"
	},
	"passenger": {
		"searchWorker": "Zoek een medewerker",
		"car": "Voertuig",
		"fromTo": "Bestemming",
		"notFound": "Geen medewerkers voor deze criteria gevonden!",
		"passengerMissing": 'Afwezig',
		"passengerCancelled": 'Ontslag',
		"start": 'Start',
		"vehicleList": 'Vehicles',
		"sharePassengersLocations": 'Deel de locatie van passagiers',
    "sharePassengerLocation": 'Passagierslocatie delen',
    "copyLinkToClipboard": 'Kopieer link naar klembord',
    "copyLinksToClipboard": 'Koppelingen kopiëren naar klembord',
    "sendInMail": 'Stuur de link per e-mail',
    "clickToFollowLocation": 'Klik op de link om de positie van de passagier te kunnen volgen',
	},
	"companyCars": {
    "companyCarsHeading": "Voertuiglijst",
		"worker": "medewerker",
    "workers": "medewerkers",
    "notFound": "Geen voertuigen gevonden voor de opgegeven criteria!",
	},
	"api": {
    "unexpectedError": 'Er is een onverwachte fout opgetreden!',
  },
};
