import React, {useEffect, useState} from 'react';

const MapPolyline = ({
  map,
  H,
  route,
  color,
  handlePolylinePress,
  editModeEnabled,
  mainGroup,
  handleGroupChange,
}) => {

  const [polyline, setPolyline] = useState(null);

  useEffect(() => {
    if (route && route.length) addRouteShapeToMap(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route, color]);

  useEffect(() => {
    function onPolylinePress(evt) {
      handlePolylinePress(evt, map);
    };

    function onPolylineEnter(evt) {
      document.body.style.cursor = "pointer";
    };

    function onPolylineLeave(evt) {
      document.body.style.cursor = "default";
    };

    if (polyline && editModeEnabled) {
      polyline.addEventListener('pointerdown', onPolylinePress);
      polyline.addEventListener('pointerenter', onPolylineEnter);
      polyline.addEventListener('pointerleave', onPolylineLeave);
    }

    return () => {
      if (polyline) {
        polyline.removeEventListener('pointerdown', onPolylinePress);
        polyline.removeEventListener('pointerenter', onPolylineEnter);
        polyline.removeEventListener('pointerleave', onPolylineLeave);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polyline, map, color, handlePolylinePress, editModeEnabled]);

  useEffect(() => {
    return () => {
      if (map && polyline) {
        mainGroup.removeObject(polyline);
        handleGroupChange(mainGroup, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polyline]);

  const addRouteShapeToMap = route => {
    if (map) {
      const lineString = new H.geo.LineString();
      let polyline = null;

      route.forEach(function(point) {
        lineString.pushLatLngAlt(point.latitude, point.longitude);
      });

      polyline = new H.map.Polyline(lineString, {
        visibility: true,
        style: {
          lineWidth: 5,
          strokeColor: color
        }
      });

      setPolyline(polyline);
      mainGroup.addObject(polyline);
      handleGroupChange(mainGroup);
    }
  }

  return <></>;
}

export default MapPolyline;
