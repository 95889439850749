import styled from 'styled-components';
import theme from 'config/theme';

export const Wrapper = styled.div`
  padding: 25px 40px 40px 40px;
  background-color: ${theme.colors.white};
  border-radius: 10px;
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 10px 25px 25px 25px;
  }
`;
