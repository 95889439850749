import * as actions from './actions';
import API from 'api';
import {
  URL_CREATE_COMPANY,
  URL_GET_COMPANIES,
  URL_GET_CORPS,
  URL_REMOVE_COMPANY,
  URL_EDIT_COMPANY,
  URL_GET_COMPANY_TEAMS,
} from 'api/config';
import history from 'config/history';
import {routes} from 'config/routes';
import {prepareApiErrors} from 'utils/form';

export const getCompanies = params => async dispatch => {
  const queryParams = params  
    ? { 
      page: params.page ? params.page : 1, 
      per_page: params.perPage ? params.perPage : 10,
      search: params.search ? params.search : '',
    } : {};
  
  let config = {
    params: queryParams,
  };

  try {
    dispatch(actions.setCompaniesLoading(true));
    const res = await API.get(URL_GET_COMPANIES, {
      ...config,
    });

    dispatch(actions.setCompaniesLoading(false));
    if (res && res.success) {
      dispatch(actions.setCompanies(res.data));
    }
  } catch (err) {
    dispatch(actions.setCompaniesLoading(false));
  }
};

export const getCorps = () => async dispatch => {
  dispatch(actions.setCorpsLoading(true));
  const res = await API.get(URL_GET_CORPS);
  dispatch(actions.setCorpsLoading(false));

  if (res.success) {
    dispatch(actions.setCorps(res.data));
  }
};

export const removeCompany = id => async dispatch => {
  dispatch(actions.setCompanyRemovingId(id));
  const res = await API.delete(URL_REMOVE_COMPANY, {
    data: { id }
  });
  dispatch(actions.setCompanyRemovingId(null));
  
  if (res.success) {
    dispatch(getCompanies());
  }
};

export const createCompany = form => async dispatch => {
  let data;

  dispatch(actions.setCompanyFormSubmitting(true));
  Object.keys(form).forEach(key => {
    data = {
      ...data,
      [key]: form[key].value
    };
  });

  const res = await API.post(URL_CREATE_COMPANY, {
    ...data,
  });
  dispatch(actions.setCompanyFormSubmitting(false));

  if (res.success) {
    history.push(routes.admin.nested.companies.url);
  } else {
    const errors = prepareApiErrors(res.data.form);
    dispatch(actions.setCompanyFormErrors(errors));
  }
};

export const getCompanyDetails = id => async dispatch => {
  dispatch(actions.setCorpsLoading(true));

  const res = await API.get(URL_EDIT_COMPANY, {
    params: { id }
  });

  dispatch(actions.setCorpsLoading(false));

  if (res.success) {
    const form = res.data.form;
    let formData;

    form.forEach(field => {
      formData = {
        ...formData,
        [field.name]: field.value,
      }
    });

    dispatch(actions.setCurrentCompany(formData));
  }
};

export const updateCompany = (id, form) => async dispatch => {
  let data;

  dispatch(actions.setCompanyFormSubmitting(true));
  Object.keys(form).forEach(key => {
    data = {
      id,
      ...data,
      [key]: form[key].value
    };
  });

  const res = await API.post(URL_EDIT_COMPANY, { 
    id,
    ...data,
  });
  dispatch(actions.setCompanyFormSubmitting(false));

  if (res && res.success) {
    history.push(routes.admin.nested.companies.url);
  } else {
    const errors = prepareApiErrors(res.data.form);
    dispatch(actions.setCompanyFormErrors(errors));
  }
};

export const getCompanyTeams = () => async dispatch => {
  dispatch(actions.setCompanyTeamsLoading(true));

  const res = await API.get(URL_GET_COMPANY_TEAMS);

  dispatch(actions.setCompanyTeamsLoading(false));

  if (res.success) {
    const teamsOptions = res.data.teams && res.data.teams.map(team => {
      return {
        value: team,
        label: team,
      }
    });
    dispatch(actions.setCompanyTeams(teamsOptions));
  }
};
