import io from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_API_URL;

const user = JSON.parse(localStorage.getItem("user")) || {
  token: "",
  user: "",
};

class AlertSocketProvider {
  constructor() {
    this.socket = io(`wss://${SOCKET_URL}/events`);
    this.connectSocket();
  }

  connectSocket() {
    this.socket.on("connect", async () => {
      console.log("Connected successfully!");
      this.socket.emit("join", { room: user.token });
      this.socket.emit("refresh", { token: user.token });
    });

    this.socket.on("connect_error", () => {
      console.log("Connection error!");
    });

    this.socket.on("connect_failed", () => {
      console.log("Connection failed!");
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected!");
    });
  }

  watchAllSocketEvents(setUnreadAlertsCounter, showToastEvent, addEventsToEvents, updateEvents) {
    this.socket.on("update_alert", (data) => {
      updateEvents(data);
    });

    this.socket.on("new_alert", (data) => {
      showToastEvent(data);
      addEventsToEvents(data);
    });

    this.socket.on("counter", (data) => {
      const { events } = data;
      setUnreadAlertsCounter(events);
    });
  }

  setMarkAsReadEvent(eventId) {
    this.socket.emit("alert_mark_as_read", { token: user.token, id: eventId });
  }

  setMarkAsNewEvent(eventId) {
    this.socket.emit("alert_mark_as_new", { token: user.token, id: eventId });
  }

  setFilterEvent(eventDate, eventReturn) {
    const formattedDate = eventDate && eventDate.toISOString().slice(0,10);
    this.socket.emit("refresh", {token: user.token, date: formattedDate, return: eventReturn });
  }
}

export default new AlertSocketProvider();
