import i18n from "i18next";
import {reactI18nextModule} from "react-i18next";
import Backend from 'i18next-chained-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from 'i18next-localstorage-backend';

import pl from './locales/pl';
import en from './locales/en';
import ro from './locales/ro';
import nl from './locales/nl';
import ua from './locales/ua';
import de from './locales/de';

// the translations
const resources = {
  pl: {translation: pl},
  en: {translation: en},
  ro: {translation: ro},
  nl: {translation: nl},
  ua: {translation: ua},
  de: {translation: de},
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    backend: {
      backends: [
        LocalStorageBackend,  // primary
      ],
      backendOptions: [{
        prefix: 'i18next_res_',
        expirationTime: 7 * 24 * 60 * 60 * 1000,
        defaultVersion: '',
        versions: {},
        store: window.localStorage
      }, {
        loadPath: '/locales/{{lng}}/{{ns}}.json' // http api load path for my own fallback
      }]
    }
  },);

export default i18n;
