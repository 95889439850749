import React from 'react';
import {Wrapper, ItemsWrapper, Item, ItemIcon} from './styles';
import {ReactComponent as PaginationArrow} from 'assets/icons/next_previous.svg';

const Pagination = ({ onPageClick, count, pageCount, currentPage, total }) => {

  const returnPaginationItems = () => {
    let items = [];
    for (let i = 1; i <= pageCount; i++) {
      items = [...items, i];
    };

    return items;
  };

  return (
    <Wrapper>
      <ItemsWrapper>
        {returnPaginationItems().map(page => {
          return (
            <Item disabled={page === currentPage} key={page} onClick={() => onPageClick(page)} isActive={page === currentPage}>{page}</Item>
          )})
        }
      </ItemsWrapper>
      <ItemsWrapper>
        {currentPage > 1
          ? <Item onClick={() => onPageClick(currentPage - 1)} isActive={true}>
              <ItemIcon isFliped={true}>
                <PaginationArrow />
              </ItemIcon>
            </Item>
          : null
        }
        {currentPage < pageCount 
          ? <Item onClick={() => onPageClick(currentPage + 1)} isActive={true}>
              <ItemIcon>
                <PaginationArrow />  
              </ItemIcon>
            </Item>
          : null
        }
      </ItemsWrapper>
    </Wrapper>
  );
};

export default Pagination;
