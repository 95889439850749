import * as React from "react"

const DriverIcon = ({ width = 12.41, height = 13.31 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15.811 16.936">
      <defs>
        <style>
          {
            ".prefix__prefix__cls-1{fill:none;stroke:#b9bbcb;stroke-linecap:round;stroke-miterlimit:10;stroke-width:1px}"
          }
        </style>
      </defs>
      <g
        id="prefix__prefix__Group_14"
        data-name="Group 14"
        transform="translate(-2111.006 -1598.123)"
      >
        <circle
          id="prefix__prefix__Ellipse_10"
          cx={4.213}
          cy={4.213}
          r={4.213}
          className="prefix__prefix__cls-1"
          data-name="Ellipse 10"
          transform="rotate(-25.373 4615.392 -3892.757)"
        />
        <path
          id="prefix__prefix__Path_11"
          d="M2126.368 1683.134a7.651 7.651 0 00-14.912-.037"
          className="prefix__prefix__cls-1"
          data-name="Path 11"
          transform="translate(0 -68.732)"
        />
        <path
          id="prefix__prefix__Path_12"
          d="M2143.847 1701.78a4.534 4.534 0 00-8.837-.022"
          className="prefix__prefix__cls-1"
          data-name="Path 12"
          transform="translate(-20.608 -87.169)"
        />
      </g>
    </svg>
  )
}

export default DriverIcon;
