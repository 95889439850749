import styled, { css } from "styled-components";
import { rem } from "polished";
import theme from "config/theme";

const TabsWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TabsWrapperItem = styled.li`
  position: relative;
  text-align: center;
  padding: 12px 50px;
  display: inline-block;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.apple};
    transform: scaleX(0);
    opacity: 0;
    transform-origin: left center;
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::before {
        opacity: 1;
        transform: scaleX(1);
      }

      span {
        color: ${theme.colors.apple};
      }
    `} {
  }

  :hover {
    &::before {
      opacity: 1;
      transform: scaleX(1);
    }

    span {
      color: ${theme.colors.apple};
    }
  }
`;

const TabsItem = styled.span`
  font-size: ${rem("10px")};
  font-weight: ${theme.font.bolder};
  color: ${theme.colors.dementedEbony};
  line-height: ${rem("12px")};
  text-transform: uppercase;
  transition: color 0.3s ease-in;
`;

export { TabsWrapper, TabsWrapperItem, TabsItem };
