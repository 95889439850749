import styled from 'styled-components';
import {rem} from 'polished';
import theme from 'config/theme';
import posed from 'react-pose';

const onEnterAnimation = { 
  opacity: 1,
  y: 0,
  delay: 500,
  transition: {
    default: { ease: 'linear', duration: 300 }
  }
}

const onLeaveAnimation = { 
  opacity: 0,
  y: 20,
  transition: {
    default: { ease: 'linear', duration: 300 }
  }
}

export const FadeWrapper = posed.div({
  enter: onEnterAnimation,
  exit: onLeaveAnimation
});

export const EditAlertWrapper = styled(FadeWrapper)`
  position: fixed;
  bottom: 55px;
  padding: 15px;
  border-radius: 5px;
  background-color: ${theme.colors.darkNavy};
  right: 80px;
  max-width: 900px;
  left: 30%;
  @media (max-width: 1710px) {
    left: 530px;
  }
  @media (max-width: 1520px) {
    left: 480px;
  }
  @media (max-width: 1023px) {
    bottom: 130px;
    left: 390px;
  }
  @media (max-width: 601px) {
    left: 20px;
    right: 20px;
    bottom: 30px;
  }
`;

export const AlertContent = styled.div`
  width: 100%;
  position: relative;
`;

export const Text = styled.div`
  font-size: ${rem('11px')};
  color: ${theme.colors.white};
  padding-right: 35px;
`;

export const TextBold = styled.span`
  font-weight: bold;
`;

export const CloseButton = styled.button`
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  padding: 5px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -15px;
  top: -15px;

  svg {
    width: 8px;
    height: 8px;
  }
`;
