import styled from 'styled-components';
import theme from 'config/theme';
import {rem} from 'polished';

export const UserAvatar = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${theme.colors.apple};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${rem('12px')};
  font-weight: bold;
  color: ${theme.colors.white};
  flex-shrink: 0;
  cursor: ${props => props.isAdmin ? 'default' : 'pointer'};
  transition: .3s background-color;
  
  &:hover {
    @media (max-width: 767px) {
      background-color: ${props => props.isAdmin ? theme.colors.apple : theme.colors.darkNavy};
    }
  }
   
  @media (max-width: 767px) {
    margin-right: 10px;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px;
  
  &:hover {
    div {
      color: ${props => props.isAdmin ? theme.colors.darkNavy : theme.colors.apple};
    }
    svg {
      path {
        transition: .3s fill;
        fill: ${theme.colors.apple};
      }
    }
  }
  
  @media (max-width: 767px) {
    display: none;
  }
`;

export const UserName = styled.div`
  font-weight: bold;
  color: ${theme.colors.darkNavy};
  font-size: ${rem('12px')};
  margin-right: 15px;
  max-width: 135px;
  cursor: ${props => props.isAdmin ? 'default' : 'pointer'};
  transition: .3s color;
    
  @media (max-width: 767px) {
    display: none;
  }
`;

export const LogoutButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: ${theme.colors.paleGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .2s ease;
  svg {
    path,
    line {
      transition: stroke .2s ease;
      stroke: ${theme.colors.darkNavy};
    }
  }
  &:hover {
    background-color: ${theme.colors.apple};
    svg {
      path,
      line {
        stroke: ${theme.colors.white};
      }
    }
  }
`;

export const LoggedUserWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TopMenuWrapper = styled.div``;

export const TopMenu = styled.div`
  background: ${theme.colors.paleGrey};
  box-shadow: 1px 10px 10px -7px rgba(0,0,0,.15);
  position: absolute;
  top: 60px;  
  right: 50px;
  width: 100%;
  max-width: 200px;
  height: 50px;
  padding: 10px;
  border-bottom-left-radius: 10px;   
  border-bottom-right-radius: 10px;   
  border-top: 1px solid ${theme.colors.paleGrey};
  transform: ${(props) => props.isOpen ? 'translateY(0%)' : 'translateY(-40%)'};
  transform-origin: top center;
  opacity: ${(props) => props.isOpen ? '1' : '0'};
  transition: transform .4s ease, opacity .4s ease;
  z-index: -1;
  
  @media (max-width: 767px) {
   top: 57px;
   right: 0;
   border-bottom-right-radius: 0;   
  }
  @media (max-width: 468px) {
    top: 87px;  
  }
  
  @media (max-width: 347px) {
    top: 124px;  
  }
`;