import styled from 'styled-components';
import { rem } from 'polished';
import theme from 'config/theme';
import posed from 'react-pose';
import { GlobalIconWrapper } from '../../__common/Timer/styles';
import { PrimaryButton } from 'styled/Buttons';

const contentConfig = posed.div({
  open: {
    opacity: 1,
    height: 'auto',
    transition: {duration: 200},
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {duration: 200},
  }
});

export const PassengerItemWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 15px 20px;
  overflow: hidden;
  background-color: ${({bgColor}) => bgColor ? bgColor : 'transparent'};
  transition: background-color 0.3s ease-in;
  border-top: 2px solid ${props => props.borderColor ? props.borderColor : theme.colors.paleGray};
`;

export const PassengerItem = styled.div`
  cursor: pointer;
`;

export const PassengerItemNameWrapper = styled.div`
  display: flex;
  justify-content: ${({carLabel}) => carLabel ? 'flex-start' : 'space-between'};
  align-items: center;
  padding-right: 10px;
`;

export const PassengerArrowWrapper = styled.div`
  position: relative;
  top: ${({rotateItem}) => rotateItem ? '2px' : '-4px'};
  transform: ${({rotateItem}) => rotateItem ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

export const PassengerItemName = styled.span`
  font-size: ${rem('12px')};
  font-weight: ${theme.font.bolder};
  color: ${theme.colors.darkNavy};
  margin-right: 7px;
`;

export const PassengerItemListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 5px 0 0 0;
`;

export const PassengerItemList = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2px;

  :first-child {
    padding-top: 0;
  }
`;

export const PassengerItemContent = styled.span`
  font-size: ${rem('12px')};
  font-weight: ${({ fontBolder }) => fontBolder ? theme.font.bolder : theme.font.regular};
  color: ${theme.colors.blueyGrey};
  margin-left: 8px;
`;

export const PasssengerItemPhone = styled.a`
  font-size: ${rem('12px')};
  font-weight: ${theme.font.bolder};
  color: ${theme.colors.blueyGrey};
  margin-left: 8px;
  text-decoration: none;
  transition: color .3s ease-in;

  &:hover {
    color: ${theme.colors.apple};
  }
`;

export const PassengerContent = styled(contentConfig)`
  
  > div {
    margin-top: 15px;
  }
`;

export const PassengerIconWrapper = styled(GlobalIconWrapper)``;

export const SharePassengerLocationButton = styled(PrimaryButton)`
  min-height: 30px;
  padding: 8px 13px;
`;
