import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router';
import {
  Wrapper,
  FormContent,
  SubmitButtonWrapper,
  BackToListLink,
  BackToListLabel,
  BackToListIcon,
  BackToListLinkContent,
  CompanySelectWrapper,
  PasswordShowButton
} from './styles';
import {ReactComponent as AddIcon} from 'assets/icons/add_icon.svg';
import {ReactComponent as Check} from 'assets/icons/check.svg'
import {ReactComponent as Arrow} from 'assets/icons/arrowLeft.svg';
import {ReactComponent as EyeShown} from 'assets/icons/eye_shown.svg';
import {ReactComponent as EyeHidden} from 'assets/icons/eye_hidden.svg';
import {routes} from 'config/routes';

import {InputBar, Form, InputLabel, Input, Label, FieldError} from 'styled/Form';
import {PrimaryButton, PrimaryButtonText, PrimaryButtonIcon} from 'styled/Buttons';
import {ButtonLoader} from 'styled/ButtonLoader';
import SimpleSelect from 'components/__common/SimpleSelect';

const CompanyForm = ({
  formValues,
  corps,
  errors,
  onSubmit,
  isFormSubmitting,
  resetErrors,
  isEditForm,
}) => {

  const companyOptions = corps.map(corp => ({ value: corp.id, label: corp.name }));
  const {company_id} = useParams();

  const [passwordShown, setPasswordShown] = useState(false);
  const [form, setForm] = useState({
    email: {value: '', required: true},
    name: {value: '', required: true},
    phone: {value: '', required: false},
    pass: {value: '', required: isEditForm ? false : true},
    company_id: {value: '', required: true},
    team_ids: {value: '', required: false},
  });

  useEffect(() => {
    setForm({
      email: {value: formValues && formValues.email ? formValues.email : '', required: true},
      name: {value: formValues && formValues.name ? formValues.name : '', required: true},
      phone: {value: formValues && formValues.phone ? formValues.phone : '', required: false},
      pass: {value: formValues && formValues.pass ? formValues.pass : '', required: isEditForm ? false : true},
      company_id: {value: formValues && formValues.company_id ? formValues.company_id : '', required: true},
      team_ids: {value: formValues && formValues.team_ids ? formValues.team_ids : '', required: false},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    resetErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const handleFormChange = e => {
    setForm({
      ...form,
      [e.target.name]: {...form[e.target.name], value: e.target.value},
    });
  };

  const handleCompanySelectChange = option => {
    setForm({
      ...form,
      company_id: {...form['company_id'], value: option.value},
    });
  };

  const handleCompanyFormSubmit = e => {
    if (isEditForm) {
      onSubmit(e, form, company_id);
    } else {
      onSubmit(e, form);
    }
  };

  const handleCompanyFormChange = () => {
    resetErrors();
  };

  const setSelectDefaultOption = () => {
    let selectedValue = null;

    companyOptions.forEach(c => {
      if (c.value === form.company_id.value) {
        selectedValue = {
          label: c.label,
          value: c.value
        }
      }
    });

    return selectedValue;
  };

  return (
    <Wrapper>
      <BackToListLink to={routes.admin.nested.companies.url}>
        <BackToListLinkContent>
          <BackToListIcon>
            <Arrow />
          </BackToListIcon>
          <BackToListLabel>Powrót do listy</BackToListLabel>
        </BackToListLinkContent>
      </BackToListLink>
      <FormContent>
        <Form onChange={handleCompanyFormChange} onSubmit={e => handleCompanyFormSubmit(e, form)}>
          <SimpleSelect
            wrapper={CompanySelectWrapper}
            className={'simple-select'}
            placeholder={'Wybierz firmę'}
            isRequired
            error={errors && errors.company_id}
            onChange={handleCompanySelectChange}
            options={companyOptions}
            value={setSelectDefaultOption()} />
          {errors && errors.company_id ? <FieldError>{errors.company_id}</FieldError> : null}
          <InputBar>
            <Input value={form.name.value} error={errors && errors.name} onChange={handleFormChange} type="text" name="name" placeholder=" "/>
            <InputLabel/>
            <Label isRequired htmlFor="name">Imię i nazwisko</Label>
          </InputBar>
          {errors && errors.name ? <FieldError>{errors.name}</FieldError> : null}
          <InputBar>
            <Input value={form.email.value} error={errors && errors.email} onChange={handleFormChange} type="text" name="email" placeholder=" "/>
            <InputLabel/>
            <Label isRequired htmlFor="email">Login</Label>
          </InputBar>
          {errors && errors.email ? <FieldError>{errors.email}</FieldError> : null}
          <InputBar>
            <Input withRightPadding value={form.pass.value} error={errors && errors.pass} onChange={handleFormChange} type={passwordShown ? 'text' : 'password'} name="pass" placeholder=" "/>
            {form.pass.value.length
              ? <PasswordShowButton onClick={() => setPasswordShown(!passwordShown)} type="button">
                  {passwordShown
                    ? <EyeShown />
                    : <EyeHidden />
                  }
                </PasswordShowButton>
              : null
            }
            <InputLabel/>
            <Label isRequired={isEditForm ? false : true} htmlFor="pass">Hasło</Label>
          </InputBar>
          {errors && errors.pass ? <FieldError>{errors.pass}</FieldError> : null}
          <InputBar>
            <Input value={form.phone.value} error={errors && errors.phone} onChange={handleFormChange} type="tel" name="phone" placeholder=" "/>
            <InputLabel/>
            <Label htmlFor="phone">Numer telefonu</Label>
          </InputBar>
          {errors && errors.phone ? <FieldError>{errors.phone}</FieldError> : null}
          <InputBar>
            <Input value={form.team_ids.value} error={errors && errors.team_ids} onChange={handleFormChange} type="text" name="team_ids" placeholder=" "/>
            <InputLabel/>
            <Label htmlFor="team_ids">Identyfikator/y oddziału/ów</Label>
          </InputBar>
          {errors && errors.team_ids ? <FieldError>{errors.team_ids}</FieldError> : null}
          <SubmitButtonWrapper>
            <PrimaryButton type="submit" className={isFormSubmitting ? 'loading' : null} disabled={isFormSubmitting}>
              <PrimaryButtonText>{isFormSubmitting ? 'Zapisuję...' : isEditForm ? 'Zaktualizuj' : 'Utwórz konto'}</PrimaryButtonText>
              <PrimaryButtonIcon>
                {isFormSubmitting ? <ButtonLoader /> : isEditForm ? <Check /> : <AddIcon />}
              </PrimaryButtonIcon>
            </PrimaryButton>
          </SubmitButtonWrapper>
        </Form>
      </FormContent>
    </Wrapper>
  );
};

export default CompanyForm;
