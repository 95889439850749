import styled from "styled-components";
import theme from "config/theme";

export const CarsTopSide = styled.div`
  padding: 24px 20px 16px;
  border-bottom: 1px solid ${theme.colors.paleGrey};
  background-color: ${theme.colors.white};
  min-height: 14.6%;
  @media (max-width: 1023px) {
    padding: 24px 20px;
    min-height: auto;
  }

  @media (max-width: 601px) {
    padding: ${props => props.editModeEnabled ? '0 20px 20px 20px': '20px'};
  }
`;

export const CarsTopSideWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ wide }) => (wide ? "100%" : "auto")};
`;

export const ArrowLeftBackWrapper = styled.div`
  position: relative;
  cursor: pointer;
  margin-left: 24px;

  @media (max-width: 1023px) {
    transform: rotate(-90deg);
  }

  @media (max-width: 601px) {
    display: ${props => props.editModeEnabled ? 'none' : 'block'};
  }

  .prefix__cls-1 {
    stroke: ${theme.colors.darkNavy};
    transition: all 0.3s ease-in;
  }

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 48px;
    min-height: 48px;
  }

  &:hover {
    .prefix__cls-1 {
      stroke: ${theme.colors.apple};
    }
  }
`;

export const CarsFiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  @media (max-width: 1023px) {
    flex-wrap: wrap;
    width: calc(100% - 42px);
  }
`;

export default {
  CarsTopSide,
  CarsFiltersWrapper,
  CarsTopSideWrapper,
  ArrowLeftBackWrapper,
};
