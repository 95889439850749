import styled, {keyframes} from 'styled-components';
import theme from 'config/theme';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const SpinnerWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: calc(50% + 40px);
	transform: translate(-50%, -50%);
	
	@media (max-width: 1023px) {
		left: 50%;
	}
`

export const Spinner = styled.div`
  animation: ${spin} 1s linear infinite;
  border: ${props => props.stroke ? `${props.stroke}px` : '3px'} solid #ddd;
  border-top: ${props => props.stroke ? `${props.stroke}px` : '3px'} solid ${props => props.color ? props.color : theme.colors.apple};
  border-radius: 50%;
  height: ${props => props.height ? `${props.height}px` : '20px'};
  width: ${props => props.width ? `${props.width}px` : '20px'};
`
