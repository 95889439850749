import React, {useState, useEffect} from 'react';
import {MapWrapper} from './styles';

const MapPlatform = ({
  children, 
  apikey,
  isPanelExpanded,
}) => {

  const [platform, setPlatform] = useState(null);
  const [defaultLayers, setDefaultLayers] = useState(null);
  const H = window.H;

  useEffect(() => {
    instantiatePlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apikey]);

  const instantiatePlatform = () => {
    const platform = new H.service.Platform({ apikey });
    const defaultLayers = platform.createDefaultLayers();
    // defaultLayers.vector.normal.map.setMax(10);

    setDefaultLayers(defaultLayers);
    setPlatform(platform);
  }

  const renderChildren = React.Children.map(children, child =>
    React.cloneElement(child, 
      { 
        platform,
        defaultLayers,
        apikey,
        H,
        isPanelExpanded,
      })
  );

  return (
    <MapWrapper isPanelExpanded={isPanelExpanded}>
      {renderChildren}
    </MapWrapper>
  );
}

export default MapPlatform;