import React, { useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import SelectFilter from "../../__common/SelectFilter";
import Search from "../../__common/Search";
import CarsStyles, { CarsFiltersWrapper } from "../Cars/styles";
import { TopSideWrapper } from '../../__common/TopSide/styles';
import { ReactComponent as ArrowLeftBack } from "../../../assets/icons/arrowLeft.svg";
import { withNamespaces } from 'react-i18next';
import Switcher from 'components/__common/Switcher';
import {setRouteLinesShown} from 'store/routes/actions';

const CarsFilters = ({ filterCars, onClick, setFiltersValues, filtersValues, t }) => {

  const optionsType = [
    { value: null, label: t('cars.carTypes.all') },
    { value: "dowoz", label: t('cars.carTypes.shippers') },
    { value: "cel", label: t('cars.carTypes.target') },
    { value: "rozwoz", label: t('cars.carTypes.delivers') },
    { value: "inne", label: t('cars.carTypes.other') },
  ];

  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const routeLinesShown = useSelector(({routes}) => routes.routeLinesShown);

  const d = useDispatch();

  const handleChangeFilter = (name, value) => {

    const newFilters = {
      ...filtersValues,
      [name]: value,
    };

    setFiltersValues(newFilters);

    if (name !== "search") {
      filterCars(newFilters);
    }

    setIsActiveFilter(!isActiveFilter);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    filterCars(filtersValues);
  };

  const onSwitcherChange = () => {
    d(setRouteLinesShown(!routeLinesShown));
  };

  const setDefaultSelectValue = value => {
    let defaultValue;

    optionsType.forEach(option => {
      if (option.value === value) {
        defaultValue = option;
      }
    });

    return defaultValue;
  };

  return (
    <>
      <TopSideWrapper>
        <Search
          inputName={"search"}
          inputValue={filtersValues.search}
          onSubmit={handleSubmit}
          onChange={handleChangeFilter}
          placeholder={t('cars.searchCar')}
        />
        <CarsStyles.ArrowLeftBackWrapper onClick={onClick}>
          <ArrowLeftBack />
        </CarsStyles.ArrowLeftBackWrapper>
      </TopSideWrapper>
      <CarsFiltersWrapper>
        <SelectFilter
          placeholder={t('cars.carTypePlaceholder')}
          name={"type"}
          value={setDefaultSelectValue(filtersValues.type)}
          onChange={handleChangeFilter}
          selectOptions={optionsType}
        />
        <Switcher
          name={'routeFilter'}
          onSwitcherChange={onSwitcherChange}
          checked={routeLinesShown}
          label={t('cars.routeLines')} />
      </CarsFiltersWrapper>
    </>
  );
};

export default withNamespaces()(CarsFilters);
