import React from 'react';
import {
  Wrapper,
  Topside,
  Logo,
  Title,
  NavWrapper,
  Nav,
} from './styles';
import logo from 'assets/img/logo-white.svg';
import {routes} from 'config/routes';
import history from 'config/history';
import AdminSidebarItem from '../AdminSidebarItem';

const AdminSidebar = () => {

  const adminRoutes = routes.admin.nested;
  const path = history.location.pathname;

  const checkActiveMenuTab = link => {
    if (path.startsWith(link)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Wrapper>
      <Topside>
        <Logo src={logo} alt="GTV Bus" />
        <Title>Administracja</Title>
      </Topside>
      <NavWrapper>
        <Nav>
          {
            Object.keys(adminRoutes).map((route, i) => {
              return (
                <AdminSidebarItem 
                  key={i}
                  link={adminRoutes[route].url}
                  name={adminRoutes[route].label}
                  icon={adminRoutes[route].icon}
                  isActiveTab={checkActiveMenuTab(adminRoutes[route].url)} />
              )
            })
          }
        </Nav>
      </NavWrapper>
    </Wrapper>
  );
};

export default AdminSidebar;
