import styled from 'styled-components';
import {rem} from 'polished';
import theme from 'config/theme';

export const Form = styled.form``;

export const InputBar = styled.span`
	position: relative;
	display: block;
	width: 100%;
	
	&:not(:first-child) {
		margin-top: 16px;
	}
`;

export const InputLabel = styled.span`
	position: absolute;
	top: 0;
	left: 10px;
	width: 100%;
	height: 100%;
	pointer-events: none;
	&:before {
		content: '';
		height: 2px;
		width: 0;
		top: 0;
		position: absolute;
		background-color: ${theme.colors.white};
		transition: 0.2s ease all;
		left: 0;
	}
`;

export const Label = styled.label`
	font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('12px')};
	color: ${theme.colors.darkNavy};
	position: absolute;
	pointer-events: none;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	text-align: left;
	line-height: 1;
	margin-left: 16px;
	padding-right: 3px;
	background-color: ${theme.colors.white};
	transition: 0.2s ease all;
	&::after {
		content: '*';
		vertical-align: top;
		color: ${theme.colors.error};
		display: ${props => props.isRequired ? 'inline-block': 'none'};
		margin-left: 2px;
	}
`;

export const Input = styled.input`
	padding: 16px;
	padding-right: ${props => props.withRightPadding ? '40px' : '16px'};
	width: 100%;
	height: 40px;
	border: 1px solid ${props => props.error ? theme.colors.error : theme.colors.veryLightBlue};
	border-radius: 4px;
	font-family: ${theme.font.family};
	font-weight: ${theme.font.regular};
	font-size: ${rem('12px')};
	color: ${theme.colors.darkNavy};
	transition: border-color .2s ease-in;

	&:focus {
		outline: none;
		border-color: ${theme.colors.apple};
	}
	
	&:focus ~ label,
	&:not(:placeholder-shown) ~ label {
		color: ${props => props.error ? theme.colors.error : theme.colors.cloudyBlue};
		font-size: ${rem('10px')};
		top: 0;
	}

	&:focus ~ ${InputLabel}:before,
	&:not(:placeholder-shown) ~ ${InputLabel}:before {
    width: 33px;
  }

	& ~ label {
		color: ${props => props.error ? theme.colors.error : theme.colors.cloudyBlue};
	}
`;

export const FieldError = styled.div`
  font-size: ${rem('10px')};
  color: ${theme.colors.error};
  margin: 4px 0;
`;
