import * as React from "react";

const TransferIcon = ({ color, index }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 25 25">
      <g id="Group_182" data-name="Group 182" transform="translate(-1155 -442)">
        <g id="Group_178" data-name="Group 178" transform="translate(1155 442)">
          <g id="Group_135" data-name="Group 135" transform="translate(0)">
            <g id="Ellipse_68" data-name="Ellipse 68" fill={color ? color : "#fd739e"} stroke="#fbfcfd" strokeWidth="2">
              <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
              <circle cx="12.5" cy="12.5" r="11.5" fill="none"/>
            </g>
          </g>
        </g>
        <text className="new-route-point-number" id="_4" data-name="4" transform="translate(1168 459)" fill="#fff" fontSize="12" fontFamily="Helvetica-Bold, Helvetica" fontWeight="700">{index}</text>
      </g>
    </svg>
  );
};

export default TransferIcon;
