import * as React from "react";

const StartIcon = ({ color }) => {
  return (
    <svg width={20} height={12.853} viewBox="0 0 6.426 12.853">
      <path
        fill={color ? color : "#000"}
        data-name="Path 47"
        d="M6.426 0L0 6.426l6.426 6.426z"
      />
    </svg>
  );
};

export default StartIcon;
