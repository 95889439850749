import * as actions from "./actions";
import API from "api";
import {URL_SETTINGS, URL_BASIC_CONFIG} from 'api/config';
import {prepareInitialValues, prepareErrors} from 'utils/settings';

export const getBasicConfig = () => {
  return async dispatch => {
    dispatch(actions.setBasicConfigLoading(true));
    
    const res = await API.get(URL_BASIC_CONFIG);

    dispatch(actions.setBasicConfigLoading(false));
    if (res && res.success && res.data) {
      dispatch(actions.setBasicConfig(res.data));
    }
  };
};

export const getSettings = () => {
  return async (dispatch) => {
    dispatch(actions.setSettingsLoading(true));
    const res = await API.get(URL_SETTINGS);
    dispatch(actions.setSettingsLoading(false));
    if (res.success && res.data) {
      dispatch(actions.setSettings(res.data.form));
      dispatch(actions.setInitialSettingsValues(prepareInitialValues(res.data.form)));
    }
  };
};

export const saveSettings = formData => {
  return async dispatch => {
    dispatch(actions.setSettingsFormSending(true));
    const res = await API.post(URL_SETTINGS, {
      ...formData,
    });
    dispatch(actions.setSettingsFormSending(false));
    if (res.success) {
      dispatch(actions.setSettings(res.data.form));
    } else {
      dispatch(actions.setSettingsErrors(prepareErrors(res.data.form)));
    }
  }
}