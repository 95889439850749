import React from 'react'
import SidebarStyles from './styles'
import logoWhite from 'assets/img/logo-white.svg'
import SidebarTabsItem from '../SidebarTabsItem'

const Sidebar = ({ activeTabId, clickId, deactivateTab, editModeEnabled = false }) => {
  return (
      <SidebarStyles.Wrapper>
        <SidebarStyles.SidebarWrapper editModeEnabled={editModeEnabled}>
          <img src={logoWhite} alt="GTV Bus"/>

          <SidebarStyles.SidebarTabsWrapper>
            <SidebarTabsItem isActiveTab={activeTabId} isDeactivateTab={deactivateTab} onClick={clickId} />
          </SidebarStyles.SidebarTabsWrapper>

        </SidebarStyles.SidebarWrapper>

      </SidebarStyles.Wrapper>
  )
}

export default Sidebar;