import styled, {css} from "styled-components";
import {rem} from "polished";
import theme from "../../../config/theme";

export const ItemWrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: row;
  background-color: ${(props) =>
  props.status
    ? theme.colors.paleGrey
    : props.type === "route_delay" ? theme.colors.yellow
    : props.type === "no_passenger" ? theme.colors.darkNavy : theme.colors.red};
  border-radius: 10px; 
  margin-bottom: 10px;
  box-shadow: ${(props) =>
  props.shadow ? "0px 0px 15px 0px rgba(0,0,0,0.5)" : null};
  position: relative;
  overflow: hidden;
  

  @media (max-width: 1023px) {
    min-height: auto;
  }
  
  ${({isNotification}) => isNotification && css`
    max-width: 450px;
  `};

  ${({isHover, drivers, status}) =>
  isHover && drivers &&
  css`
  cursor: ${status ? 'default' : 'pointer'};
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.colors.black};
        transform: scaleX(0);
        transform-origin: left center;
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }

      &:hover {
        &:before {
          opacity: 0.05;
          transform: scaleX(1);
        }
      }
    `};
`;

export const Type = styled.div`
  background-color: ${(props) =>
  props.status ? theme.colors.white : `rgba(0,0,0,.08);`};
  color: ${(props) =>
  props.status ? theme.colors.blueyGrey : theme.colors.white};
  padding: ${(props) => props.isNotification ? `5px 3px` : `10px 3px`};
  min-height: ${(props) => props.isNotification ? `40px` : `61px`};
  align-items: center;
  text-align: center;
  border-radius: 10px;
  justify-content: space-between;
  align-content: space-between;
  display: flex;
  flex-direction: column;
  min-width: 40px;
  z-index: 1;

  svg {
  margin-bottom: ${(props) => props.isNotification ? `5px` : `0`};
    g,
    path {
      stroke: ${(props) => props.status ? theme.colors.blueyGrey : theme.colors.white};
      line {
        stroke: ${(props) =>
  props.status ? theme.colors.blueyGrey : theme.colors.white};
      }
    }
  }
`;

export const RouteNumber = styled.div`
  font-size: ${rem("12px")};
  font-weight: bold;
  white-space: nowrap;
`;

export const Text = styled.div`
  margin-left: 20px;
  font-size: ${rem("12px")};
  width: ${(props) => props.isNotification ? `70%` : `100%`};
  justify-content: ${(props) => props.isNotification ? `center` : `flex-start`};
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const Date = styled.div``;

export const Heading = styled.div`
  font-weight: bold;
  font-size: ${rem("12px")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 24px;
  color: ${(props) =>
  props.status ? theme.colors.blueyGrey : theme.colors.white};
`;

export const StatusBox = styled.div`
  background-color: ${(props) =>
  props.status ? theme.colors.apple : theme.colors.white};
  border-radius: 10px;
  padding: 3px 4px;
  margin-left: 10px;
  z-index: 1;
  transition: 0.3s all;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
    path {
      fill: ${(props) =>
  props.status ? theme.colors.white : theme.colors.darkNavy};
    }
  }

  &:hover {
    background-color: ${theme.colors.apple};
    svg {
      path {
        fill: ${theme.colors.white};
      }
    }
  }
`;

export const BottomText = styled.div`
  font-size: ${rem("10px")};
  margin: 5px 0 10px;
  color: ${(props) =>
  props.status ? theme.colors.blueyGrey : theme.colors.white};
  display: flex;
  justify-content: space-between;
`;

export const Links = styled.div`
  background-color: ${theme.colors.darkNavy};
  color: ${theme.colors.white};
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 5px;
`;

export default {
  ItemWrapper,
  Type,
  Text,
  Heading,
  BottomText,
  StatusBox,
  RouteNumber,
  Date,
  Links,
  Buttons,
};
