import React, {useEffect} from 'react';
import {useParams} from 'react-router';

import {useSelector, useDispatch} from 'react-redux';
import {getCorps, updateCompany, getCompanyDetails} from 'store/companies/thunks';
import {setCompanyFormErrors, setCurrentCompany} from 'store/companies/actions';

import {validateForm} from 'utils/form';

import Fade from 'components/__common/Fade';
import CompanyForm from 'components/companies/CompanyForm';
import BoxWrapper from 'components/__common/BoxWrapper';
import PageLoader from 'components/__common/PageLoader';
import BoldHeading from 'components/__common/BoldHeading';

const EditCompany = () => {

  const corps = useSelector(({companies}) => companies.corps);
  const loadingCorps = useSelector(({companies}) => companies.loadingCorps);
  const companyFormSubmitting = useSelector(({companies}) => companies.companyFormSubmitting);
  const companyFormErrors = useSelector(({companies}) => companies.companyFormErrors);
  const currentCompany = useSelector(({companies}) => companies.currentCompany);

  const d = useDispatch();
  const {company_id} = useParams();

  useEffect(() => {
    if (company_id) {
      d(getCompanyDetails(company_id));
    }
    d(getCorps());
    return () => {
      d(setCurrentCompany(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);

  const handleFormSubmit = (e, form, id) => {
    e.preventDefault();
    const validation = validateForm(form);
  
    if (validation.success) {
      d(updateCompany(id, form));
    } else {
      d(setCompanyFormErrors(validation.errors));
    }
  };

  if (loadingCorps) {
    return <PageLoader isFixed={false} />
  }

  return (
    <Fade>
      <BoldHeading text={"Edytuj konto firmy"} />
      <BoxWrapper>
      <CompanyForm
        formValues={currentCompany}
        isFormSubmitting={companyFormSubmitting}
        resetErrors={() => d(setCompanyFormErrors(null))}
        onSubmit={handleFormSubmit}
        errors={companyFormErrors}
        corps={corps}
        isEditForm={true} />
      </BoxWrapper>
    </Fade>
  );
};

export default EditCompany;
