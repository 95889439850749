import styled, { css } from 'styled-components';
import theme from 'config/theme';
import posed from 'react-pose';

const onEnterAnimation = { 
  opacity: 1,
  transition: 200,
  x: 0,
  y: 0,
}

const onLeaveAnimation = { 
  opacity: 0,
  transition: 200,
  x: 0,
  y: 0,
}

export const FadeWrapper = posed.div({
  enter: onEnterAnimation,
  exit: onLeaveAnimation
});

export const ShareIconWrapper = styled.div`
  position: absolute;
  left: 7px;
  top: 5px;
  width: 18px;
  height: 25px;
  .st0 {
    transition: fill .2s ease;
    max-width: 100%;
    fill: #00063c;
  }
`;

export const ShareDropdown = styled.div`
  font-family: ${theme.font.family};
  font-size: 12px;
  background-color: ${theme.colors.veryLightBlue};
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 8px 12px 8px 36px;
  border-radius: 5px;
  color: ${theme.colors.darkNavy};
  display: inline-block;
  transition: background-color .2s ease, color .2s ease;
  &:hover {
    background-color: ${theme.colors.apple};
    color: ${theme.colors.white};
    ${ShareIconWrapper} {
      .st0 {
        fill: white;
      }
    }
  }
`;

export const ShareContent = styled(FadeWrapper)`
  position: ${props => props.reverse ? 'absolute' : 'sticky'};
  width: auto !important;
  min-width: 200px;
  left: ${props => props.reverse ? '15px' : 0};
  top: ${props => props.reverse ? '-55px' : `${props.top}px !important`};
  padding: 15px;
  border-radius: 5px;
  background-color: white;
  color: ${theme.colors.blueyGrey};
  box-shadow: 0 0 22px 0px rgb(0 0 0 / 10%);
  padding: 17px;
  font-size: 12px;
  display: inline-block;
  &:after {
    content: '';
    position: absolute;
    width: 0; 
    height: 0;
    left: 25px;
    ${props => props.reverse ? css`
      top: 100%;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid white;
    ` : css`
      top: -8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid white;
    `};
  }
`;

export const ShareOption = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: ${theme.colors.blueyGrey};
  transition: color .2s ease;
  svg {
    transition: fill .2s ease;
    max-width: 20px;
    margin-right: 10px;
  }
  &:hover {
    text-decoration: underline;
    color: ${theme.colors.apple};
    svg {
      .st0 {
        fill: ${theme.colors.apple};
      }
    }
  }
`;