import React from 'react';
import {Wrapper} from './styles';

const BoxWrapper = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

export default BoxWrapper;
