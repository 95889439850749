import React, {useEffect} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {getCorps, createCompany} from 'store/companies/thunks';
import {setCompanyFormErrors} from 'store/companies/actions';

import {validateForm} from 'utils/form';

import Fade from 'components/__common/Fade';
import CompanyForm from 'components/companies/CompanyForm';
import BoxWrapper from 'components/__common/BoxWrapper';
import PageLoader from 'components/__common/PageLoader';
import BoldHeading from 'components/__common/BoldHeading';

const AddCompany = () => {

  const corps = useSelector(({companies}) => companies.corps);
  const loadingCorps = useSelector(({companies}) => companies.loadingCorps);
  const companyFormSubmitting = useSelector(({companies}) => companies.companyFormSubmitting);
  const companyFormErrors = useSelector(({companies}) => companies.companyFormErrors);

  const d = useDispatch();

  useEffect(() => {
    d(getCorps());
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d]);

  const handleFormSubmit = (e, form) => {
    e.preventDefault();
    const validation = validateForm(form);
    if (validation.success) {
      d(createCompany(form));
    } else {
      d(setCompanyFormErrors(validation.errors));
    }
  };

  if (loadingCorps) {
    return <PageLoader isFixed={false} />
  }

  return (
    <Fade>
      <BoldHeading text={"Tworzenie konta firmy"} />
      <BoxWrapper>
        <CompanyForm
          isFormSubmitting={companyFormSubmitting}
          resetErrors={() => d(setCompanyFormErrors(null))}
          onSubmit={handleFormSubmit}
          errors={companyFormErrors}
          corps={corps}
          isEditForm={false} />
      </BoxWrapper>
    </Fade>
  );
};

export default AddCompany;
