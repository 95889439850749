import React, {useEffect} from "react";
import EventsItemStyles from "./styles";
import {ReactComponent as Clock} from "assets/icons/clock.svg";
import {ReactComponent as RouteChange} from "assets/icons/routeChange.svg";
import {ReactComponent as Check} from "assets/icons/check.svg";
import {ReactComponent as NoPassenger} from "assets/icons/no-passenger.svg";
import {ReactComponent as Phone} from "assets/icons/darkPhone.svg";
import LoadingButton from "../../__common/LoadingButton";
import Fade from "../../__common/Fade";
import {useSelector} from "react-redux";
import {withNamespaces} from 'react-i18next';

const EventsItem = ({
  event,
  isNotification = false,
  withShadow,
  onEventMarkRead,
  onEventMarkNew,
  openSidebar,
  t
}) => {
  const [showButtons, toggleButtons] = React.useState(false);
  const [status, setStatus] = React.useState(event.marked);
  const userName = useSelector(({user}) => user.loggedUser);

  const showResults = () => {
    toggleButtons(!showButtons);
    openSidebar && openSidebar();
  };

  const handleSetStatus = (eventId) => {
    if (status === false) {
      onEventMarkRead(eventId);
    } else {
      onEventMarkNew(eventId);
    }
    toggleButtons(false);
    setStatus(!status);
  };

  useEffect(() => {
    if (event) {
      setStatus(event.marked);
    }
  }, [event]);

  return (
    <EventsItemStyles.ItemWrapper
      type={event.type}
      status={status}
      shadow={withShadow}
      isNotification={isNotification}
      onClick={showResults}
      isHover={!status}
      drivers={event.drivers.length > 0}
    >
      <EventsItemStyles.Type
        type={event.type}
        status={status}
        onClick={showResults}
        isNotification={isNotification}
      >
        {event.type === "route_delay" && <Clock/>}
        {event.type === "route_out" && <RouteChange/>}
        {event.type === "no_passenger" && <NoPassenger/>}
        <EventsItemStyles.RouteNumber>
          {event.car_number}
        </EventsItemStyles.RouteNumber>
      </EventsItemStyles.Type>
      <EventsItemStyles.Text isNotification={isNotification}>
        <EventsItemStyles.Heading status={status}>
          {event.description}
          {!isNotification && (
            <EventsItemStyles.StatusBox
              status={status}
              onClick={() => handleSetStatus(event.id)}
            >
              <Check/>
            </EventsItemStyles.StatusBox>
          )}
        </EventsItemStyles.Heading>
        {!isNotification && (
          <EventsItemStyles.BottomText status={status}>
            <EventsItemStyles.Date>{event.time_str}</EventsItemStyles.Date>
            {status && userName ? userName.name : null}
          </EventsItemStyles.BottomText>
        )}
        {showButtons && !status && !isNotification && event.drivers.length > 0 && (
          event.drivers.map((drivers) => (
              drivers.phones.map((phoneNumber) => (
                  <Fade key={phoneNumber.tel}>
                    <EventsItemStyles.Buttons>
                      <a href={`tel:${phoneNumber.tel}`}>
                        <LoadingButton
                          dark
                          smallButton
                          wide={false}
                          beforeTextIcon={<Phone/>}
                          buttonText={`${t('events.call')} ${phoneNumber.name}`}
                        />
                      </a>
                    </EventsItemStyles.Buttons>
                  </Fade>
                )
              )
            )
          )
        )}
        {/*< LoadingButton*/}
        {/*dark*/}
        {/*smallButton*/}
        {/*wide={false}*/}
        {/*beforeTextIcon={<Message/>}*/}
        {/*buttonText={t('events.message')}*/}
        {/*/>*/}
      </EventsItemStyles.Text>
    </EventsItemStyles.ItemWrapper>
  );
};

export default withNamespaces()(EventsItem);
