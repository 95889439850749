import * as actions from "./actions";
import API from "api";
import { URL_GET_EVENTS } from "../../api/config";
import {formatDate} from 'utils/global';

export const getEvents = () => {
  return async (dispatch, getState) => {
    dispatch(actions.setEventsLoading(true));
    const baseFilters = getState().filters.filters;

    let queryFilters = { 
      date: formatDate(baseFilters.date),
      return: baseFilters.return,
    }
    const response = await API.get(URL_GET_EVENTS, {
      params: {...queryFilters},
    });
    dispatch(actions.setEventsLoading(false));

    if (response.success) {
      dispatch(actions.setEvents(response.data));
    }
  };
};

export const showToastEvent = (event) => {
  return (dispatch, getState) => {
    const timeoutValue = getState().settings.basicConfig.alert_show_time;
    dispatch(actions.addToastEvent(event));
    setTimeout(() => {
      dispatch(actions.removeToastEvent(event.id));
    }, timeoutValue ? timeoutValue * 1000 : 5000);
  };
};
