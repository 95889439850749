export const prepareErrors = settings => {
  let errors = null;
  settings.forEach(section => {
    section.fields.forEach(field => { 
      if (field.error && field.error.length) {
        errors = {
          ...errors, 
          [field.name]: field.error
        };
      }  
    });
  });
  return errors;
};

export const prepareInitialValues = settings => {
  let allFields = null;
  settings.forEach(section => {
    section.fields.forEach(field => {
      allFields = {
        ...allFields, 
        [field.name]: field.value
      };
    });
  });
  return allFields;
};
