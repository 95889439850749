import * as types from './types'

const initialState = {
  cars: [],
  carsPositions: [],
  carsColors: {},
  carsLoading: false,
  companyCars: [],
  companyCarsLoading: false,
  selectedCars: [],
  companyCarsPositions: {},
  companyCarsPositionsLoading: false,
  carsFilters: {
    type: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CARS:
      return {
        ...state,
        cars: action.payload,
      };
    case types.SET_CARS_LOADING:
      return {
        ...state,
        carsLoading: action.payload,
      };
    case types.SET_CARS_POSITIONS:
      return {
        ...state,
        carsPositions: action.payload,
      };
    case types.SET_COMPANY_CARS:
      return {
        ...state,
        companyCars: action.payload,
      };
    case types.SET_COMPANY_CARS_LOADING:
      return {
        ...state,
        companyCarsLoading: action.payload,
      };
    case types.SET_SELECTED_COMPANY_CARS:
      return {
        ...state,
        selectedCars: action.payload,
      };
    case types.SET_COMAPNY_CARS_POSITIONS:
      return {
        ...state,
        companyCarsPositions: action.payload,
      };
    case types.SET_COMAPNY_CARS_POSITIONS_LOADING:
      return {
        ...state,
        companyCarsPositionsLoading: action.payload,
      };
    case types.SET_CARS_FILTERS:
      return {
        ...state,
        carsFilters: action.payload,
      };
    case types.SET_CARS_COLORS:
      return {
        ...state,
        carsColors: action.payload,
      };
    default: 
      return state;
  };
};
