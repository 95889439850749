import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import userReducer from 'store/user';
import routesReducer from 'store/routes';
import carsReducer from 'store/cars';
import filterReducer from 'store/filters';
import eventsReducer from 'store/events';
import settingsReducer from 'store/settings';
import companiesReducer from 'store/companies';
import passengersReducer from 'store/passengers';
import modalReducer from 'store/modal';

export const configureStore = (initialState = {}) => {
  return createStore(
    combineReducers({
      user: userReducer,
      routes: routesReducer,
      cars: carsReducer,
      filters: filterReducer,
      events: eventsReducer,
      settings: settingsReducer,
      companies: companiesReducer,
      passengers: passengersReducer,
      modal: modalReducer,
    }),
    initialState,
    applyMiddleware(thunk)
  )
}