import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SettingsWrapper} from './styles';
import SettingsForm from '../SettingsForm';
import Fade from 'components/__common/Fade';
import PageLoader from 'components/__common/PageLoader';
import {getSettings, saveSettings} from 'store/settings/thunks';
import {setSettingsErrors} from 'store/settings/actions';

const Settings = () => {

  const settings = useSelector(({settings}) => settings.settings);
  const initialSettingsValues = useSelector(({settings}) => settings.initialSettingsValues);
  const settingsErrors = useSelector(({settings}) => settings.settingsErrors);
  const settingsFormSending = useSelector(({settings}) => settings.settingsFormSending);
  const settingsLoading = useSelector(({settings}) => settings.settingsLoading);

  const d = useDispatch();

  useEffect(() => {
    d(getSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (settingsLoading) {
    return <PageLoader isFixed={false} />
  }

  return (
    <Fade>
      <SettingsWrapper>
        <SettingsForm 
          errors={settingsErrors}
          resetErrors={() => d(setSettingsErrors(null))}
          onSubmit={settings => d(saveSettings(settings))}
          isLoading={settingsLoading} 
          form={settings} 
          initialFormValues={initialSettingsValues} 
          isFormSending={settingsFormSending}/>
      </SettingsWrapper>
    </Fade>
  )
}

export default Settings;