import styled, {css} from "styled-components";
import theme from "../../../config/theme";
import {rem} from "polished";

export const SidebarTabItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 48px;
  
  &:not(:first-child) {
    margin-top: 16px;
    @media (max-width: 1023px) {
    margin-top: 0;
    margin-left: 100px;
    }
    
    @media (max-width: 640px) {
    margin-left: 50px;
    }
    
    @media (max-width: 450px) {
    	margin-left: 30px;
    }
    
    @media (max-width: 375px) {
    	margin-left: 20px;
    }
    
    @media (max-width: 320px) {
    	margin-left: 15px;
    }
  }
`;

export const SidebarTab = styled.button`
  position: relative;
	width: 32px;
	height: 32px;
	background-color: ${theme.colors.leafyGreen};
	border-radius: 50%;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	
	&:focus {
		outline: none;
	}
	
	&:before {
	  content: "";
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  background-color: ${theme.colors.white};
	  transform: scale(1.35);
	  border-radius: 50%;
	  opacity: 0;
	  transition: transform .3s ease, opacity .3s ease;
	}
	
	&:after {
	  content: "";
	  position: absolute;
	  top: 0;
	  bottom: 0;
	  left: 50%;
	  transform: translateX(-50%);
	  background-color: transparent;
	  min-width: 48px;
	  min-height: 48px;
	  border-radius: 50%;
	  opacity: 0;
	}
	
	svg {
	  position: relative;
	}
	
	svg path,
	svg circle,
	svg ellipse,
	svg line {
	  fill: none;
	  stroke: ${({ active }) => (active ? theme.colors.leafyGreen : theme.colors.white)};
	  pointer-events: none;
	  transition: .3s ease;
	}
	
	&:hover{
	  
	  &:before {
	    opacity: 1;
	    transform: none;
	  }
	  
	  svg path,
	  svg circle,
	  svg ellipse,
	  svg line {
	    stroke: ${theme.colors.leafyGreen};
	  }
	}
	
	${({ isActive }) => (
		isActive && css`
        &:before {
          transform: scale(1);
          opacity: 1;
        }
        svg path,
        svg circle,
        svg ellipse,
        svg line {
          stroke: ${theme.colors.leafyGreen};
        }
      `
	)}
	
	${({ isDeactivate }) => (
		isDeactivate && css`
        &:before {
          transform: scale(1.35);
          opacity: 0;
        }
        svg path,
        svg circle,
        svg ellipse,
        svg line {
          stroke: ${theme.colors.white};
        }
      `
)}
`;

export const SidebarTabCountMsg = styled.span`
  width: 17px;
  height: 17px;
  display: flex;
	align-items: center;
	justify-content: center;
  background-color: ${theme.colors.mango};
  border-radius: 50%;
  text-align: center;
  font-size: ${rem('10px')};
  font-weight: bold;
  color: ${theme.colors.white};
  position: absolute;
  top: 0px;
  right: -10px;
`;

export const SidebarTabInfo = styled.p`
  font-size: ${rem('10px')};
  font-weight: ${theme.font.regular};
  line-height: 1.2em;
  color: ${theme.colors.white};
  margin: 4px 0 0;
	text-align: center;
`;

export default {
	SidebarTabItem,
	SidebarTab,
	SidebarTabCountMsg,
	SidebarTabInfo,
}