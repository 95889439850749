import React from "react";
import {
  ContentInfoWrap,
  ContentInfoBlock,
  ContentInfoText,
  ContentInfoIconWrap,
} from "./styles";

const ContentInfo = ({ icon, text, href }) => {
  return (
    <ContentInfoWrap>
      <ContentInfoBlock href={href}>
        <ContentInfoIconWrap>{icon}</ContentInfoIconWrap>
        <ContentInfoText>{text}</ContentInfoText>
      </ContentInfoBlock>
    </ContentInfoWrap>
  );
};

export default ContentInfo;
