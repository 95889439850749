import React, {useEffect, useState} from 'react';
import {
  CarItemWrapper,
  CarItem,
  CarItemNameWrapper,
  CarItemName,
  CarPassengersContent,
  CarArrowWrapper,
  CarPassengerWrapper,
  CarPassengerPhone,
  CarItemDescription,
  NameHeading,
  CarItemList,
  CarItemPin,
  InfoWrapper,
  LocationIconWrapper
} from './styles';
import theme from 'config/theme';
import {ReactComponent as ArrowIcon} from 'assets/icons/arrowIcon.svg';
import {ReactComponent as PhoneIcon} from 'assets/icons/phone.svg';
import {ReactComponent as RouteArrowIcon} from "assets/icons/pinmaps.svg";
import {ReactComponent as UserIcon} from "assets/icons/user.svg";
import {ReactComponent as TimerIcon} from 'assets/icons/timer.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/locationIcon.svg';
import {withNamespaces} from 'react-i18next';
import BorderedLabel from "../../__common/BorderedLabel";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedCompanyCars} from "store/cars/actions";
import {generateRandomColor} from 'utils/cars';
import Timer from "components/__common/Timer";
import CarsListStyles from "components/cars/CarsList/styles";


const CompanyCarItem = ({car, t, index, activeEmployeeList}) => {

  const [isOpen, setIsOpen] = useState(false);
  const { selectedCars, companyCarsPositions } = useSelector(({ cars }) => cars);
  const d = useDispatch();

  const handleSelectedCarsArray = (car) => {
    const carIds = selectedCars.map((car) => car.car.id);
    if (!carIds.includes(car.car.id)) {
      d(setSelectedCompanyCars([...selectedCars, car]));
    } else {
      d(setSelectedCompanyCars(selectedCars.filter(selectedCar => selectedCar.car.id !== car.car.id)));
    }
  };

  useEffect(() => {
    if (activeEmployeeList === car.id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [activeEmployeeList, car.id]);

  const handleCarClick = () => {
    const selectedCar = {
      car: car,
      color: generateRandomColor(selectedCars), //generateColor(selectedCars),
    };

    handleSelectedCarsArray(selectedCar);
  };

  const checkIfCarIsSelected = () => {
    let selectedColor = '';
    for (let i = 0; i < selectedCars.length; i++) {
      if (selectedCars[i].car.id === car.id) {
        selectedColor = selectedCars[i].color;
        break;
      }
    }
    return selectedColor;
  };

  const carPositionData = 
    companyCarsPositions &&
    companyCarsPositions[car.id] &&
    companyCarsPositions[car.id].geo_lat !== 0 &&
    companyCarsPositions[car.id].geo_lon !== 0
    ? companyCarsPositions[car.id] : null;

  return (
    <CarItemWrapper bgColor={isOpen}>
      <CarItem>
        <CarItemNameWrapper>
          <NameHeading>
            <CarItemPin bgColor={isOpen} routeColor={checkIfCarIsSelected()} onClick={handleCarClick}>
              <RouteArrowIcon/>
            </CarItemPin>
            <div>
              <CarItemName onClick={() => setIsOpen(!isOpen)}>{car.number}</CarItemName>
              {car && carPositionData && (
                <CarsListStyles.CarPositionLink target="_blank" href={`https://maps.google.com/maps?q=${carPositionData.geo_lat},${carPositionData.geo_lon}`}>
                  <LocationIconWrapper>
                    <LocationIcon />
                    {`${carPositionData.geo_lat}, ${carPositionData.geo_lon}`}
                  </LocationIconWrapper>
                </CarsListStyles.CarPositionLink>
              )}
            </div>
          </NameHeading>
          <NameHeading onClick={() => setIsOpen(!isOpen)}>
            <InfoWrapper>
              <UserIcon/>
              <CarItemDescription>{`${car.items.length} ${car.items.length > 1 ? t('companyCars.workers') : t('companyCars.worker')}`}</CarItemDescription>
            </InfoWrapper>
            {car.items.length > 0 && (
              <CarArrowWrapper rotateItem={isOpen}>
                <ArrowIcon/>
              </CarArrowWrapper>
            )}
          </NameHeading>
        </CarItemNameWrapper>
      </CarItem>
      {car.items.length > 0 && (
        <CarPassengersContent initialPose="closed" pose={isOpen ? 'open' : 'closed'}>
          {car.items.map((passenger, index) => (
            <CarPassengerWrapper 
              carLabel key={index}
              bgColor={passenger.cancelled ? theme.colors.lightEditRed : theme.colors.white}>
              <CarItemName carLabel>
                {passenger.name}
                {passenger.cancelled ? (
                  <BorderedLabel text={t('passenger.passengerMissing')}/>
                ) : null}
              </CarItemName>
              {passenger.phones.length ? (
                <CarItemList>
                  <PhoneIcon/>
                  {passenger.phones.map((phoneNumber, index) => (
                    <CarPassengerPhone key={index} carLabel href={`tel:${phoneNumber.tel}`}>
                      {phoneNumber.name}
                    </CarPassengerPhone>
                  ))}
                </CarItemList>
              ) : null}
              <CarItemList>
                {!passenger.cancelled && passenger.estimated &&
                  <Timer 
                    icon={<TimerIcon />}
                    text={passenger.estimated} />
                }
              </CarItemList>
            </CarPassengerWrapper>
          ))}
        </CarPassengersContent>
      )}
      
    </CarItemWrapper>
  )
};

export default withNamespaces()(CompanyCarItem);
