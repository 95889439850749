import * as types from "./types";

const initialState = {
  routes: [],
  routePointsOnPolyline: [],
  temporaryRoutePointsOnPolyline: [],
  loadingRouteId: null,
  routeLoading: false,
  routeDetails: {},
  tripDetails: [],
  tripColors: [],
  selectedRoute: null,
  editModeEnabled: false,
  sectionPoints: [],
  newRoutePoints: [],
  editAlertVisible: false,
  fetchedNewRoute: null,
  fetchedRouteSaving: false,
  boundToMarkers: false,
  routeLinesShown: true,
  companiesDestinations: [],
  companiesDestinationsLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ROUTES:
      return {
        ...state,
        routes: action.payload,
      };
    case types.SET_LOADING_ROUTE:
      return {
        ...state,
        loadingRouteId: action.payload,
      };
    case types.SET_ROUTE_LOADING:
      return {
        ...state,
        routeLoading: action.payload,
      };
    case types.SET_ROUTE_DETAILS:
      return {
        ...state,
        routeDetails: action.payload,
      };
    case types.SET_TRIP_DETAILS:
      return {
        ...state,
        tripDetails: action.payload,
      };
    case types.SET_TRIP_COLOR_DETAILS:
      return {
        ...state,
        tripColors: action.payload,
      };
    case types.SET_POINTS_ON_POLYLINE:
      return {
        ...state,
        routePointsOnPolyline: action.payload
      }
    case types.SET_TEMPORARY_POINTS_ON_POLYLINE:
      return {
        ...state,
        temporaryRoutePointsOnPolyline: action.payload
      }
    case types.SET_SELECTED_ROUTE:
      return {
        ...state,
        selectedRoute: action.payload,
      }
    case types.SET_EDIT_MODE_ENABLE:
      return {
        ...state,
        editModeEnabled: action.payload,
      }
    case types.SET_SECTION_POINTS:
      return {
        ...state,
        sectionPoints: action.payload,
      }
    case types.SET_NEW_ROUTE_POINTS:
      return {
        ...state,
        newRoutePoints: action.payload,
      }
    case types.SET_EDIT_ALERT_VISIBLE:
      return {
        ...state,
        editAlertVisible: action.payload,
      }
    case types.SET_FETCHED_NEW_ROUTE:
      return {
        ...state,
        fetchedNewRoute: action.payload,
      }
    case types.SET_FETCHED_ROUTE_SAVING:
      return {
        ...state,
        fetchedRouteSaving: action.payload,
      }
    case types.SET_BOUND_TO_MARKERS:
      return {
        ...state,
        boundToMarkers: action.payload,
      }
    case types.SET_ROUTES_LINES_SHOWN:
      return {
        ...state,
        routeLinesShown: action.payload,
      };
    case types.SET_COMPANIES_DESTINATIONS:
      return {
        ...state,
        companiesDestinations: action.payload,
      }
    case types.SET_COMPANIES_DESTINATIONS_LOADING:
      return {
        ...state,
        companiesDestinationsLoading: action.payload,
      }
    default:
      return state;
  };
};
