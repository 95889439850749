import * as types from './types'

export const setWorkers = workers => {
  return {
    type: types.SET_WORKERS,
    payload: workers
  }
}

export const setWorkersLoading = isLoading => {
  return {
    type: types.SET_WORKERS_LOADING,
    payload: isLoading
  }
}