import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FiltersRow, FilterLabel, FilterCol} from './styles';
import ReturnFilter from '../ReturnFilter';
import SelectDate from 'components/__common/SelectDate';
import {setFilters} from 'store/filters/actions';
import {withNamespaces} from 'react-i18next';
import {showModalWithCallback} from 'store/modal/thunks';
import {setSelectedCompanyCars} from 'store/cars/actions';

const MainFilters = ({ setFilterEvent, t }) => {

  const filters = useSelector(({filters}) => filters.filters);
  const editModeEnabled = useSelector(({routes}) => routes.editModeEnabled);

  const d = useDispatch();

	const handleFilterChange = (name, value) => {

		const newFilters = {
			...filters,
			[name]: value
		};

    setFilterEvent(newFilters.date, newFilters.return);
    d(setFilters(newFilters));
    d(setSelectedCompanyCars([]));
  };

  const handleCancelEdit = (name, value) => {
    d(showModalWithCallback(() => {
      handleFilterChange(name, value);
    }));
  };

  return (
    <FiltersRow>
      <FilterCol>
        <FilterLabel>{t('filters.labels.date')}</FilterLabel>
        <SelectDate name={'date'} valueDate={filters.date} onChange={editModeEnabled ? handleCancelEdit : handleFilterChange} />
      </FilterCol>
      <FilterCol>
        <FilterLabel>{t('filters.labels.tripType')}</FilterLabel>
        <ReturnFilter value={filters.return} handleFilterChange={editModeEnabled ? handleCancelEdit : handleFilterChange} />
      </FilterCol>
    </FiltersRow>
  );
};

export default withNamespaces()(MainFilters);
