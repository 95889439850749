import React from 'react';
import {FadeWrapper} from './styles';

const Fade = ({ children }) => {
  return (
    <FadeWrapper initialPose='hidden' pose='visible'>
      { children }
    </FadeWrapper>
  )
}

export default Fade;

