import {colors} from 'config/colors';

export const generateColor = pickedRoutes => {
  let colorIndex = 0;
  const reservedColors = pickedRoutes.map(route => route.color);

  const pickAvailableColor = () => {
    if (colorIndex < colors.length && reservedColors.includes(colors[colorIndex])) {
      colorIndex = colorIndex + 1;
      return pickAvailableColor();
    } else {
      return colors[colorIndex];
    }
  }

  return pickAvailableColor();
};

export const generateRandomColor = pickedRoutes => {
  // const reservedColors = pickedRoutes.map(route => route.color);

  const randColor = () => {
    let rand = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + rand.slice(0, 6);

    // if (reservedColors.includes(color)) {
    //   return randColor();
    // } else {
    //   return color;
    // }
  };

  return randColor();
};
