import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isUser} from 'utils/auth';

const IsLogged = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isUser() ? 
      <Component {...props} /> : <Redirect to={'/map'}/>   
  )} />
);

export default IsLogged;
