export const simpleMarker = (index) => {
  return `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
  <g id="Group_182" data-name="Group 182" transform="translate(-1155 -442)">
    <g id="Group_178" data-name="Group 178" transform="translate(1155 442)">
      <g id="Group_135" data-name="Group 135" transform="translate(0)">
        <g id="Ellipse_68" data-name="Ellipse 68" fill="#fd739e" stroke="#fbfcfd" stroke-width="2">
          <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
          <circle cx="12.5" cy="12.5" r="11.5" fill="none"/>
        </g>
      </g>
    </g>
    <text class="new-route-point-number" id="_4" data-name="4" transform="translate(1168 459)" fill="#fff" font-size="12" font-family="Helvetica-Bold, Helvetica" font-weight="700">${index}</text>
  </g>
</svg>`;
};

export const mapIcons = {
  finishMarker:
    '<svg xmlns="http://www.w3.org/2000/svg" width="16.89" height="25.549" viewBox="0 0 16.89 25.549">' +
    '<g id="Path_39" data-name="Path 39" transform="translate(-6134.707 -324.03)" fill="#fd739e" stroke-linecap="round" stroke-linejoin="round">' +
    '<path d="M 6140.0703125 348.5793762207031 L 6139.0703125 348.5793762207031 L 6136.70654296875 348.5793762207031 L 6135.70654296875 348.5793762207031 L 6135.70654296875 347.5793762207031 L 6135.70654296875 338.2171325683594 L 6135.70654296875 326.0299377441406 L 6135.70654296875 325.0299377441406 L 6136.70654296875 325.0299377441406 L 6149.5966796875 325.0299377441406 L 6152.03759765625 325.0299377441406 L 6150.29833984375 326.7424926757813 L 6145.00390625 331.9554748535156 L 6150.3203125 337.5267944335938 L 6151.93310546875 339.2171325683594 L 6149.5966796875 339.2171325683594 L 6140.0703125 339.2171325683594 L 6140.0703125 347.5793762207031 L 6140.0703125 348.5793762207031 Z" stroke="none"/>' +
    '<path d="M 6136.70654296875 326.0299377441406 L 6136.70654296875 338.2171325683594 L 6136.70654296875 347.5793762207031 L 6139.0703125 347.5793762207031 L 6139.0703125 338.2171325683594 L 6149.5966796875 338.2171325683594 L 6143.60107421875 331.9336242675781 L 6149.5966796875 326.0299377441406 L 6136.70654296875 326.0299377441406 M 6136.70654296875 324.0299377441406 L 6149.5966796875 324.0299377441406 C 6150.408203125 324.0299377441406 6151.1396484375 324.5205688476563 6151.447265625 325.2716979980469 C 6151.7548828125 326.0228576660156 6151.578125 326.885498046875 6151 327.4550170898438 L 6146.4072265625 331.9773559570313 L 6151.04345703125 336.8364562988281 C 6151.59619140625 337.41552734375 6151.75048828125 338.2682800292969 6151.435546875 339.0041198730469 C 6151.12060546875 339.7399291992188 6150.39697265625 340.2171325683594 6149.5966796875 340.2171325683594 L 6141.0703125 340.2171325683594 L 6141.0703125 347.5793762207031 C 6141.0703125 348.6839599609375 6140.1748046875 349.5793762207031 6139.0703125 349.5793762207031 L 6136.70654296875 349.5793762207031 C 6135.60205078125 349.5793762207031 6134.70654296875 348.6839599609375 6134.70654296875 347.5793762207031 L 6134.70654296875 338.2171325683594 L 6134.70654296875 326.0299377441406 C 6134.70654296875 324.9253540039063 6135.60205078125 324.0299377441406 6136.70654296875 324.0299377441406 Z" stroke="none" fill="#fbfcfd"/>' +
    "</g>" +
    "</svg>",
  startMarker:
    '<svg xmlns="http://www.w3.org/2000/svg" width="16.009" height="28.018" viewBox="0 0 16.009 28.018">' +
    '<g id="Path_38" data-name="Path 38" transform="translate(-6114.494 -316.472)" fill="#fd739e" stroke-linecap="round" stroke-linejoin="round">' +
    '<path d="M 6129.50341796875 344.9040222167969 L 6127.79638671875 343.1969909667969 L 6115.787109375 331.1880798339844 L 6115.080078125 330.48095703125 L 6115.787109375 329.7738342285156 L 6127.79638671875 317.7649230957031 L 6129.50341796875 316.0578918457031 L 6129.50341796875 318.4720458984375 L 6129.50341796875 342.4898681640625 L 6129.50341796875 344.9040222167969 Z" stroke="none"/>' +
    '<path d="M 6128.50341796875 318.4720458984375 L 6116.494140625 330.48095703125 L 6128.50341796875 342.4898681640625 L 6128.50341796875 318.4720458984375 M 6128.50390625 316.4719848632813 C 6128.76171875 316.4719848632813 6129.021484375 316.5217895507813 6129.2685546875 316.6242980957031 C 6130.01611328125 316.9338684082031 6130.50341796875 317.6631164550781 6130.50341796875 318.4720458984375 L 6130.50341796875 342.4898681640625 C 6130.50341796875 343.2987976074219 6130.01611328125 344.0280456542969 6129.2685546875 344.3376159667969 C 6129.021484375 344.4401245117188 6128.76171875 344.4899291992188 6128.50390625 344.4899291992188 C 6127.9833984375 344.4899291992188 6127.4716796875 344.2866821289063 6127.08935546875 343.9040832519531 L 6115.080078125 331.8951721191406 C 6114.705078125 331.5201110839844 6114.494140625 331.0113830566406 6114.494140625 330.48095703125 C 6114.494140625 329.9505310058594 6114.705078125 329.4418029785156 6115.080078125 329.0667419433594 L 6127.08935546875 317.0578308105469 C 6127.4716796875 316.6752624511719 6127.9833984375 316.4719848632813 6128.50390625 316.4719848632813 Z" stroke="none" fill="#fbfcfd"/>' +
    "</g>" +
    "</svg>",
  carMarker:
    '<svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">' +
      '<circle id="Ellipse_50" data-name="Ellipse 50" cx="18" cy="18" r="18" fill="#fd739e" opacity="0.4"/>' +
      '<g id="Path_33" data-name="Path 33" transform="translate(-161.033 136.715) rotate(-91)" fill="#fd739e" stroke-linejoin="round">' +
        '<path d="M 104.2112655639648 191.650634765625 L 105.6292343139648 189.2310791015625 L 110.2497024536133 181.3470001220703 L 105.5957565307617 171.9435729980469 L 104.374626159668 169.4762420654297 L 106.8946151733398 170.5846252441406 L 129.3733215332031 180.4716796875 L 131.6321716308594 181.4652252197266 L 129.3189086914062 182.3244781494141 L 106.8401947021484 190.6741180419922 L 104.2112655639648 191.650634765625 Z" stroke="none"/>' +
        '<path stroke="none" fill="white" d="M 106.4920043945312 171.5 L 111.3853073120117 181.3870544433594 L 106.4920043945312 189.7366943359375 L 128.970703125 181.3870544433594 L 106.4920043945312 171.5 M 106.4925384521484 169.4999237060547 C 106.7640838623047 169.4999237060547 107.0379791259766 169.5552215576172 107.2972412109375 169.6692504882812 L 129.7759399414062 179.5563201904297 C 130.5215148925781 179.8842620849609 130.9937438964844 180.631591796875 130.9698486328125 181.4457702636719 C 130.9459228515625 182.2599487304688 130.4306640625 182.978271484375 129.6671142578125 183.2619018554688 L 107.1884002685547 191.6115417480469 C 106.9611053466797 191.6959686279297 106.7255401611328 191.7368011474609 106.4924468994141 191.7368011474609 C 105.9126281738281 191.7368011474609 105.3476715087891 191.4843444824219 104.9599761962891 191.0223388671875 C 104.4163970947266 190.3746032714844 104.3389434814453 189.4550018310547 104.7664947509766 188.7254638671875 L 109.1141128540039 181.3069305419922 L 104.6995239257812 172.3871307373047 C 104.3263702392578 171.6331634521484 104.4649200439453 170.7262573242188 105.0461883544922 170.1181030273438 C 105.4317626953125 169.7146911621094 105.95751953125 169.4999237060547 106.4925384521484 169.4999237060547 Z" />' +
      '</g>' +
    '</svg>',
  carMarkerStopped:
    '<svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="40.119" height="40.119" viewBox="0 0 40.119 40.119">'+
      '<circle id="Ellipse_50" data-name="Ellipse 50" cx="18" cy="18" r="18" transform="translate(0 35.732) rotate(-83)" fill="#B4BBBF" opacity="0.504"/>'+
      '<g id="Rectangle_103" data-name="Rectangle 103" transform="translate(13.06 13.059)" fill="#B4BBBF" stroke="#fbfcfd" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">'+
        '<rect width="14" height="14" stroke="none"/>'+
        '<rect x="-1" y="-1" width="16" height="16" fill="none"/>'+
      '</g>'+
    '</svg>',
  companyCarLabelMarker:
    '<svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="66" height="19" viewBox="0 0 66 19">' +
      '<defs>' +
        '<clipPath id="clip-path">' +
          '<rect id="Rectangle_43" data-name="Rectangle 43" width="66" height="19" rx="4" fill="#fff" opacity="0.8"/>' +
        '</clipPath>' +
      '</defs>' +
      '<g id="Component_3_1" data-name="Component 3 – 1">' +
        '<rect id="Rectangle_42" data-name="Rectangle 42" width="66" height="19" rx="4" fill="#1c214d" opacity="0.8"/>' +
        '<g id="Mask_Group_1" data-name="Mask Group 1">' +
          '<text id="B157" transform="translate(18 12)" fill="#fbfcfd" font-size="9" font-family="Helvetica-Bold, Helvetica" font-weight="700"><tspan x="0" y="0">CAR_LABEL_TEXT</tspan></text>' +
          '<g id="Path_73" data-name="Path 73" transform="translate(6.35 6)" fill="#fd739e">' +
            '<path d="M 3.361755609512329 7.223515510559082 C 1.232375621795654 7.223515510559082 -0.5000044107437134 5.491135597229004 -0.5000044107437134 3.361755609512329 C -0.5000044107437134 1.232375621795654 1.232375621795654 -0.5000044107437134 3.361755609512329 -0.5000044107437134 C 5.491135597229004 -0.5000044107437134 7.223515510559082 1.232375621795654 7.223515510559082 3.361755609512329 C 7.223515510559082 5.491135597229004 5.491135597229004 7.223515510559082 3.361755609512329 7.223515510559082 Z" stroke="none"/>' +
            '<path d="M 3.361755609512329 -4.291534423828125e-06 C 1.505105495452881 -4.291534423828125e-06 -4.291534423828125e-06 1.505105495452881 -4.291534423828125e-06 3.361755609512329 C -4.291534423828125e-06 5.218405723571777 1.505105495452881 6.723515510559082 3.361755609512329 6.723515510559082 C 5.218405723571777 6.723515510559082 6.723515510559082 5.218405723571777 6.723515510559082 3.361755609512329 C 6.723515510559082 1.505105495452881 5.218405723571777 -4.291534423828125e-06 3.361755609512329 -4.291534423828125e-06 M 3.361755609512329 -1.000004291534424 C 5.766835689544678 -1.000004291534424 7.723515510559082 0.9566755294799805 7.723515510559082 3.361755609512329 C 7.723515510559082 5.766835689544678 5.766835689544678 7.723515510559082 3.361755609512329 7.723515510559082 C 0.9566755294799805 7.723515510559082 -1.000004291534424 5.766835689544678 -1.000004291534424 3.361755609512329 C -1.000004291534424 0.9566755294799805 0.9566755294799805 -1.000004291534424 3.361755609512329 -1.000004291534424 Z" stroke="none" fill="#fff"/>' +
          '</g>' +
        '</g>' +
      '</g>' +
    '</svg>',
  companyCarLabelMarkerGray:
    '<svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="68" height="19" viewBox="0 0 68 19">' +
      '<defs>' +
        '<clipPath id="clip-path">' +
          '<rect id="Rectangle_43" data-name="Rectangle 43" width="68" height="19" rx="4" fill="#fff" opacity="0.8"/>' +
        '</clipPath>' +
      '</defs>' +
      '<g id="Component_2_1" data-name="Component 2 – 1">' +
        '<path id="Path_116" data-name="Path 116" d="M6.433,0H61.314c3.553,0,6.433,1.791,6.433,4V15c0,2.209-2.88,4-6.433,4H6.433C2.88,19,0,17.209,0,15V4C0,1.791,2.88,0,6.433,0Z" fill="#969c9f"/>' +
        '<g id="Mask_Group_1" data-name="Mask Group 1">' +
          '<text id="B157" transform="translate(18 13)" fill="#fbfcfd" font-size="10" font-family="Helvetica-Bold, Helvetica" font-weight="700"><tspan x="0" y="0">CAR_LABEL_TEXT</tspan></text>' +
          '<g id="Path_73" data-name="Path 73" transform="translate(5.866 6)" fill="#fd739e">' +
            '<path d="M 3.479404449462891 7.458804607391357 C 1.285154461860657 7.458804607391357 -0.4999955594539642 5.673654556274414 -0.4999955594539642 3.479404449462891 C -0.4999955594539642 1.285154461860657 1.285154461860657 -0.4999955594539642 3.479404449462891 -0.4999955594539642 C 5.673654556274414 -0.4999955594539642 7.458804607391357 1.285154461860657 7.458804607391357 3.479404449462891 C 7.458804607391357 5.673654556274414 5.673654556274414 7.458804607391357 3.479404449462891 7.458804607391357 Z" stroke="none"/>' +
            '<path d="M 3.479404449462891 4.291534423828125e-06 C 1.557784557342529 4.291534423828125e-06 4.291534423828125e-06 1.557784557342529 4.291534423828125e-06 3.479404449462891 C 4.291534423828125e-06 5.401024341583252 1.557784557342529 6.958804607391357 3.479404449462891 6.958804607391357 C 5.401024341583252 6.958804607391357 6.958804607391357 5.401024341583252 6.958804607391357 3.479404449462891 C 6.958804607391357 1.557784557342529 5.401024341583252 4.291534423828125e-06 3.479404449462891 4.291534423828125e-06 M 3.479404449462891 -0.9999957084655762 C 5.94935417175293 -0.9999957084655762 7.958804607391357 1.009454250335693 7.958804607391357 3.479404449462891 C 7.958804607391357 5.94935417175293 5.94935417175293 7.958804607391357 3.479404449462891 7.958804607391357 C 1.009454250335693 7.958804607391357 -0.9999957084655762 5.94935417175293 -0.9999957084655762 3.479404449462891 C -0.9999957084655762 1.009454250335693 1.009454250335693 -0.9999957084655762 3.479404449462891 -0.9999957084655762 Z" stroke="none" fill="#fff"/>' +
          '</g>' +
        '</g>' +
      '</g>' +
    '</svg>',
  companyDestinationMarker:
    '<svg xmlns="http://www.w3.org/2000/svg" width="16.89" height="25.549" viewBox="0 0 16.89 25.549">'+
      '<g id="Path_39" data-name="Path 39" transform="translate(-6134.707 -324.03)" fill="#fd739e" stroke-linecap="round" stroke-linejoin="round">'+
        '<path d="M 6140.0703125 348.5793762207031 L 6139.0703125 348.5793762207031 L 6136.70654296875 348.5793762207031 L 6135.70654296875 348.5793762207031 L 6135.70654296875 347.5793762207031 L 6135.70654296875 338.2171325683594 L 6135.70654296875 326.0299377441406 L 6135.70654296875 325.0299377441406 L 6136.70654296875 325.0299377441406 L 6149.5966796875 325.0299377441406 L 6152.03759765625 325.0299377441406 L 6150.29833984375 326.7424926757812 L 6145.00390625 331.9554748535156 L 6150.3203125 337.5267944335938 L 6151.93310546875 339.2171325683594 L 6149.5966796875 339.2171325683594 L 6140.0703125 339.2171325683594 L 6140.0703125 347.5793762207031 L 6140.0703125 348.5793762207031 Z" stroke="none"/>'+
        '<path d="M 6136.70654296875 326.0299377441406 L 6136.70654296875 338.2171325683594 L 6136.70654296875 347.5793762207031 L 6139.0703125 347.5793762207031 L 6139.0703125 338.2171325683594 L 6149.5966796875 338.2171325683594 L 6143.60107421875 331.9336242675781 L 6149.5966796875 326.0299377441406 L 6136.70654296875 326.0299377441406 M 6136.70654296875 324.0299377441406 L 6149.5966796875 324.0299377441406 C 6150.408203125 324.0299377441406 6151.1396484375 324.5205688476562 6151.447265625 325.2716979980469 C 6151.7548828125 326.0228576660156 6151.578125 326.885498046875 6151 327.4550170898438 L 6146.4072265625 331.9773559570312 L 6151.04345703125 336.8364562988281 C 6151.59619140625 337.41552734375 6151.75048828125 338.2682800292969 6151.435546875 339.0041198730469 C 6151.12060546875 339.7399291992188 6150.39697265625 340.2171325683594 6149.5966796875 340.2171325683594 L 6141.0703125 340.2171325683594 L 6141.0703125 347.5793762207031 C 6141.0703125 348.6839599609375 6140.1748046875 349.5793762207031 6139.0703125 349.5793762207031 L 6136.70654296875 349.5793762207031 C 6135.60205078125 349.5793762207031 6134.70654296875 348.6839599609375 6134.70654296875 347.5793762207031 L 6134.70654296875 338.2171325683594 L 6134.70654296875 326.0299377441406 C 6134.70654296875 324.9253540039062 6135.60205078125 324.0299377441406 6136.70654296875 324.0299377441406 Z" stroke="none" fill="#fbfcfd"/>'+
      '</g>'+
    '</svg>',
  carLabelMarker:
    '<svg id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53" height="19" viewBox="0 0 53 19">' +
      '<defs>' +
        '<clipPath id="clip-path">' +
          '<rect id="Rectangle_43" data-name="Rectangle 43" width="53" height="19" rx="4" fill="#fff" opacity="0.8"/>' +
        '</clipPath>' +
      '</defs>' +
      '<rect id="Rectangle_42" data-name="Rectangle 42" width="53" height="19" rx="4" fill="#1c214d" opacity="0.85"/>' +
      '<g id="Mask_Group_1" data-name="Mask Group 1">' +
        '<text id="B157" transform="translate(21 13)" fill="#fbfcfd" font-size="10" font-family="Helvetica-Bold, Helvetica" font-weight="700"><tspan x="0" y="0">CAR_LABEL_TEXT</tspan></text>' +
        '<g id="Path_73" data-name="Path 73" transform="translate(6.66 6)" fill="#969c9d">' +
          '<path d="M 4 8.5 C 1.51868999004364 8.5 -0.5 6.48130989074707 -0.5 4 C -0.5 1.51868999004364 1.51868999004364 -0.5 4 -0.5 C 6.48130989074707 -0.5 8.5 1.51868999004364 8.5 4 C 8.5 6.48130989074707 6.48130989074707 8.5 4 8.5 Z" stroke="none"/>' +
          '<path d="M 4 0 C 1.790860176086426 0 0 1.790860176086426 0 4 C 0 6.209139823913574 1.790860176086426 8 4 8 C 6.209139823913574 8 8 6.209139823913574 8 4 C 8 1.790860176086426 6.209139823913574 0 4 0 M 4 -1 C 6.757009983062744 -1 9 1.242990016937256 9 4 C 9 6.757009983062744 6.757009983062744 9 4 9 C 1.242990016937256 9 -1 6.757009983062744 -1 4 C -1 1.242990016937256 1.242990016937256 -1 4 -1 Z" stroke="none" fill="#fff"/>' +
        '</g>' +
      '</g>' +
    '</svg>',
  //   '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="89" height="19" viewBox="0 0 89 19">'+
  //   '<defs>'+
  //     '<clipPath id="clip-path">'+
  //       '<rect id="Rectangle_43" data-name="Rectangle 43" width="89" height="19" rx="4" fill="#fff" opacity="0.75"/>'+
  //     '</clipPath>'+
  //   '</defs>'+
  //   '<g id="Group_156" data-name="Group 156" transform="translate(-493 -46)">'+
  //     '<rect id="Rectangle_42" data-name="Rectangle 42" width="89" height="19" rx="4" transform="translate(493 46)" fill="#1c214d" opacity="0.85"/>'+
  //     '<g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(493 46)">'+
  //       '<text id="B157_Opel_KR_" data-name="B157 Opel KR " transform="translate(19 13)" fill="#fbfcfd" font-size="9" font-family="Helvetica-Bold, Helvetica" font-weight="700"><tspan x="0" y="0">CAR_LABEL_TEXT</tspan></text>'+
  //       '<g id="Path_73" data-name="Path 73" transform="translate(5.485 5)" fill="#fd739e">'+
  //         '<path d="M 3.981067419052124 8.462137222290039 C 1.510197401046753 8.462137222290039 -0.5000025629997253 6.451937198638916 -0.5000025629997253 3.981067419052124 C -0.5000025629997253 1.510197401046753 1.510197401046753 -0.5000025629997253 3.981067419052124 -0.5000025629997253 C 6.451937198638916 -0.5000025629997253 8.462137222290039 1.510197401046753 8.462137222290039 3.981067419052124 C 8.462137222290039 6.451937198638916 6.451937198638916 8.462137222290039 3.981067419052124 8.462137222290039 Z" stroke="none"/>'+
  //         '<path d="M 3.981067419052124 -2.384185791015625e-06 C 1.782387256622314 -2.384185791015625e-06 -2.384185791015625e-06 1.782387256622314 -2.384185791015625e-06 3.981067419052124 C -2.384185791015625e-06 6.179747581481934 1.782387256622314 7.962137222290039 3.981067419052124 7.962137222290039 C 6.179747581481934 7.962137222290039 7.962137222290039 6.179747581481934 7.962137222290039 3.981067419052124 C 7.962137222290039 1.782387256622314 6.179747581481934 -2.384185791015625e-06 3.981067419052124 -2.384185791015625e-06 M 3.981067419052124 -1.000002384185791 C 6.72763729095459 -1.000002384185791 8.962137222290039 1.234497547149658 8.962137222290039 3.981067419052124 C 8.962137222290039 6.72763729095459 6.72763729095459 8.962137222290039 3.981067419052124 8.962137222290039 C 1.234497547149658 8.962137222290039 -1.000002384185791 6.72763729095459 -1.000002384185791 3.981067419052124 C -1.000002384185791 1.234497547149658 1.234497547149658 -1.000002384185791 3.981067419052124 -1.000002384185791 Z" stroke="none" fill="#fff"/>'+
  //       '</g>'+
  //     '</g>'+
  //   '</g>'+
  // '</svg>',
  carLabelMarkerWithPhone:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="65" height="19" viewBox="0 0 65 19">' +
    '<defs>' +
      '<clipPath id="clip-path">' +
        '<rect id="Rectangle_43" data-name="Rectangle 43" width="65" height="19" rx="4" transform="translate(493 52)" fill="#fff" opacity="0.8"/>' +
      '</clipPath>' +
    '</defs>' +
    '<g id="Group_159" data-name="Group 159" transform="translate(-493 -46)">' +
      '<rect id="Rectangle_42" data-name="Rectangle 42" width="65" height="19" rx="4" transform="translate(493 46)" fill="#1c214d" opacity="0.85"/>' +
      '<g id="Mask_Group_1" data-name="Mask Group 1" transform="translate(0 -6)">' +
        '<text id="B157" transform="translate(527 66)" fill="#fbfcfd" font-size="10" font-family="Helvetica-Bold, Helvetica" font-weight="700"><tspan x="0" y="0">CAR_LABEL_TEXT</tspan></text>' +
        '<g id="Path_73" data-name="Path 73" transform="translate(511.563 58)" fill="#213ed9">' +
          '<path d="M 4 8.5 C 1.51868999004364 8.5 -0.5 6.48130989074707 -0.5 4 C -0.5 1.51868999004364 1.51868999004364 -0.5 4 -0.5 C 6.48130989074707 -0.5 8.5 1.51868999004364 8.5 4 C 8.5 6.48130989074707 6.48130989074707 8.5 4 8.5 Z" stroke="none"/>' +
          '<path d="M 4 0 C 1.790860176086426 0 0 1.790860176086426 0 4 C 0 6.209139823913574 1.790860176086426 8 4 8 C 6.209139823913574 8 8 6.209139823913574 8 4 C 8 1.790860176086426 6.209139823913574 0 4 0 M 4 -1 C 6.757009983062744 -1 9 1.242990016937256 9 4 C 9 6.757009983062744 6.757009983062744 9 4 9 C 1.242990016937256 9 -1 6.757009983062744 -1 4 C -1 1.242990016937256 1.242990016937256 -1 4 -1 Z" stroke="none" fill="#fff"/>' +
        '</g>' +
        '<g id="Group_39" data-name="Group 39" transform="translate(498.51 55.096)">' +
          '<g id="Group_38" data-name="Group 38" transform="translate(2.486 2.904)">' +
            '<path id="Path_25" data-name="Path 25" d="M1484.369,1579.708a.489.489,0,0,0-.49-.487h-3.389a.489.489,0,0,0-.49.487v6.883a.489.489,0,0,0,.49.487h3.389a.489.489,0,0,0,.49-.487v-6.883" transform="translate(-1480 -1579.221)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.75"/>' +
            '<line id="Line_23" data-name="Line 23" x1="4.369" transform="translate(0 6.088)" fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="0.75"/>' +
          '</g>' +
          '<g id="Group_251" data-name="Group 251" transform="translate(2.503 -0.277) rotate(60)">' +
            '<path id="Path_107" data-name="Path 107" d="M.493.067A.972.972,0,0,0,.144.831a1.051,1.051,0,0,0,.367.78" transform="translate(1.44 0.676)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="0.75"/>' +
            '<path id="Path_108" data-name="Path 108" d="M.918.124a1.807,1.807,0,0,0-.65,1.421A1.954,1.954,0,0,0,.95,3" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="0.75"/>' +
          '</g>' +
        '</g>' +
      '</g>' +
    '</g>' +
  '</svg>',
  selectedDot:
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">'+
      '<g id="Ellipse_71" data-name="Ellipse 71" fill="#fd739e" stroke="#fff" stroke-width="1">'+
        '<circle cx="8" cy="8" r="8" stroke="none"/>'+
        '<circle cx="8" cy="8" r="7.5" fill="none"/>'+
      '</g>'+
    '</svg>',
  sectionDot:
    '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">'+
      '<g id="Ellipse_69" data-name="Ellipse 69" fill="#fff" stroke="#00063c" stroke-width="2">'+
        '<circle cx="6" cy="6" r="6" stroke="none"/>'+
        '<circle cx="6" cy="6" r="5" fill="none"/>'+
      '</g>'+
    '</svg>',
};

export const noGpsCarMarkerLabel = text => {
  return (
    `<svg id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53" height="19" viewBox="0 0 53 19">
      <defs>
      <clipPath id="clip-path">
      <rect id="Rectangle_43" data-name="Rectangle 43" width="53" height="19" rx="4" fill="#fff" opacity="0.8"/>
      </clipPath>
      </defs>
      <rect id="Rectangle_42" data-name="Rectangle 42" width="53" height="19" rx="4" fill="#969c9f" opacity="0.85"/>
      <g id="Mask_Group_1" data-name="Mask Group 1">
      <text id="B157" transform="translate(21 13)" fill="#fbfcfd" font-size="10" font-family="Helvetica-Bold, Helvetica" font-weight="700"><tspan x="0" y="0">${text}</tspan></text>
      <g id="Path_73" data-name="Path 73" transform="translate(6.66 6)" fill="#969c9d">
      <path d="M 4 8.5 C 1.51868999004364 8.5 -0.5 6.48130989074707 -0.5 4 C -0.5 1.51868999004364 1.51868999004364 -0.5 4 -0.5 C 6.48130989074707 -0.5 8.5 1.51868999004364 8.5 4 C 8.5 6.48130989074707 6.48130989074707 8.5 4 8.5 Z" stroke="none"/>
      <path d="M 4 0 C 1.790860176086426 0 0 1.790860176086426 0 4 C 0 6.209139823913574 1.790860176086426 8 4 8 C 6.209139823913574 8 8 6.209139823913574 8 4 C 8 1.790860176086426 6.209139823913574 0 4 0 M 4 -1 C 6.757009983062744 -1 9 1.242990016937256 9 4 C 9 6.757009983062744 6.757009983062744 9 4 9 C 1.242990016937256 9 -1 6.757009983062744 -1 4 C -1 1.242990016937256 1.242990016937256 -1 4 -1 Z" stroke="none" fill="#fff"/>
      </g>
      </g>
    </svg>`
  );
};

export const newCreatedDot = index => {
  return (
    `<svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Path_122" data-name="Path 122" fill="#fff">
        <path d="M 12 23 C 9.061790466308594 23 6.299449920654297 21.85580062866211 4.221819877624512 19.77817916870117 C 2.144200086593628 17.7005500793457 1 14.93820953369141 1 12 C 1 9.061790466308594 2.144200086593628 6.299449920654297 4.221819877624512 4.221819877624512 C 6.299449920654297 2.144200086593628 9.061790466308594 1 12 1 C 14.93820953369141 1 17.7005500793457 2.144200086593628 19.77817916870117 4.221819877624512 C 21.85580062866211 6.299449920654297 23 9.061790466308594 23 12 C 23 14.93820953369141 21.85580062866211 17.7005500793457 19.77817916870117 19.77817916870117 C 17.7005500793457 21.85580062866211 14.93820953369141 23 12 23 Z" stroke="none"/>
        <path d="M 12 2 C 6.485979080200195 2 2 6.485979080200195 2 12 C 2 17.51401901245117 6.485979080200195 22 12 22 C 17.51401901245117 22 22 17.51401901245117 22 12 C 22 6.485979080200195 17.51401901245117 2 12 2 M 12 0 C 18.62742042541504 0 24 5.372579574584961 24 12 C 24 18.62742042541504 18.62742042541504 24 12 24 C 5.372579574584961 24 0 18.62742042541504 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z" stroke="none" fill="#00063c"/>
      </g>
      <text class="new-route-point-number" id="_1" data-name="1" transform="translate(12 16)" fill="#00063c" font-size="11" font-family="Helvetica-Bold, Helvetica" font-weight="700">${index}</text>
    </svg>
    `
  );
};
