import React, {useState, useEffect, useRef} from 'react';
import shortid from 'shortid';
import {mapIcons, noGpsCarMarkerLabel} from 'config/map';

const MapContent = ({
  children,
  H,
  defaultLayers,
  center,
  onMapClick,
  isPanelExpanded,
  companiesDestinations = null,
  clusterData,
  colors,
  shouldCluster,
}) => {
  const [map, setMap] = useState(null);
  const [behavior, setBehavior] = useState(null);
  const [ui, setUi] = useState(null);
  const [mainGroup, setMainGroup] = useState(null);
  const [clusterLayer, setClusterLayer] = useState(null);

  const mapRef = useRef(null);

  const startClustering = data => {
    const dataPoints = data.map(item => {
      return new H.clustering.DataPoint(item.geo_lat, item.geo_lon, null, item);
    });

    const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 30,
        minWeight: 2
      },
      theme: CUSTOM_THEME,
    });
    const clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
    if (map) {
      map.removeLayer(clusterLayer);
    }
    map.addLayer(clusteringLayer);
    setClusterLayer(clusteringLayer);
  };

  useEffect(() => {
    if (companiesDestinations && companiesDestinations.length && mainGroup && mainGroup.getObjects().length) {
      const bbox = mainGroup.getBoundingBox();
      const mainGroupLength = mainGroup && mainGroup.getObjects().length;
      if (bbox && mainGroup && mainGroupLength) {
        if (mainGroupLength <= 2) {
          map.getViewModel().setLookAtData({
            bounds: bbox,
            zoom: 10,
          });
        } else {
          map.getViewModel().setLookAtData({
            bounds: bbox,
          });
        }
      } else {
        map.setCenter({
          lat: 52.152366,
          lng: 19.34208,
        });
        map.setZoom(7);
      }
    }
  }, [map, mainGroup, companiesDestinations]);

  useEffect(() => {
    if (map) {
      if (isPanelExpanded) {
        map.getViewPort().setPadding(150, 150, 100, 540);
      } else {
        map.getViewPort().setPadding(150, 150, 100, 100);
      }
    }
  }, [map, isPanelExpanded]);

  useEffect(() => {
    if (map && shouldCluster) {
      startClustering(clusterData);
    }
    return () => {
      if (map) {
        map.removeLayer(clusterLayer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, clusterData, colors, shouldCluster]);

  useEffect(() => {
    if (defaultLayers) instantiateMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLayers]);

  useEffect(() => {
    function onMapReady() {
      map.getViewPort().resize();
    }

    function onMapPress(evt) {
      if (
        onMapClick &&
        evt.target instanceof H.map.Polyline === false &&
        evt.target instanceof H.map.Marker === false &&
        evt.target instanceof H.map.DomMarker === false
      ) {
        const coord = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
        onMapClick({
          id: shortid.generate(),
          latitude: String(coord.lat),
          longitude: String(coord.lng),
        });
      }
    }

    if (map) {
      window.addEventListener('resize', onMapReady);
      map.addEventListener('tap', onMapPress);
    }

    return () => {
      if (map) {
        window.removeEventListener('resize', onMapReady);
        map.removeEventListener('tap', onMapPress);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, onMapClick]);

  const handleGroupChange = (group, shouldBound) => {
    setMainGroup(group);

    if (shouldBound) {
      const bbox = group.getBoundingBox();
      const mainGroupLength = mainGroup && mainGroup.getObjects().length;

      if (bbox && group && mainGroupLength) {
        if (mainGroupLength <= 2) {
          map.getViewModel().setLookAtData({
            bounds: bbox,
            zoom: 10,
          });
        } else {
          map.getViewModel().setLookAtData({
            bounds: bbox,
          });
        }
      } else {
        map.setCenter({
          lat: 52.152366,
          lng: 19.34208,
        });
        map.setZoom(7);
      }
    }
  };

  const instantiateMap = () => {
    const mapInstance = new H.Map(
      mapRef.current,
      defaultLayers.vector.normal.map,
      {
        center: center ? center : { lat: 52.157071, lng: 19.502911 },
        zoom: 7,
        pixelRatio: window.devicePixelRatio || 1
      }
    );
    const ui = H.ui.UI.createDefault(mapInstance, defaultLayers);
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance));
    const group = new H.map.Group({zIndex: 2});

    mapInstance.addObject(group);

    const settings = ui.getControl('mapsettings');
    if (settings) {
      settings.setVisibility(false);
    }

    setBehavior(behavior);
    setMap(mapInstance);
    setUi(ui);
    setMainGroup(group);
  }

  const CUSTOM_THEME = {
    getClusterPresentation: function(cluster) {
      // Get random DataPoint from our cluster
      const randomDataPoint = getRandomDataPoint(cluster);
      const labelsGroup = new H.map.Group({zIndex: 3});
      let i = -1;

      cluster.forEachDataPoint(point => {
        const data = point.getData();
        let markerIcon;

        if (data.navi_type === 'phone') {
          if (data.geo_error) {
            markerIcon = noGpsCarMarkerLabel(data && data.car_number ? data.car_number.substring(0, 15) : '-');
          } else {
            markerIcon = mapIcons.carLabelMarkerWithPhone;
          }
        } else {
          if (data.geo_error) {
            markerIcon = noGpsCarMarkerLabel(data && data.car_number ? data.car_number.substring(0, 15) : '-');
          } else {
            markerIcon = mapIcons.carLabelMarker;
          }
        }

        let coloredIcon = markerIcon.replace('CAR_LABEL_TEXT', data.car_number);
        const selectedColor = colors[data.car_id];
        const domIconElement = document.createElement("div");

        if (selectedColor) {
          coloredIcon = coloredIcon.replaceAll("#969c9d", `${selectedColor}`);
        }
        domIconElement.innerHTML = `${coloredIcon}`;

        let pixelsFromTop = (i * 20) + 10;
        const icon = new H.map.DomIcon(domIconElement, {
          onAttach: function (clonedElement, domIcon, domMarker) {
            const clonedContent = clonedElement.getElementsByTagName("svg")[0];
            clonedContent.style.position = 'absolute';

            clonedContent.style.left = '22px';
            clonedContent.style.bottom = `${pixelsFromTop}`;
          },
        });

        const marker = new H.map.DomMarker(
          randomDataPoint.getPosition(),
          {
            icon: icon,
            min: cluster.getMinZoom(),
            max: cluster.getMaxZoom(),
          }
        );

        labelsGroup.addObject(marker);
        i = i - 1;
      });

      return labelsGroup;
    },
    getNoisePresentation: function (noisePoint) {
      // Get a reference to data object our noise points
      const data = noisePoint.getData();
      let markerIcon;

      if (data.navi_type === 'phone') {
        if (data.geo_error) {
          markerIcon = noGpsCarMarkerLabel(data && data.car_number ? data.car_number.substring(0, 15) : '-');
        } else {
          markerIcon = mapIcons.carLabelMarkerWithPhone;
        }
      } else {
        if (data.geo_error) {
          markerIcon = noGpsCarMarkerLabel(data && data.car_number ? data.car_number.substring(0, 15) : '-');
        } else {
          markerIcon = mapIcons.carLabelMarker;
        }
      }

      const domIconElement = document.createElement("div");
      const selectedColor = colors[data.car_id];
      let coloredIcon = markerIcon.replace('CAR_LABEL_TEXT', data.car_number);

      if (selectedColor) {
        coloredIcon = coloredIcon.replaceAll("#969c9d", `${selectedColor}`);
      }
      domIconElement.innerHTML = `${coloredIcon}`;

      const icon = new H.map.DomIcon(domIconElement, {
        onAttach: function (clonedElement, domIcon, domMarker) {
          const clonedContent = clonedElement.getElementsByTagName("svg")[0];
          clonedContent.style.position = 'absolute';

          clonedContent.style.left = '22px';
          clonedContent.style.top = '-11px';
        },
      });

      const marker = new H.map.DomMarker(
        {
          lat: data.geo_lat,
          lng: data.geo_lon,
        },
        {
          icon: icon,
          min: noisePoint.getMinZoom(),
        }
      );

      return marker;
    }
  };

  const getRandomDataPoint = cluster => {
    let dataPoints = [];

    // Iterate through all points which fall into the cluster and store references to them
    cluster.forEachDataPoint(dataPoints.push.bind(dataPoints));

    // Randomly pick an index from [0, dataPoints.length) range
    // Note how we use bitwise OR ("|") operator for that instead of Math.floor
    return dataPoints[Math.random() * dataPoints.length | 0];
  }

  const renderChildren = React.Children.map(children, child =>
    React.cloneElement(child,
      {
        H,
        map,
        behavior,
        ui,
        isPanelExpanded,
        mainGroup,
        handleGroupChange,
      })
  );

  return (
    <div className="map-content" ref={mapRef} style={{ height: "100%", width: "100%"}}>
      {renderChildren}
    </div>
  );
}

export default MapContent;
