import React, {useEffect, useRef, useState} from 'react'
import {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  ArrowContainer,
  DropDownText,
  BoldText
} from './styles'
import {ReactComponent as ArrowDown} from 'assets/icons/arrowDown.svg'
import {withNamespaces} from 'react-i18next';
import i18n from "../../../i18n";
import {ReactComponent as FlagPL} from 'assets/icons/flags/pl.svg'
import {ReactComponent as FlagEN} from 'assets/icons/flags/en.svg'
import {ReactComponent as FlagRO} from 'assets/icons/flags/ro.svg'
import {ReactComponent as FlagNL} from 'assets/icons/flags/nl.svg';
import {ReactComponent as FlagUA} from 'assets/icons/flags/ua.svg';
import {ReactComponent as FlagDE} from 'assets/icons/flags/de.svg';

const SelectLanguage = ({isSearchable = false, onChange, name, withChangeText, t}) => {

  const getLanguage = () => i18n.language;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(`${getLanguage()}`);
  const myRef = useRef();

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const languagesOptions = [
    {value: "pl", label: t('languages.pl')},
    {value: "en", label: t('languages.en')},
    {value: "ro", label: t('languages.ro')},
    {value: "nl", label: t('languages.nl')},
    {value: "ua", label: t('languages.ua')},
    {value: "de", label: t('languages.de')},
  ];

  const returnProperLanguageName = (locale) => {
    switch (locale) {
      case 'pl':
        return t('languages.pl');
      case 'ro':
        return t('languages.ro');
      case 'en':
        return t('languages.en');
      case 'nl':
        return t('languages.nl');
      case 'ua':
        return t('languages.ua');
      case 'de':
        return t('languages.de');
      default:
        return t('languages.pl');
    }
  };

  const returnProperLanguageFlag = (locale) => {
    switch (locale) {
      case 'pl':
        return <FlagPL/>;
      case 'ro':
        return <FlagRO/>;
      case 'en':
        return <FlagEN/>;
      case 'nl':
        return <FlagNL/>;
      case 'ua':
        return <FlagUA/>;
      case 'de':
        return <FlagDE/>;
      default:
        return <FlagPL/>;
    }
  };

  const toggleSelect = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <DropDownContainer ref={myRef} onClick={toggleSelect} withChangeText={withChangeText}>
      <DropDownHeader>
        {returnProperLanguageFlag(selectedOption)}
        <DropDownText withChangeText={withChangeText}>
          {i18n.language !== 'en' && !withChangeText && `${t('languages.language')}`}
          <BoldText>
            {returnProperLanguageName(selectedOption)}
          </BoldText>
          {i18n.language === 'en' && !withChangeText && `${t('languages.language')}`}
        </DropDownText>
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {languagesOptions.map((item, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  handleChangeLanguage(item.value);
                  onOptionClicked(item.value);
                }}>
                {returnProperLanguageFlag(item.value)}
                {returnProperLanguageName(item.value)}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
      <ArrowContainer>{withChangeText ? t('languages.change') : <ArrowDown/>}</ArrowContainer>
    </DropDownContainer>
  )
};

export default withNamespaces()(SelectLanguage);
