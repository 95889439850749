import React from 'react';
import {
  ItemLink,
  IconWrapper,
  Label
} from './styles';

const AdminSidebarItem = ({ name, icon, link, isActiveTab }) => {
  return (
    <ItemLink to={link}>
      <IconWrapper isActiveTab={isActiveTab}>
        {icon}
      </IconWrapper>
      <Label isActiveTab={isActiveTab}>{name}</Label>
    </ItemLink>
  );
};

export default AdminSidebarItem;
