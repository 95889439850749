import React from 'react';
import { TimerWrapper, GlobalIconWrapper, TimerTextContent } from './styles';

const Timer = ({icon, text}) => {
  return (
    <TimerWrapper>
      <GlobalIconWrapper>{icon}</GlobalIconWrapper>
      <TimerTextContent>{text}</TimerTextContent>
    </TimerWrapper>
  );
};

export default Timer;
