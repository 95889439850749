export default {
  "login": {
    "logoAlt": "GTV Bus",
      "header": "Einloggen",
      "companyHeader": "Einloggen",
      "loginForm": "Login",
      "loginFormPassword": "Passwort",
      "forgotPassword": "Wenn Sie das Passwort vergessen haben, kontaktieren Sie den Administrator",
      "loginFormButton": "Einloggen",
      "logging": "Einloggen…",
      "verify": "Überprüfung...",
      "helpText": "Brauchen Sie Hilfe? Rufen Sie uns an",
      "loading": "Wird geladen…"
  },
  "languages": {
    "language": "Sprache",
      "pl": "Polski",
      "en": "English",
      "ro": "Română",
      "nl": "Nederlands",
      "ua": "Український",
      "de": "Deutsche",
      "change": "Ändern"
  },
  "database": {
    "name": "Datenbank",
      "pl": "Polnisch",
      "nl": "Niederländisch",
      "ro": "Rumänisch",
      "pl2": ' Autko 2.0',
      "undefined": ' niezdefiniowana'
  },
  "route": {
    "editRoute": "Route bearbeiten",
      "cancelEdit": "Bearbeitung abbrechen",
      "saveChanges": "Änderungen speichern",
      "savingChanges": "Speicherung…",
      "editAlert1": "Zu bearbeitenden Routenabschnitt markieren",
      "editAlert2": "durch Auswahl von zwei Punkten",
      "editAlert3": "auf der Strecke und dann",
      "editAlert4": "Umwegspunkte festlegen",
      "editAlert5": "für diesen Routenabschnitt",
      "taskCancelled": "Abgebrochen",
      "removePoint": "Punkt löschen",
      "additionalPoint": "Umwegspunkt"
  },
  "confirmModal": {
    "confirmButton": "Ja, abbrechen",
      "cancelButton": "Nein, ich möchte die Bearbeitung abschließen",
      "mainText": "Die Routenänderungen wurden nicht gespeichert und werden gelöscht",
      "boldText": "Möchten Sie dennoch abbrechen?"
  },
  "filters": {
    "all": "Alle",
    "departure": "Abfahrt",
    "back": "Rückreise",
    "labels": {
      "departureDate": "Abreisedatum",
      "date": "Datum",
      "tripType": "Routentyp",
      "team": "Filiale-ID"
    },
    "allPassengers": "Alle",
    "presentPassengers": "Anwesend",
    "refreshData": "Daten aktualisieren"
  },
  "tabs": {
  "info": "Infos",
    "task": "Aufgabe"
},
  "sidebar": {
  "vehicles": "Fahrzeuge",
    "drivers": "Fahrer",
    "passengers": "Passagiere",
    "workers": "Mitarbeiter",
    "events": "Vorgänge",
    "chat": "Chat"
},
  "cars": {
    "searchCar": "Fahrzeug finden",
    "noCarsFound": "Keine Fahrzeuge für diese Kriterien gefunden!",
    "carTypePlaceholder": "Art.",
    "carTypes": {
      "all": "Alle",
      "target": "Ziel",
      "delivers": "Anlieferung",
      "shippers": "Auslieferung",
      "other": "Andere"
    },
    "drivers": "Fahrer",
    "trip": "Route",
    "tripTypes": {
      "target": "Ziel",
      "delivers": "Anlieferung",
      "shippers": "Auslieferung",
      "other": "Anders"
    },
    "vehicleTrip": "Route des Fahrzeugs",
    "tripNotPlanned": "Die Route wurde noch nicht geplant",
    "write": "Schreiben",
    "backToCarsList": "Zurück zur Fahrzeugliste",
    "noGpsSignal": "Kein GPS-Signal",
    "routeLines": "Routen",
    "personIn": 'Sammlung',
    "personOut": 'Ausschiffung'
  },
  "events": {
    "eventsHeading": "Vorgangsliste",
    "noEventsMessage": "Keine Vorgänge gefunden!",
    "call": "Anrufen",
    "message": "Schreiben"
  },
  "passenger": {
    "searchWorker": "Mitarbeiter suchen",
    "car": "Fahrzeug",
    "fromTo": "Zielort",
    "notFound": "Keine Mitarbeiter für diese Kriterien gefunden!",
    "passengerMissing": "Nicht gefahren",
    "passengerCancelled": "Verzichten",
    "start": "Start",
    "vehicleList": "Fahrzeuge",
    "sharePassengersLocations": 'Teilen Sie den Standort der Passagiere',
    "sharePassengerLocation": 'Fahrgaststandort teilen',
    "copyLinkToClipboard": 'Link in die Zwischenablage kopieren',
    "copyLinksToClipboard": 'Links in die Zwischenablage kopieren',
    "sendInMail": 'Senden Sie den Link per E-Mail',
    "clickToFollowLocation": 'Klicken Sie auf den Link, um die Position des Passagiers verfolgen zu können',
  },
  "companyCars": {
    "companyCarsHeading": "Fahrzeugliste",
    "worker": "Mitarbeiter",
    "workers": "Mitarbeiter",
    "notFound": "Keine Fahrzeuge für diese Kriterien gefunden!"
  },
  "api": {
    "unexpectedError": "Unerwartetes Fehler!"
  }
}
