import React from "react";
import {useSelector} from 'react-redux';
import SidebarTabPanelStyles from "./styles";

const SidebarPanel = (props) => {
  const { activePanel, panelId, children } = props;
  const editModeEnabled = useSelector(({routes}) => routes.editModeEnabled);

  return (
    <SidebarTabPanelStyles.SidebarTabPanel
      isActive={activePanel === panelId ? true : false}
      editModeEnabled={editModeEnabled}>
      {React.cloneElement(children, { ...props })}
    </SidebarTabPanelStyles.SidebarTabPanel>
  );
};

export default SidebarPanel;
