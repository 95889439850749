import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import theme from 'config/theme';
import {rem} from 'polished';

export const Wrapper = styled.div``;

export const FormContent = styled.div`
  margin-bottom: 20px;
  max-width: 250px;
`;

export const SubmitButtonWrapper = styled.div`
  margin-top: 15px;
`;

export const BackToListLinkContent = styled.div`
  display: flex;
  align-items: center;
`;

export const BackToListIcon = styled.div`
  margin-right: 5px;
  width: 45px;
  height: 45px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -13px;

  svg {
    path {
      transition: stroke .2s ease;
      stroke: ${theme.colors.blueyGrey};
    }
  }
`;

export const BackToListLabel = styled.span`
  font-size: ${rem('12px')};
  color: ${theme.colors.blueyGrey};
  transition: color .2s ease;
`;

export const BackToListLink = styled(Link)`
  margin-bottom: 15px;
  text-decoration: none;
  display: inline-block;

  &:hover {
    ${BackToListIcon} {
      svg {
        path {
          stroke: ${theme.colors.darkNavy};
        }
      }
    }
    ${BackToListLabel} {
      color: ${theme.colors.darkNavy};
    }
  }
`;

export const CompanySelectWrapper = styled.div`
  ${props => css`
    .${props.className} {
      width: 100%;
      max-width: 280px;

      &__control {
        border-color: ${props => props.error ? theme.colors.error : theme.colors.veryLightBlue};
        background-color: transparent;
        min-height: 40px;
        &--is-focused {
          box-shadow: 0 0 0 1px ${theme.colors.apple};
        }
        &:hover {
          cursor: pointer;
          border-color: ${props => props.error ? theme.colors.error : theme.colors.veryLightBlue};
        }
      }
      &__placeholder {
        font-size: ${rem('12px')};
        color: ${props => props.error ? theme.colors.error : theme.colors.cloudyBlue};
        &::after {
          content: '*';
          color: ${theme.colors.error};
          margin-left: 2px;
          display: ${props => props.isRequired ? 'inline-block' : 'none'}
        }
      }
      &__value-container {
        padding-left: 13px;
      }
      &__indicator-separator {
        display: none;
      }
      &__option {
        font-size: ${rem('12px')};
        color: ${theme.colors.darkNavy};
        background-color: transparent;
        &:hover {
          background-color: ${theme.colors.apple};
          color: ${theme.colors.white};
          cursor: pointer;
        }
      }
      &__single-value {
        font-size: ${rem('12px')};
        color: ${theme.colors.darkNavy};
      }
    }
  `}
`;

export const PasswordShowButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      path,
      circle {
        stroke: ${theme.colors.apple};
      }
    }
  }
`;
