import styled, {css} from "styled-components";
import theme from 'config/theme';
import rem from "polished/lib/helpers/rem";
import {Link} from 'react-router-dom';

export const ItemWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  background: ${theme.colors.white};
  border-radius: 5px;
  margin-bottom: 5px;
  opacity: ${props => props.isRemoving ? 0.4 : 1};
  transition: opacity .3s ease;
  
  @media (max-width: ${theme.breakpoints.xl}) {
  	padding: 8px 15px;
  }
`;

export const Name = styled.div`
  width: 16.666%;
  font-weight: bold;
  color: ${theme.colors.darkNavy};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${rem('12px')};
  padding-right: 8px;
`;

export const GrayText = styled.div`
 	width: 16.666%;
 	color: ${theme.colors.blueyGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${rem('12px')};
  padding-right: 8px;
  a {
 	  text-decoration: none;
  	transition: .3s color;
	  color: ${theme.colors.blueyGrey};
	
			&:hover {
				color: ${theme.colors.apple};
			}
  }
`;

export const IdWrapper = styled.div`
	width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: ${rem('12px')};
  padding-right: 8px;
`;
export const Id = styled.div`
  background: rgba(102, 203, 52, 0.1);
  padding: 8px 10px;
  border-radius: 5px;
  font-weight: bold;
  color: ${theme.colors.apple};
  margin: 2px 5px 2px 0;
  
   @media (max-width: ${theme.breakpoints.xl}) {
  	padding: 8px;
  }
  
   ${({showAll}) => (
  showAll && css`
		cursor: pointer;
		transition: .3s background;
		
		&:hover {
			background: rgba(102, 203, 52, 0.3);
		}	
			`
	)}
`;

export const EditButtonsWrapper = styled.div`
	min-width: 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemButtonStyles = css`
  background: rgba(0,6,60,.1);
  padding: 5px;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s background;
  
  &:hover {
  	background: ${props => props.disabled ? 'rgba(0,6,60,.1)' : 'rgba(0,6,60,.3)'};
  }
  
  ${({deleteButton}) => (
    deleteButton && css`
      background: rgba(234, 93, 88,.1);
      margin-right: 0;
      
      &:hover {
        background: rgba(234, 93, 88,.3);
      }
    `
  )}
`;

export const ItemButton = styled.button`
  ${ItemButtonStyles};
`;

export const ItemLink = styled(Link)`
  ${ItemButtonStyles};
`;
