import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {LoggedUserWrapper, UserAvatar, UserWrapper, UserName, LogoutButton, TopMenuWrapper, TopMenu} from './styles';
import {ReactComponent as LogoutIcon} from 'assets/icons/logout.svg';
import {ReactComponent as ChevronDown} from 'assets/icons/chevronDown.svg';
import {logout} from 'utils/auth'
import SelectLanguage from "../../__common/SelectLanguage";
import {showModalWithCallback} from 'store/modal/thunks';
import {setCompaniesDestinations, setRoutes, setRoutesDetails, setTripDetails} from 'store/routes/actions';
import {setCarsPositions, setSelectedCompanyCars, setCarsColors} from 'store/cars/actions';

function LoggedUser() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggingOut, setIsLogginOut] = useState(false);

  const loggedUser = useSelector(({user}) => user.loggedUser);
  const editModeEnabled = useSelector(({routes}) => routes.editModeEnabled);

  const d = useDispatch();
  const myRef = useRef();

  const toggleTopMenu = () => {
    if (loggedUser.type !== 'admin') {
      setIsOpen(!isOpen);
    }
  }

  const handleClickOutside = e => {
    if (!myRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const createUserAvatar = () => {
    if (loggedUser) {
      const splittedName = loggedUser.name.split(' ');
      if (splittedName.length > 1) {
        return `${splittedName[0].charAt(0)}${splittedName[1].charAt(0)}`
      } else {
        return `${splittedName[0].charAt(0)}`
      }
    }
  };

  const handleLogoutClick = () => {
    if (!isLoggingOut) {
      if (editModeEnabled) {
        d(showModalWithCallback(() => {
          setIsLogginOut(true);
          logout(() => setIsLogginOut(false));
          d(setCarsPositions([]));
          d(setRoutes([]));
          d(setRoutesDetails({}));
          d(setTripDetails([]));
          d(setSelectedCompanyCars([]));
          d(setCompaniesDestinations([]));
          d(setCarsColors({}));
        }));
      } else {
        setIsLogginOut(true);
        logout(() => setIsLogginOut(false));
        d(setCarsPositions([]));
        d(setRoutes([]));
        d(setRoutesDetails({}));
        d(setTripDetails([]));
        d(setSelectedCompanyCars([]));
        d(setCompaniesDestinations([]));
        d(setCarsColors({}));
      }
    }
  };

  return (
    <LoggedUserWrapper>
      <UserAvatar isAdmin={loggedUser.type === 'admin'} onClick={toggleTopMenu}>{createUserAvatar()}</UserAvatar>
      <UserWrapper isAdmin={loggedUser.type === 'admin'} onClick={toggleTopMenu}>
        <UserName isAdmin={loggedUser.type === 'admin'}>{loggedUser && loggedUser.name}</UserName>
        {loggedUser.type !== 'admin' && <ChevronDown/>}
      </UserWrapper>
      <LogoutButton onClick={handleLogoutClick}>
        <LogoutIcon/>
      </LogoutButton>
      <TopMenuWrapper ref={myRef}>
        <TopMenu isOpen={isOpen}>
          <SelectLanguage withChangeText/>
        </TopMenu>
      </TopMenuWrapper>
    </LoggedUserWrapper>
  );
};

export default LoggedUser;
