import * as types from './types'

export const setFilters = filters => {
  return {
    type: types.SET_FILTERS,
    payload: filters
  };
};

export const setFiltersFetched = areFetched => {
  return {
    type: types.SET_FILTERS_FETCHED,
    payload: areFetched,
  };
};

export const setCompanyFilters = filters => {
  return {
    type: types.SET_COMPANY_FILTERS,
    payload: filters
  };
};

export const setSavedFiltersLoading = areLoading => {
  return {
    type: types.SET_SAVED_FILTERS_LOADING,
    payload: areLoading,
  };
};

export const setCompanyDataRefreshTime = time => {
  return {
    type: types.SET_COMPANY_DATA_REFRESH_TIME,
    payload: time,
  };
};
