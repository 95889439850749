import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {getNewRouteFromHere, getRoute, saveNewFetchedRoute} from "store/routes/thunks";
import {getAllNaviCars} from "store/cars/thunks";
import {setFetchedNewRoute, setRoutes} from "store/routes/actions";
import {showToastEvent} from "store/events/thunks";
import {showModalWithCallback} from 'store/modal/thunks';
import {getSavedFilters} from 'store/filters/thunks';
import {setUnreadAlertsCounter, addEventsToEvents, updateEvents} from "store/events/actions";
import {setTemporaryPointsOnPolyline, setSectionPoints, setNewRoutePoints, setBoundToMarkers} from 'store/routes/actions';

import {MapWrapper, NotificationWrapper, FadingToast} from "./styles";
import {mapIcons, simpleMarker, newCreatedDot} from "config/map";
import {PoseGroup} from "react-pose";
import theme from 'config/theme';
import shortid from "shortid";
import {findNearest} from 'utils/routes';
import {withNamespaces} from 'react-i18next';

import Sidebar from "../../../components/sidebar/Sidebar";
import Topbar from "components/topbar/Topbar";
import MapPlatform from "../MapPlatform";
import MapContent from "../MapContent";
import MapPolyline from "../MapPolyline";
import MapMarker from "../MapMarker";
import CarMarker from "../CarMarker";
import Events from "components/events/Events";
import EventsItem from "../../events/EventsItem";
import SidebarPanel from "../../sidebar/SidebarPanel";
import Cars from "components/cars/Cars";
import Passengers from 'components/passengers/Passengers';
import EditAlert from 'components/__common/EditAlert';
import {EditAlertWrapper} from 'components/__common/EditAlert/styles';
import AlertSocketProvider from "services/AlertSocketProvider";
import MainFilters from 'components/topbar/MainFilters';
import LoggedUser from 'components/topbar/LoggedUser';
import CompanyCars from "../../companyCars/CompanyCars";
import PageLoader from 'components/__common/PageLoader';
import {setCarsColors} from "../../../store/cars/actions";

const Map = ({ t }) => {
  const [activeTab, setActiveTab] = useState(null);

  const toastEvents = useSelector(({events}) => events.toastEvents);
  const routes = useSelector(({routes}) => routes.routes);
  const temporaryRoutePointsOnPolyline = useSelector(({routes}) => routes.temporaryRoutePointsOnPolyline);
  const loadingRouteId = useSelector(({routes}) => routes.loadingRouteId);
  const carsPositions = useSelector(({cars}) => cars.carsPositions);
  const carsColors = useSelector(({cars}) => cars.carsColors);
  const selectedRoute = useSelector(({routes}) => routes.selectedRoute);
  const sectionPoints = useSelector(({routes}) => routes.sectionPoints);
  const editModeEnabled = useSelector(({routes}) => routes.editModeEnabled);
  const editAlertVisible = useSelector(({routes}) => routes.editAlertVisible);
  const newRoutePoints = useSelector(({routes}) => routes.newRoutePoints);
  const fetchedNewRoute = useSelector(({routes}) => routes.fetchedNewRoute);
  const boundToMarkers = useSelector(({routes}) => routes.boundToMarkers);
  const companyDomain = useSelector(({settings}) =>  settings.basicConfig ? settings.basicConfig.company_domain : null);
  const routeLinesShown = useSelector(({routes}) => routes.routeLinesShown);
  const filtersLoading = useSelector(({filters}) => filters.filtersLoading);
  const filters = useSelector(({filters}) => filters.filters);
  const filtersFetched = useSelector(({filters}) => filters.filtersFetched);

  const d = useDispatch();
  const positionsInterval = useRef(null);

  const getNaviCarsFunc = (isInitialFetch, filters) => {
    d(getAllNaviCars(isInitialFetch, filters));
  };

  useEffect(() => {
    if (boundToMarkers) {
      setTimeout(() => {
        d(setBoundToMarkers(false));
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boundToMarkers]);

  useEffect(() => {
    d(getSavedFilters());
    AlertSocketProvider.watchAllSocketEvents(
      (count) => d(setUnreadAlertsCounter(count)),
      (toastEvent) => d(showToastEvent(toastEvent)),
      (event) => d(addEventsToEvents(event)),
      (event) => d(updateEvents(event))
    );
  }, [d]);

  useEffect(() => {
    d(setCarsColors({}));

    if (filtersFetched) {
      initGettingPositionsInterval();
    }

    return () => {
      clearInterval(positionsInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (newRoutePoints.length) {
      handleFetchForNewRoute();
    } else {
      d(setFetchedNewRoute(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryRoutePointsOnPolyline]);

  const initGettingPositionsInterval = (filters = null) => {
    clearInterval(positionsInterval.current);

    getNaviCarsFunc(true, filters);
    positionsInterval.current = setInterval(() => getNaviCarsFunc(false, filters), 60000);
  };

  const handleActiveClick = (id) => {
    if (editModeEnabled) {
      if (activeTab === 0 && id !== 0) {
        d(showModalWithCallback(() => {
          setActiveTab(id);
        }));
      } else {
        setActiveTab(id);
      }
    } else {
      setActiveTab(id);
    }
  };

  const pickRoute = (routeId, carName, carId) => {
    d(setBoundToMarkers(true));
    const routesIds = routes.map((route) => route.id);
    let colors = {...carsColors};

    if (routesIds.length && routesIds.includes(routeId)) {
      const filteredRoutes = routes.filter((route) => route.id !== routeId);
      delete colors[carId];

      d(setCarsColors(colors));
      d(setRoutes(filteredRoutes));
    } else {
      d(getRoute(routeId, routes, carName, carId));
    }
  };

  const handleHideSidebarPanel = () => {
    setActiveTab(null);
  };

  const addNewPointOnPolyline = (index, clickedPoint, pointType) => {
    const newPoints = [...temporaryRoutePointsOnPolyline];

    newPoints.splice(index, 0, {
      id: clickedPoint.id ? clickedPoint.id : shortid.generate(),
      latitude: String(clickedPoint.latitude),
      longitude: String(clickedPoint.longitude),
      type: pointType,
    });

    if (pointType === 'section-point' && sectionPoints.length >= 1) {
      const firstSectionPointIndex = newPoints.findIndex(el => el.type === 'section-point');
      const lastSectionPointIndex = newPoints.map(el => el.type).lastIndexOf('section-point');
      const indexDiff = lastSectionPointIndex - firstSectionPointIndex;

      newPoints.splice(firstSectionPointIndex + 1, indexDiff - 1);
    }

    d(setTemporaryPointsOnPolyline(newPoints));
  }

  const handlePolylinePress = (evt, map) => {
    if (sectionPoints.length < 2) {

      const coord = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);

      if (selectedRoute) {
        let foundPointIndex = null;

        const nearestCoord = findNearest({
          latitude: coord.lat,
          longitude: coord.lng,
        }, selectedRoute.route);

        const index = sectionPoints.findIndex(point =>
          point.latitude === nearestCoord.latitude &&
          point.longitude === nearestCoord.longitude
        );

        if (index === -1) {
          for (let i = 0; i < temporaryRoutePointsOnPolyline.length; i++) {
            const pointIndex = selectedRoute.route.findIndex(point =>
              point.latitude === temporaryRoutePointsOnPolyline[i].latitude &&
              point.longitude === temporaryRoutePointsOnPolyline[i].longitude
            );

            const nearestClickedPointIndex = selectedRoute.route.indexOf(nearestCoord);
            if (nearestClickedPointIndex <= pointIndex) {
              foundPointIndex = i;
              break;
            }
          }

          d(setSectionPoints([...sectionPoints, {latitude: nearestCoord.latitude, longitude: nearestCoord.longitude}]));
          addNewPointOnPolyline(foundPointIndex, {latitude: coord.lat, longitude: coord.lng}, 'section-point');
        }
      }
    }
  };

  const handleNewPointCreate = newPoint => {
    // if both section points are marked on polyline then allow adding new points
    if (sectionPoints.length === 2) {
      d(setNewRoutePoints([
        ...newRoutePoints,
        newPoint,
      ]));

      const lastSectionPointIndex = temporaryRoutePointsOnPolyline.map(el => el.type).lastIndexOf('section-point');
      addNewPointOnPolyline(lastSectionPointIndex, newPoint, 'new-route-point');
    }
  };

  const handleNewPointRemove = pointId => {
    const newPoints = newRoutePoints.filter(point => point.id !== pointId);
    const newTemporaryRoutePointsOnPolyline = temporaryRoutePointsOnPolyline.filter(point => point.id !== pointId);

    d(setNewRoutePoints(newPoints));
    d(setTemporaryPointsOnPolyline(newTemporaryRoutePointsOnPolyline));
  };

  const handleNewPointDrag = (location, id) => {
    let routePointsAfterChange = [...newRoutePoints];
    let newTemporaryRoutePointsOnPolyline = [...temporaryRoutePointsOnPolyline];

    const i = routePointsAfterChange.findIndex(point => point.id === id);
    const j = newTemporaryRoutePointsOnPolyline.findIndex(point => point.id === id);

    routePointsAfterChange[i] = {
      ...routePointsAfterChange[i],
      latitude: location.lat,
      longitude: location.lng,
    };

    newTemporaryRoutePointsOnPolyline[j] = {
      ...newTemporaryRoutePointsOnPolyline[j],
      latitude: String(location.lat),
      longitude: String(location.lng),
    };

    d(setNewRoutePoints(routePointsAfterChange));
    d(setTemporaryPointsOnPolyline(newTemporaryRoutePointsOnPolyline));
  };

  const returnRemoveBubble = i => {
    return (
      `
        <div>
          <div class="bubble-header">${t('route.additionalPoint')} ${i}</div>
          <button class="bubble-button bubble-button-remove">
            <svg xmlns="http://www.w3.org/2000/svg" width="8.31" height="9.551" viewBox="0 0 8.31 9.551">
              <g id="Group_305" data-name="Group 305" transform="translate(0.456 0.375)">
                <line id="Line_57" data-name="Line 57" x2="7.479" transform="translate(0 1.506)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="0.75"/>
                <path id="Path_427" data-name="Path 427" d="M-.041.389H3.7" transform="translate(1.872 -0.389)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="0.75"/>
                <path id="Path_426" data-name="Path 426" d="M586.505,18.184l1.573,5.72h4.127l1.6-5.72" transform="translate(-586.5 -15.104)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="0.75"/>
              </g>
            </svg>
            <span>${t('route.removePoint')}</span>
          </button>
        </div>
      `
    );
  };

  const handleFetchForNewRoute = async () => {
    const color = selectedRoute ? carsColors[selectedRoute.car_id] : 'yellow';
    d(getNewRouteFromHere(temporaryRoutePointsOnPolyline, color));
  };

  const handleNewRouteSave = () => {
    d(saveNewFetchedRoute(selectedRoute, temporaryRoutePointsOnPolyline));
  };

  return (
    <>
      {filtersLoading
        ? <PageLoader />
        : (
          <MapWrapper>
            <Sidebar
              editModeEnabled={editModeEnabled}
              activeTabId={activeTab}
              clickId={handleActiveClick}
            />
            <Topbar editModeEnabled={editModeEnabled}>
              <MainFilters setFilterEvent={(date, isReturn) => AlertSocketProvider.setFilterEvent(date, isReturn)}/>
              <LoggedUser/>
            </Topbar>
            {companyDomain ? (
              <>
                <SidebarPanel
                  activePanel={activeTab}
                  panelId={0}
                >
                  <Passengers activePanel={activeTab}/>
                </SidebarPanel>
                <SidebarPanel
                  activePanel={activeTab}
                  panelId={1}
                  pickRoute={pickRoute}
                  routes={routes}
                  loadingRouteId={loadingRouteId}>
                  <CompanyCars/>
                </SidebarPanel>
              </>
            ) : (
              <>
                <SidebarPanel
                  activePanel={activeTab}
                  panelId={0}
                  pickRoute={pickRoute}
                  routes={routes}
                  loadingRouteId={loadingRouteId}>
                  <Cars
                    initGettingPositionsInterval={initGettingPositionsInterval}
                    handleNewRouteSave={handleNewRouteSave}
                    onClick={handleHideSidebarPanel}
                    routes={routes}/>
                </SidebarPanel>
                <SidebarPanel
                  activePanel={activeTab}
                  panelId={3}>
                  <Events
                    hideSidebarPanel={handleHideSidebarPanel}
                    markEventAsRead={(eventId) =>
                      AlertSocketProvider.setMarkAsReadEvent(eventId)
                    }
                    markEventAsNew={(eventId) =>
                      AlertSocketProvider.setMarkAsNewEvent(eventId)
                    }/>
                </SidebarPanel>
              </>
            )}
            <MapPlatform
              isPanelExpanded={activeTab !== null}
              apikey={"AMyLKc4pe2VvrUN0pBM1lqDbAMtlz095C71uP8Xz2Rg"}>
              <MapContent
                center={{
                  lat: 52.152366,
                  lng: 19.34208,
                }}
                shouldCluster={true}
                clusterData={carsPositions}
                colors={carsColors}
                onMapClick={e => handleNewPointCreate(e)}>
                {fetchedNewRoute
                  ? <MapPolyline
                      route={fetchedNewRoute.route}
                      color={fetchedNewRoute.color} />
                  : <></>
                }
                {routeLinesShown
                  ? routes.map((route) => {
                    return (
                      <MapPolyline
                        key={route.id}
                        route={route.route}
                        color={
                          selectedRoute && route.id === selectedRoute.id &&
                          editModeEnabled &&
                          newRoutePoints.length > 0
                            ? 'rgba(155, 158, 185, 0.7)'
                            : carsColors[route.car_id] ? carsColors[route.car_id] : 'yellow'
                        }
                        editModeEnabled={editModeEnabled}
                        handlePolylinePress={(e, map) => handlePolylinePress(e, map)}/>
                      )
                    })
                  : <></>
                }
                {newRoutePoints.length ? (
                  newRoutePoints.map((point, i) => {
                    return (
                      <MapMarker
                        key={point.id}
                        id={point.id}
                        geoLat={point.latitude}
                        geoLon={point.longitude}
                        icon={newCreatedDot(i + 1)}
                        dragable={true}
                        onDragEnd={(location, id) => handleNewPointDrag(location, id)}
                        bubbleContent={() => returnRemoveBubble(i + 1)}
                        onBubbleClick={id => handleNewPointRemove(id)}
                        editModeEnabled={editModeEnabled}
                        isNewRouteMarker={true}
                      />
                    );
                  })
                ) : <></>}
                {routeLinesShown
                  ? routes.map((route) => {
                    const markers = route.tasks;
                    if (markers.length) {
                      return markers.map((marker, index) => {
                        let icon =
                          index === 0
                            ? mapIcons.startMarker
                            : index === markers.length - 1
                            ? mapIcons.finishMarker
                            : simpleMarker(index);
                        return (
                          <MapMarker
                            geoLat={marker.geo_lat}
                            geoLon={marker.geo_lon}
                            color={marker.cancelled ? theme.colors.blueyGrey : carsColors[route.car_id]}
                            icon={icon}
                          />
                        );
                      });
                    } else {
                      return <></>;
                    }
                  })
                  : <></>
                }
                {carsPositions.length ? (
                  carsPositions.map((car) => {
                    const geoLat = car.geo_lat;
                    const geoLon = car.geo_lon;
                    const geoDegree = car.geo_degree;
                    const icon = car.geo_error ? mapIcons.carMarkerStopped : mapIcons.carMarker;
                    const color = carsColors[car.car_id] ? carsColors[car.car_id] : car.geo_error ? '#B4BBBF' : theme.colors.navy;
                      return (
                        <CarMarker
                          key={car.car_id}
                          geoLat={geoLat}
                          geoLon={geoLon}
                          icon={icon}
                          geoDegree={car.geo_error ? 0 : geoDegree}
                          color={color}
                        />
                      )
                  })
                ) : <></>}
                {sectionPoints.length
                  ? sectionPoints.map((point, i) => {
                    return (
                      <MapMarker
                        key={i}
                        geoLat={point.latitude}
                        geoLon={point.longitude}
                        icon={mapIcons.sectionDot}/>
                    )
                  })
                  : <></>
                }
              </MapContent>
            </MapPlatform>
            <PoseGroup>
              {editAlertVisible && (
                <EditAlertWrapper key='editAlert'>
                  <EditAlert/>
                </EditAlertWrapper>
              )}
            </PoseGroup>
            <NotificationWrapper>
              <PoseGroup flipMove={false}>
                {toastEvents.map((event) => {
                  return (
                    <FadingToast key={event.id}>
                      <EventsItem
                        event={event}
                        withShadow
                        isNotification
                        openSidebar={() => handleActiveClick(3)}
                      />
                    </FadingToast>
                  );
                })}
              </PoseGroup>
            </NotificationWrapper>
          </MapWrapper>
        )
      }
    </>
  );
};

export default withNamespaces()(Map);
