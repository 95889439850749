import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  ModalWrapper,
  ModalContent,
  ContentIcon,
  ContentText,
  ButtonsWrapper,
  ModalButton,
  TextBold,
} from './styles';
import theme from 'config/theme';
import {ReactComponent as ModalIcon} from 'assets/icons/modal_icon.svg';
import {withNamespaces} from 'react-i18next';
import {
  setEditModeEnabled,
  setPointsOnPolyline,
  setSectionPoints,
  setTemporaryPointsOnPolyline,
  setFetchedNewRoute,
  setNewRoutePoints,
  setEditAlertVisible,
} from 'store/routes/actions';
import {setConfirmModalVisible, setModalCallback, setModalContent} from 'store/modal/actions';

const ConfirmModal = ({ t }) => {

  const modalCallback = useSelector(({modal}) => modal.modalCallback);
  const modalContent = useSelector(({modal}) => modal.modalContent);

  const d = useDispatch();

  const handleConfirmClick = () => {
    modalCallback();
    d(setConfirmModalVisible(false));
    d(setModalCallback(null));
    d(setPointsOnPolyline([]));
    d(setSectionPoints([]));
    d(setEditModeEnabled(false));
    d(setTemporaryPointsOnPolyline([]));
    d(setFetchedNewRoute(null));
    d(setNewRoutePoints([]));
    d(setEditAlertVisible(false));
  };

  const handleCancelClick = () => {
    d(setConfirmModalVisible(false));
    d(setModalCallback(null));
    setTimeout(() => {
      d(setModalContent({
        text: '',
        boldText: '',
        confirmButtonText: '',
        cancelButtonText: '',
        icon: 'default',
      }));
    }, 1000);
  };

  return (
    <ModalWrapper>
      <ModalContent>
        {modalContent && modalContent.icon &&
          <ContentIcon>
            <ModalIcon />
          </ContentIcon>
        }
        <ContentText>
          {modalContent && modalContent.text ? modalContent.text : t('confirmModal.mainText')}
          <TextBold>{modalContent && modalContent.boldText ? modalContent.boldText : t('confirmModal.boldText')}</TextBold>
        </ContentText>
      </ModalContent>
      <ButtonsWrapper>
        <ModalButton 
          bgColor={theme.colors.editRed} 
          textColor={theme.colors.white}
          onClick={handleConfirmClick}>
          {modalContent && modalContent.confirmButtonText ? modalContent.confirmButtonText : t('confirmModal.confirmButton')}
        </ModalButton>
        <ModalButton 
          onClick={handleCancelClick}>
          {modalContent && modalContent.cancelButtonText ? modalContent.cancelButtonText : t('confirmModal.cancelButton')}
        </ModalButton>
      </ButtonsWrapper>
    </ModalWrapper>
  )
};

export default withNamespaces()(ConfirmModal);




