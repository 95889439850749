import * as actions from './actions';
import API from 'api';
import {URL_GET_WORKERS} from 'api/config';
import {formatDate} from 'utils/global';

export const getWorkers = (search = '', passengersType, loader = true) => {
  return async (dispatch, getState) => {
    dispatch(actions.setWorkersLoading(loader));

    const companyFilters = getState().filters.companyFilters;
    let queryFilters = {
      search,
      date: formatDate(companyFilters.date),
      return: companyFilters.return,
      team_id: companyFilters.team_id === 'all' ? null : companyFilters.team_id,
      cancelled: passengersType.value,
    };

    const res = await API.get(URL_GET_WORKERS, {
      params: {
        ...queryFilters,
      }
    });
    dispatch(actions.setWorkersLoading(false));

    if (res && res.data && res.data.items) {
      dispatch(actions.setWorkers(res.data.items));
    } else {
      dispatch(actions.setWorkers([]));
    }
  };
};
