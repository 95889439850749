import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getWorkers} from 'store/passengers/thunks';
import PassengersSearch from 'components/passengers/PassengersSearch';
import {
  PassengersWrapper,
  PassengersWrapperInner,
  PassengersFiltersWrapper,
  ShareAllPassengersPositionsWrapper,
  RefreshButton,
  ActionsWrapper,
  RefreshWrapper,
  RefreshLabel,
} from './styles';
import { SpinnerWrapper, Spinner } from 'styled/Spinner';
import PassengersItem from 'components/passengers/PassengersItem';
import { withNamespaces } from 'react-i18next';
import {SidebarListNotFound} from 'components/sidebar/SidebarPanel/styles';
import SelectFilter from "../../__common/SelectFilter";
import {TopSideContainer, TopSideWrapper, TopSideArrowButtonWrapper} from 'components/__common/TopSide/styles';
import BackButton from "components/__common/BackButton";
import SharePosition from '../SharePosition/index';
import {ReactComponent as RefreshIcon} from 'assets/icons/refresh.svg';
import { setCompanyDataRefreshTime } from '../../../store/filters/actions';

const Passengers = ({ activePanel, closeSidebar, refreshDestinations, t }) => {

  const filterOptions = [
    { value: 0, label: t('filters.presentPassengers') },
    { value: 1, label: t('filters.allPassengers') },
  ];

  const [searchValue, setSearchValue] = useState('');
  const [passengersType, setPassengersType] = useState(filterOptions[0]);

  const workers = useSelector(({ passengers }) => passengers.workers);
  const workersLoading = useSelector(({ passengers }) => passengers.workersLoading);
  const { companyFilters, companyDataRefreshTime } = useSelector(({filters}) => filters);

  const d = useDispatch();

  useEffect(() => {
    if (!workersLoading || (activePanel === 0 && !workersLoading)) {
      d(getWorkers(searchValue, passengersType));
      refreshDestinations(passengersType.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [d, companyDataRefreshTime, companyFilters, activePanel, passengersType]);

  const handleSearchChange = (name, value) => {
    setSearchValue(value);
  };

  const handleTypeFilterChange = (name, value) => {
    let selectedOption = null;

    filterOptions.forEach(option => {
      if (option.value === value) {
        selectedOption = option;
      }
    });

    setPassengersType(selectedOption);
  };

  const returnAllPassengersLocationLinks = () => {
    let workersLocationsList = '';

    workers.forEach(worker => {
      workersLocationsList = `${workersLocationsList}${worker.name} | Link: ${worker.urls && worker.urls.share ? worker.urls.share : 'Brak linku'} | Dojazd: ${worker.estimated}\n`;
    });

    return workersLocationsList;
  };

  const handleRefreshData = () => {
    const refreshTime = new Date().getTime();
    d(setCompanyDataRefreshTime(refreshTime));
  };

  return (
    <>
      <TopSideContainer noFilters>
        <TopSideWrapper>
          <PassengersSearch
            disabled={workersLoading}
            value={searchValue}
            onSubmit={() => d(getWorkers(searchValue, passengersType))}
            onChange={handleSearchChange}
            closeSidebar={closeSidebar} />
          <TopSideArrowButtonWrapper>
            <BackButton onClick={closeSidebar}/>
          </TopSideArrowButtonWrapper>
        </TopSideWrapper>
        <PassengersFiltersWrapper>
          <ActionsWrapper>
            <SelectFilter
              name={"cancelled"}
              disabled={workersLoading}
              value={passengersType}
              onChange={handleTypeFilterChange}
              selectOptions={filterOptions}
            />
            <RefreshWrapper>
              <RefreshLabel>{t('filters.refreshData')}</RefreshLabel>
              <RefreshButton onClick={handleRefreshData} disabled={workersLoading}>
                {workersLoading ? (
                  <Spinner stroke={2} width={13} height={13} />
                ) : (
                  <RefreshIcon />
                )}
              </RefreshButton>
            </RefreshWrapper>
          </ActionsWrapper>
        </PassengersFiltersWrapper>
      </TopSideContainer>
      <PassengersWrapper>
        <PassengersWrapperInner>
          {workersLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            workers.length
              ? workers.map(worker => {
                return (
                  <PassengersItem
                    key={worker.id}
                    passenger={worker}
                  />
                );
              })
              : <SidebarListNotFound employee>{t('passenger.notFound')}</SidebarListNotFound>
          )}
        </PassengersWrapperInner>
      </PassengersWrapper>
      {!workersLoading && (
        <ShareAllPassengersPositionsWrapper>
          <SharePosition
            text={t('passenger.sharePassengersLocations')}
            data={returnAllPassengersLocationLinks()}
            clipboardText={t('passenger.copyLinksToClipboard')}
            reverse={true}
          />
        </ShareAllPassengersPositionsWrapper>  
      )}
    </>
  )
};

export default withNamespaces()(Passengers);
