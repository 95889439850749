import React from 'react'
import Select from 'react-select'
import {SelectWrapper} from './styles'
import { ReactComponent as SelectedIcon } from 'assets/icons/selected.svg'
import theme from '../../../config/theme'
import {rem} from 'polished'

const customStyles = {
	placeholder:
			((styles, { data, isDisabled, isFocused, isSelected }) => {
				return {
					fontFamily: `${theme.font.family}`,
					fontSize: `${rem('10px')}`,
					color: `${theme.colors.blueyGrey}`,
					flexWrap: 'nowrap',
					padding: '0 23px 0 8px',
					textOverflow: 'ellipsis'
				}
			}),
	indicatorsContainer: styles => {
		return {
			...styles,
			display: 'none'
		}
	},
	singleValue: styles => {
		return {
			...styles,
			position: 'relative',
			top: 'auto',
			maxWidth: '100%',
			transform: 'none',
			textOverflow: 'inherit',
			fontSize: `${rem('10px')}`,
			color: `${theme.colors.blueyGrey}`
		}
	},
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			backgroundColor: isFocused ? `${theme.colors.apple}` : `${theme.colors.white}`,
			color: isFocused ? `${theme.colors.white}` : `${theme.colors.darkNavy}`,
			cursor: isDisabled ? 'not-allowed' : 'pointer',
			width: '100%',
			fontFamily: `${theme.font.family}`,
			fontSize: `${rem('10px')}`,
		};
	},
};

const SelectFilter = ({ placeholder, selectOptions, isSearchable = false, onChange, name, value, disabled }) => {
	return (
			<SelectWrapper>
				<Select
					value={value}
					isDisabled={disabled}
					classNamePrefix={'select-filter'}
					placeholder={placeholder}
					options={selectOptions}
					isSearchable={isSearchable}
					onChange={(e) => onChange(name, e.value)}
					styles={customStyles} />
				<SelectedIcon />
			</SelectWrapper>
	)
}

export default SelectFilter;