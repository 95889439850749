import {toast} from 'react-toastify';

export const displayProperToast = flashes => {

  if (flashes.error) {
    flashes.error.forEach((flash, i) => toast.error(flashes.error[i]))
  } else if (flashes.warning) {
    flashes.warning.forEach((flash, i) => toast.warn(flashes.warning[i]))
  } else if (flashes.info) {
    flashes.info.forEach((flash, i) => toast.info(flashes.info[i]))
  } else {
    flashes.success.forEach((flash, i) => toast.success(flashes.success[i]))
  }
}