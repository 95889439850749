import * as types from "./types";

export const setRoutes = (routes) => {
  return {
    type: types.SET_ROUTES,
    payload: routes,
  };
};

export const setLoadingRoute = (routeId) => {
  return {
    type: types.SET_LOADING_ROUTE,
    payload: routeId,
  };
};

export const setLRouteLoading = (isLoading) => {
  return {
    type: types.SET_ROUTE_LOADING,
    payload: isLoading,
  };
};

export const setRoutesDetails = (routeDetails) => {
  return {
    type: types.SET_ROUTE_DETAILS,
    payload: routeDetails,
  };
};

export const setTripDetails = (tripDetails) => {
  return {
    type: types.SET_TRIP_DETAILS,
    payload: tripDetails,
  };
};

export const setTripColorDetails = (dataTrip) => {
  return {
    type: types.SET_TRIP_COLOR_DETAILS,
    payload: dataTrip,
  };
};

export const setPointsOnPolyline = points => {
  return {
    type: types.SET_POINTS_ON_POLYLINE,
    payload: points,
  };
};

export const setTemporaryPointsOnPolyline = points => {
  return {
    type: types.SET_TEMPORARY_POINTS_ON_POLYLINE,
    payload: points,
  };
};

export const setCurrentRoute = route => {
  return {
    type: types.SET_SELECTED_ROUTE,
    payload: route,
  };
};

export const setEditModeEnabled = enabled => {
  return {
    type: types.SET_EDIT_MODE_ENABLE,
    payload: enabled,
  };
};

export const setSectionPoints = points => {
  return {
    type: types.SET_SECTION_POINTS,
    payload: points,
  };
};

export const setNewRoutePoints = points => {
  return {
    type: types.SET_NEW_ROUTE_POINTS,
    payload: points,
  };
};

export const setEditAlertVisible = isVisible => {
  return {
    type: types.SET_EDIT_ALERT_VISIBLE,
    payload: isVisible,
  };
};

export const setFetchedNewRoute = newRoute => {
  return {
    type: types.SET_FETCHED_NEW_ROUTE,
    payload: newRoute,
  };
};

export const setFetchedRouteSaving = isSaving => {
  return {
    type: types.SET_FETCHED_ROUTE_SAVING,
    payload: isSaving,
  };
};

export const setBoundToMarkers = isBounding => {
  return {
    type: types.SET_BOUND_TO_MARKERS,
    payload: isBounding,
  };
};

export const setRouteLinesShown = areShown => {
  return {
    type: types.SET_ROUTES_LINES_SHOWN,
    payload: areShown,
  };
};

export const setCompaniesDestinations = destinations => {
  return {
    type: types.SET_COMPANIES_DESTINATIONS,
    payload: destinations,
  };
};

export const setCompaniesDestinationsLoading = isLoading => {
  return {
    type: types.SET_COMPANIES_DESTINATIONS_LOADING,
    payload: isLoading,
  }
}
