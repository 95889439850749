import styled, { css } from 'styled-components'
import theme from 'config/theme'
import {rem} from "polished";

const DatePickerStyles = css`
  border-radius: 4px;
  padding: 8px 8px 8px 26px;
  max-width: 105px;
  color: ${theme.colors.white};
  outline: none;
  border: none;
  background-color: ${theme.colors.apple};
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const DataLoadingWrapper = styled.div`
  opacity: 0.6;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px;
  width: 105px;
  outline: none;
  border: none;
  background-color: ${theme.colors.apple};
  margin-right: 15px;
`;

export const DateFilterSelect = styled.input`
  ${DatePickerStyles};
  background-color: ${props => props.bgColor ? props.bgColor : theme.colors.apple};
  font-family: ${theme.font.family};
  font-size: ${rem('11px')};
  cursor: pointer;
  transition: border-color .3s ease;
  &:hover {
    border-color: ${theme.colors.apple};
  }
`;

export const PickerWrapper = styled.div`
  position: relative;
  cursor: pointer;
  max-width: 105px;
  margin-right: 15px;
  @media(max-width: 1023px) {
    width: 100%;
  }
  .react-datepicker {
    font-family: ${theme.font.family};
  }
  .react-datepicker{
    &-wrapper {
      width: 100%;
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected, 
  .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__month--selected {
    background-color: ${theme.colors.main};
    &:hover {
      background-color: ${theme.colors.main};
    }
  }
  .react-datepicker__input-container {
    input {
      font-weight: bold;
      width: 100%;
      max-width: 100%;
    }
    svg {
       max-width: 8px;
       max-height: 8px;
       position: absolute;
       top: 50%;
       left: 10px;
       transform: translateY(-50%);
      .prefix__cls-1,.prefix__cls-2 {
        stroke: ${theme.colors.white};
      }
    }
  }
  
  .react-datepicker__navigation,
  .react-datepicker__day {
  
    &:focus {
      outline: none;
    }
  }
  
  .react-datepicker__day--selected {
    background-color: ${theme.colors.apple};
  }
`