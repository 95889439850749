import styled from 'styled-components';
import theme from 'config/theme';

export const Wrapper = styled.div`
  position: ${props => props.isFixed ? 'fixed' : 'absolute'};
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${theme.colors.white};
`