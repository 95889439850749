import * as types from './types';

const initialState = {
  corps: [],
  loadingCorps: false,
  companies: null,
  companiesLoading: false,
  companyRemovingId: null,
  companyFormSubmitting: false,
  companyFormErrors: null,
  currentCompany: null,
  companyTeamsLoading: false,
  companyTeams: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CORPS:
      return {
        ...state,
        corps: action.payload,
      };
    case types.SET_CORPS_LOADING:
      return {
        ...state,
        loadingCorps: action.payload,
      };
    case types.SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case types.SET_COMPANIES_LOADING:
      return {
        ...state,
        companiesLoading: action.payload,
      };
    case types.SET_COMPANY_REMOVING_ID:
      return {
        ...state,
        companyRemovingId: action.payload,
      };
    case types.SET_COMPANY_FORM_SUBMITTING:
      return {
        ...state,
        companyFormSubmitting: action.payload,
      };
    case types.SET_COMPANY_FORM_ERRORS:
      return {
        ...state,
        companyFormErrors: action.payload,
      };
    case types.SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
      };
    case types.SET_COMPANY_TEAMS_LOADING:
      return {
        ...state,
        companyTeamsLoading: action.payload,
      }
    case types.SET_COMPANY_TEAMS:
      return {
        ...state,
        companyTeams: action.payload,
      }
    default:
      return state;
  };
};
